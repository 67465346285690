import { Button } from '@material-ui/core'
import Modal from 'react-bootstrap/Modal'

export const NotificationModal = ({ message, open, onClose = () => {} }) => (
  <Modal show={open} onHide={onClose} style={{ marginTop: '50px' }}>
    <Modal.Header closeButton>
      <Modal.Title>Patient Notification</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{message}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={onClose}>
        Okay
      </Button>
    </Modal.Footer>
  </Modal>
)
