import { useEffect, useState } from 'react'

export const useElementById = (actionId) => {
  const [actionElement, setActionElement] = useState(null)
  useEffect(() => {
    setActionElement(document.getElementById(actionId))
  }, [actionId])

  return actionElement
}
