import { DataGrid } from '@mui/x-data-grid'
import { getLanguage } from '../../../locale/getLocale'
import { dateTransform } from '../../../device/utils/dateTransform'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { getCustomerTestReports } from '../getCustomerTestReports'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import Box from '@mui/material/Box'

const PAGE_SIZE = 10
const getRowId = (row) => row._id

const RowActions = ({ id, onSelect }) => (
  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    <IconButton onClick={() => onSelect(id)}>
      <FileOpenIcon />
    </IconButton>
  </Box>
)

export const createColumns = ({ onSelect } = {}) => [
  {
    field: 'createdAt',
    headerName: 'Start At',
    flex: 1,
    type: 'datetime',
    valueFormatter: ({ value }) =>
      value
        ? new Intl.DateTimeFormat(getLanguage(), {
            dateStyle: 'short',
            timeStyle: 'medium',
          }).format(dateTransform.input(value))
        : value,
    filterable: false,
    sortable: false,
  },
  {
    field: 'finishAt',
    headerName: 'Finished At',
    flex: 1,
    type: 'datetime',
    valueFormatter: ({ value }) =>
      value
        ? new Intl.DateTimeFormat(getLanguage(), {
            dateStyle: 'short',
            timeStyle: 'medium',
          }).format(dateTransform.input(value))
        : value,
    filterable: false,
    sortable: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    sortable: false,
    flex: 0,
    filterable: false,
    hideable: false,
    aggregable: false,
    groupable: false,
    resizable: false,
    renderCell: (params) => {
      return <RowActions id={params.id} onSelect={onSelect} />
    },
  },
]

export const TestReportList = ({ customerId, onSelect, onSelectLatest }) => {
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)

  const selectHandler = useCallback(
    (id) => {
      return onSelect(reports.find((report) => getRowId(report) === id))
    },
    [onSelect, reports]
  )

  const columns = useMemo(
    () =>
      createColumns({
        onSelect: selectHandler,
      }),
    [reports]
  )

  useEffect(() => {
    if (customerId) {
      setLoading(true)
      getCustomerTestReports(customerId)
        .then(setReports)
        .finally(() => setLoading(false))
    }
  }, [customerId])

  return (
    <>
      <DataGrid
        sx={{
          '--DataGrid-overlayHeight': '300px',
        }} // rewrite boostrap styles.
        rows={reports || []}
        columns={columns}
        disableRowSelectionOnClick
        getRowId={getRowId}
        autoHeight
        initialState={{
          pagination: { paginationModel: { pageSize: PAGE_SIZE } },
        }}
        pageSizeOptions={[PAGE_SIZE]}
        disableColumnFilter
        disableColumnResize
        disableColumnSorting
        loading={loading}
      />
      {onSelectLatest && (
        <Button onClick={onSelectLatest}>Show Latest Report</Button>
      )}
    </>
  )
}
