export const getPrograms = async (clinicId) =>
  fetch(`/routes/program/clinic/${clinicId}`, {
    withCredentials: true,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.message === 'Success') {
        return data.programs
      }
      return []
    })
    .catch((error) => {
      console.error(error)
      return []
    })
