import { useState, useEffect, useId } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Button } from '@material-ui/core'
import { Dialog } from '../../components/common/Dialog'
import { defaultFormState } from '../threshold/defaultFormState'
import { setFormThresholds } from '../threshold/setFormThresholds'
import { ThresholdsForm } from '../threshold/ThresholdsForm'
import { useFormStyles } from '../../components/common/style'
import { getProgramThresholds } from './getProgramThresholds'
import { updateProgamThresholds } from './updateProgramThresholds'
import { useWeightUnit } from '../../components/common/DisplaySetings'

export const EditProgramModal = ({
  open,
  programId,
  onClose = () => {},
  onSave = () => {},
}) => {
  const formId = useId()
  const [serverError, setServerError] = useState('')
  const [isLoading, setLoading] = useState(true)
  const classes = useFormStyles()
  const { weightUnit } = useWeightUnit()

  const methods = useForm({
    defaultValues: defaultFormState,
  })

  const closeHandler = () => {
    setServerError('')
    setLoading(true)
    onClose()
    methods?.reset()
  }

  const onSubmit = async (data) => {
    try {
      updateProgamThresholds(programId, data)
      onSave()
      closeHandler()
    } catch (e) {
      setServerError(e.message)
    }
  }

  useEffect(() => {
    if (open) {
      getProgramThresholds(programId).then((data) => {
        if (data && data.threshold) {
          methods.setValue('name', data.name, { shouldValidate: true })
          methods.setValue('description', data.description, {
            shouldValidate: true,
          })
          setFormThresholds(methods, data.threshold)
        } else {
          setServerError('Error loading program data')
        }
        setLoading(false)
      })
    }
  }, [open])

  return (
    <Dialog
      open={open}
      title={'Edit Program Thresholds'}
      handleClose={closeHandler}
      actions={
        <>
          <Button variant="secondary" onClick={closeHandler}>
            Close
          </Button>
          <Button
            disabled={methods.formState.isSubmitting}
            form={formId}
            type="submit"
            variant="primary"
          >
            Submit
          </Button>
        </>
      }
      maxWidth="lg"
    >
      <FormProvider {...methods}>
        <form
          className={classes.paper}
          id={formId}
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {/* <ProgramNameForm /> */}
          {/* <ProgramDescriptionForm /> */}
          <br />
          <ThresholdsForm
            isLoading={isLoading}
            serverError={serverError}
            weightUnit={weightUnit}
          />
        </form>
      </FormProvider>
    </Dialog>
  )
}
