import { dateTransform } from '../utils/dateTransform'
import { manufacturers, manufacturersMap } from '../utils/manufacturers'
import { deviceTypesMap, deviceTypes } from '../utils/deviceTypes'
import { getLanguage } from '../../locale/getLocale'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { getCustomerEndpoint } from '../utils/endpoints'
import { formatSignal, signalOptions } from '../utils/formatSignal'

const commonColumnProps = {
  flex: 1,
  aggregable: false,
  groupable: false,
  resizable: false,
  minWidth: 120,
}

const RowActions = ({ id, onDelete, onEdit }) => (
  <Box>
    <IconButton onClick={() => onDelete(id)}>
      <DeleteIcon />
    </IconButton>
    <IconButton onClick={() => onEdit(id)}>
      <EditIcon />
    </IconButton>
  </Box>
)

export const createColumns = ({ onDelete, onEdit, onShowDetails } = {}) => [
  {
    ...commonColumnProps,
    field: 'imei',
    headerName: 'IMEI',
    type: 'string',
    hideable: false,
    sortable: false,
    renderCell: (params) => (
      <Link
        component="button"
        variant="body2"
        onClick={() => onShowDetails(params.id)}
      >
        {params.value}
      </Link>
    ),
  },
  {
    ...commonColumnProps,
    field: 'deviceId',
    headerName: 'Device Id',
    type: 'string',
    hide: true,
  },
  {
    ...commonColumnProps,
    field: 'type',
    headerName: 'Type',
    minWidth: 100,
    type: 'singleSelect',
    valueFormatter: ({ value }) => deviceTypesMap.get(value),
    valueOptions: deviceTypes,
  },
  {
    ...commonColumnProps,
    field: 'manufacturer',
    headerName: 'Manufacturer',
    minWidth: 100,
    type: 'singleSelect',
    valueFormatter: ({ value }) => manufacturersMap.get(value),
    valueOptions: manufacturers,
  },
  {
    ...commonColumnProps,
    field: 'customer',
    headerName: 'Customer',
    type: 'string',
    valueFormatter: ({ value }) => value?.name,
  },
  {
    ...commonColumnProps,
    field: 'clinic',
    headerName: 'Clinic',
    type: 'string',
    valueFormatter: ({ value }) => value?.name,
  },
  {
    ...commonColumnProps,
    field: 'endpoint',
    headerName: 'Endpoint',
    type: 'string',
    valueFormatter: ({ id, value, api }) => {
      const { customer } = api.getRow(id)
      return getCustomerEndpoint(customer)(value)
    },
  },
  {
    ...commonColumnProps,
    field: 'billingStart',
    headerName: 'Billing Start',
    type: 'date',
    valueFormatter: ({ value }) =>
      value
        ? new Intl.DateTimeFormat(getLanguage()).format(
            dateTransform.input(value)
          )
        : value,
  },
  {
    ...commonColumnProps,
    field: 'billingEnd',
    headerName: 'Billing End',
    type: 'date',
    valueFormatter: ({ value }) =>
      value
        ? new Intl.DateTimeFormat(getLanguage()).format(
            dateTransform.input(value)
          )
        : value,
  },
  {
    ...commonColumnProps,
    field: 'createdAt',
    headerName: 'Added to System',
    type: 'dateTime',
    valueFormatter: ({ value }) =>
      new Intl.DateTimeFormat(getLanguage(), {
        dateStyle: 'short',
        timeStyle: 'medium',
      }).format(dateTransform.input(value)),
  },
  {
    ...commonColumnProps,
    field: 'modelNumber',
    headerName: 'Model Number',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'bat',
    headerName: 'Battery Level',
    type: 'number',
  },
  {
    ...commonColumnProps,
    field: 'sn',
    headerName: 'Serial Number',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'iccid',
    headerName: 'SIM ICCD Number',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'mcuv',
    headerName: 'MCU Version',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'appv',
    headerName: 'APP Version',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'fv',
    headerName: 'Modem Version',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'algv',
    headerName: 'Bpm Algorithm Version',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'ops',
    headerName: 'Cellular Carrier',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'net',
    headerName: 'Type of Cellular Network',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'sig',
    headerName: 'Signal',
    type: 'singleSelect',
    minWidth: 100,
    valueFormatter: ({ value }) => formatSignal(value),
    valueOptions: signalOptions,
  },
  {
    ...commonColumnProps,
    field: 'tp',
    headerName: 'Chip Temperature',
    type: 'number',
    minWidth: 50,
    valueFormatter: ({ value }) => (value ? `${value}℃` : ''),
  },
  {
    ...commonColumnProps,
    field: 'lastMeasurement',
    headerName: 'Last Measurement',
    type: 'dateTime',
    valueFormatter: ({ value }) =>
      value
        ? new Intl.DateTimeFormat(getLanguage(), {
            dateStyle: 'short',
            timeStyle: 'medium',
          }).format(value)
        : '',
  },
  {
    ...commonColumnProps,
    field: 'receivedByMdms',
    headerName: 'Received By MDMS',
    type: 'dateTime',
    valueFormatter: ({ value }) =>
      value
        ? new Intl.DateTimeFormat(getLanguage(), {
            dateStyle: 'short',
            timeStyle: 'medium',
          }).format(value)
        : '',
  },
  {
    ...commonColumnProps,
    field: 'tz',
    headerName: 'Time Zone',
    type: 'string',
  },
  {
    ...commonColumnProps,
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    sortable: false,
    flex: 0,
    filterable: false,
    hideable: false,
    renderCell: (params) => {
      return <RowActions id={params.id} onDelete={onDelete} onEdit={onEdit} />
    },
  },
]

export const defaultColumnVisibilityModel = {
  deviceId: false,
  imei: true,
  type: true,
  customer: true,
  clinic: false,
  endpoint: false,
  billingStart: false,
  billingEnd: false,
  createdAt: true,
  modelNumber: false,
  bat: false,
  sn: false,
  iccid: false,
  mcuv: false,
  appv: false,
  fv: false,
  algv: false,
  ops: false,
  net: false,
  sig: false,
  tp: false,
  lastMeasurement: true,
  tz: false,
}
