import { useState, useCallback, useMemo } from 'react'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid'
import { EditDeviceModal } from './EditDeviceModal'
import { ErrorBoundary } from '../../../components/ErrorBoundary'
import { DELETE_ACTION, CHANGE_ACTION, RENEW_ACTION } from '../constants'
import { createColumns } from './columns'

const PAGE_SIZE = 10
const getRowId = (row) => row.id

const Toolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton variant="primary" />
    <GridToolbarColumnsButton variant="primary" />
  </GridToolbarContainer>
)

export const DevicesGrid = ({ data, customers, onChange }) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  })
  const [curentEditId, setCurentEditId] = useState(null)

  const deleteHandler = useCallback((id) => {
    onChange({ id, type: DELETE_ACTION })
  }, [])
  const renewHandler = useCallback(
    (id) => onChange({ id, type: RENEW_ACTION }),
    []
  )
  const editHandler = useCallback((id) => setCurentEditId(id), [])

  const columns = useMemo(
    () =>
      createColumns({
        onEdit: editHandler,
        onDelete: deleteHandler,
        onRenew: renewHandler,
        customers,
      }),
    []
  )

  return (
    <ErrorBoundary>
      <DataGrid
        slots={{
          toolbar: Toolbar,
        }}
        sx={{
          '& p': {
            mt: '1em',
            mb: '1em',
          },
          '--DataGrid-overlayHeight': '300px',
        }} // rewrite boostrap styles.
        getRowId={getRowId}
        columns={columns}
        rows={data}
        pageSizeOptions={[PAGE_SIZE]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick
        autoHeight
      />

      {curentEditId && (
        <EditDeviceModal
          id={curentEditId}
          customers={customers}
          device={data.find(({ id }) => curentEditId === id)?.device}
          onClose={() => setCurentEditId(null)}
          onSave={(id, newDevice) =>
            onChange({ id, type: CHANGE_ACTION, device: newDevice })
          }
        />
      )}
    </ErrorBoundary>
  )
}
