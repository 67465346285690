import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { DevicesOther } from '@material-ui/icons'
import List from '@material-ui/core/List'

export const SidebarList = ({
  setMainDashboard,
  setRegisterDevicesDashboard,
}) => {
  return (
    <List>
      <div>
        <ListItem
          button
          onClick={() => {
            setRegisterDevicesDashboard(false)
            setMainDashboard(true)
          }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            setMainDashboard(false)
            setRegisterDevicesDashboard(true)
          }}
        >
          <ListItemIcon>
            <DevicesOther />
          </ListItemIcon>
          <ListItemText primary="Register Devices" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            fetch('/routes/users/logout', {
              method: 'POST',
              body: JSON.stringify({}),
              headers: { 'Content-Type': 'application/json' },
            })
              .then((response) => {
                if (response.status === 201) {
                  return response.json()
                }
              })
              .then((data) => {
                sessionStorage.clear()
                window.location.reload()
              })
              .catch((error) => {
                console.error(error)
              })
          }}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </div>
    </List>
  )
}
