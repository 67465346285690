import trim from 'lodash/trim'

const parseRow = (noIdentifierName) => (rowStr) =>
  noIdentifierName ? rowStr : rowStr.split(',').map((val) => trim(val, '"'))

export const generateBulkData = ({
  identifierName,
  noIdentifierName,
  type,
  manufacturer,
  customer,
  endpoint,
  clinic,
  data,
  headers,
  billingStart,
  billingEnd,
}) => {
  let dataIndex = headers.findIndex((item) => identifierName === item)
  if (dataIndex < 0) {
    dataIndex = 0
  }

  return data.map(parseRow(noIdentifierName)).map((row) => ({
    identifier: noIdentifierName ? row : row[dataIndex],
    type,
    manufacturer,
    customer,
    endpoint,
    clinic,
    billingStart,
    billingEnd,
  }))
}
