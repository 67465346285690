import React, { Component } from 'react'
import './App.css'
import { Router, Switch, Route } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import LoginPage from '../src/components/LoginPage'
import DashboardPage from '../src/components/DashboardPage'
import IFramePage from './components/IFramePage'
import AdminDashboard from '../src/components/AdminDashboard'
import PatientDashboard from '../src/components/PatientDashboard'
import ForgotPassword from '../src/components/ForgotPassword'
import CreateAccount from '../src/components/CreateAccount'
import TermsConditions from '../src/components/TermsConditions'
import PrivacyPolicy from '../src/components/PrivacyPolicy'
import { DeviceSignUp } from './components/DeviceNotifications/DeviceSignUpPage'
import { getLocale } from './locale/getLocale'
import { DeviceDashboard } from './components/DeviceNotifications/DeviceDashboard'
import { createBrowserHistory } from 'history'
import { MagicLinkAuthPage } from './components/DeviceNotifications/PatientAuth/MagicLinkAuthPage'
import { MagicLinkAuthCallback } from './components/DeviceNotifications/PatientAuth/MagicLinkAuthCallback'
import { ShortUrlPage } from './components/ShortUrlPage'
//import ForgotPasswordPage from '../src/components/ForgotPasswordPage';

export const history = createBrowserHistory()

export default class App extends Component {
  render() {
    return (
      <Router history={history}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={getLocale()}
        >
          <div>
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route exact path="/dashboardPage" component={DashboardPage} />
              <Route exact path="/iFrame/:id" component={IFramePage} />
              <Route exact path="/adminDashboard" component={AdminDashboard} />
              <Route
                exact
                path="/patientDashboard"
                component={PatientDashboard}
              />
              <Route
                exact
                path="/forgotPassword/:id"
                component={ForgotPassword}
              />
              <Route
                exact
                path="/createAccount/:id"
                component={CreateAccount}
              />
              <Route
                exact
                path="/terms-conditions"
                component={TermsConditions}
              />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route
                exact
                path="/device-updates/register/:imei"
                component={DeviceSignUp}
              />
              <Route
                exact
                path="/device-updates/dashboard/:imei"
                component={DeviceDashboard}
              />
              <Route
                exact
                path="/devices/:imei/magic-link"
                component={MagicLinkAuthPage}
              />
              <Route
                exact
                path="/devices/:imei/magic-link/:token"
                component={MagicLinkAuthCallback}
              />
              <Route exact path="/s/:token" component={ShortUrlPage} />
              {/* <Route exact path="/forgotPasswordPage/:id" component={ForgotPasswordPage}/> */}
            </Switch>
          </div>
        </LocalizationProvider>
      </Router>
    )
  }
}
