import {
  TextField,
  Button,
  Checkbox,
  FormHelperText,
  Link,
  Box,
} from '@material-ui/core'
import { useFormStyles } from '../common/style'
import { PhoneNumberRegex } from '../../common/regex'
import { useForm, Controller } from 'react-hook-form'
import { TopAlignedFormControlLabel } from '../common/TopAlignedFormControlLabel'

const consentVerbage =
  'I agree to receive blood pressure device related messages from Cardiowell at the phone number provided above. I understand I will receive one message every week, data rates may apply, reply STOP to opt out.'

export const DeviceSignupForm = ({ onSubmit }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      phoneNumber: '',
      firstName: '',
      lastName: '',
      smsConsent: false,
    },
  })
  const classes = useFormStyles()

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="phoneNumber"
        control={control}
        rules={{
          required: 'Required field',
          validate: (values) =>
            PhoneNumberRegex.test(values) ||
            'Valid phone number example: 1234567890 or 123-456-7890',
        }}
        render={({ field: { name, value, onChange } }) => (
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phonenumber"
            label="Phone Number"
            value={value}
            name={name}
            autoFocus
            onChange={onChange}
            error={!!errors[name]}
            helperText={errors[name]?.message}
          />
        )}
      />
      <Controller
        name="firstName"
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name={name}
            value={value}
            label="First Name"
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name={name}
            value={value}
            label="Last Name"
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="smsConsent"
        control={control}
        rules={{
          required:
            'Please agree to receive SMS notifications as part of this service',
        }}
        render={({ field: { name, value, onChange } }) => (
          <TopAlignedFormControlLabel
            className={classes.formElement}
            name={name}
            value={value}
            control={<Checkbox />}
            onChange={onChange}
            label={consentVerbage}
          />
        )}
      />
      <FormHelperText error={!!errors['smsConsent']}>
        {errors['smsConsent']?.message}
      </FormHelperText>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Link
          target="_blank"
          href={'https://cardiowell.com/privacy-policy/'}
          underline="always"
        >
          Privacy Policy
        </Link>
        &nbsp;
        {'|'}
        &nbsp;
        <Link
          target="_blank"
          href={'https://cardiowell.com/cardiowell-mobile-terms-of-service'}
          underline="always"
        >
          Mobile Terms of Service
        </Link>
      </Box>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        className={classes.submit}
      >
        Register
      </Button>
    </form>
  )
}
