export const adminDeletePatient = async (patientId, adminId) => {
  const inputValue = {
    id: patientId,
    adminID: adminId,
  }

  return fetch('/routes/users/providerDeletePatient', {
    method: 'POST',
    body: JSON.stringify(inputValue),
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      return {
        error: 'error',
        status: response.status,
        statusText: response.statusText,
      }
    })
    .catch((error) => {
      console.error(error)
      return { error }
    })
}
