import { useEffect } from 'react'
import {
  Box,
  CssBaseline,
  AppBar,
  Typography,
  Toolbar,
  CircularProgress,
} from '@material-ui/core'
import { Copyright } from '../../common/Copyright'
import { useStyles } from '../../common/style'
import cardiowell2 from '../../../images/cardiowell2.png'
import { authWithMagicLinkToken } from './magicLinkAPI'
import { history } from '../../../App.js'

const LoadingDisplay = () => {
  const classes = useStyles()
  return (
    <Box className={classes.centered}>
      <CircularProgress />
    </Box>
  )
}

export const MagicLinkAuthCallback = (props) => {
  const token = props.match.params.token
  const imei = props.match.params.imei
  const classes = useStyles()

  useEffect(() => {
    authWithMagicLinkToken(token)
      .catch(() => {})
      .finally(() => {
        history.push(`/device-updates/dashboard/${imei}`)
      })
  }, [token, imei])

  return (
    <main className={classes.content}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Patient Auth
          </Typography>
          <img
            style={{ width: '125px', height: '25px' }}
            src={cardiowell2}
            alt="Default"
          />
        </Toolbar>
      </AppBar>
      <div className={classes.appBarSpacer} />
      <LoadingDisplay />
      <Box mt={8}>
        <Copyright />
      </Box>
    </main>
  )
}
