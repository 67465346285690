export const getDeviceDetails = (id) =>
  fetch(`/routes/devices/${id}/details`, {
    withCredentials: true,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }).then((response) => {
    if (response.ok) {
      return response.json()
    } else {
      throw Error('server error')
    }
  })
