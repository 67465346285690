import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Switch,
  FormControlLabel,
} from '@material-ui/core'
import Modal from 'react-bootstrap/Modal'
import React, { useEffect, useState } from 'react'
import { useStyles } from './style'
import { ClinicSelect } from './ClinicSelect'
import { CARDIOWELL, TRANSTEK, WITHINGS, AD } from '../../common/manufacters'
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'

export const EditPatient = ({
  canDelete,
  modalShow,
  setModalShow,
  serverMessage,
  savePatientChanges,
  deletePatient,
  selectedPatientId,
  selectedPatient,
  canEditClinic,
  clinics = [],
  timezoneRequired,
}) => {
  const classes = useStyles()

  const { options: timeZoneOptions } = useTimezoneSelect({
    labelStyle: 'abbrev',
    allTimezones,
  })

  // Initialize state variables with empty strings as default values
  const [patientFirstName, setPatientFirstName] = useState('')
  const [patientLastName, setPatientLastName] = useState('')
  const [patientMRN, setPatientMRN] = useState('')
  const [patientEmail, setPatientEmail] = useState('')
  const [patientPhoneNumber, setPatientPhoneNumber] = useState('')
  const [patientCellNumber, setPatientCellNumber] = useState('')
  const [patientAddress, setPatientAddress] = useState('')
  const [patientCity, setPatientCity] = useState('')
  const [patientState, setPatientState] = useState('')
  const [patientZip, setPatientZip] = useState('')
  const [patientTimeZone, setPatientTimeZone] = useState('')
  const [patientClinic, setPatientClinic] = useState('')
  const [patientWeightDevice, setPatientWeightDevice] = useState('')
  const [selectedWeightIMEI, setPatientWeightIMEI] = useState('')
  const [patientTTWeightIMEI, setPatientTTWeightIMEI] = useState('')
  const [patientBPDevice, setPatientBpDevice] = useState('')
  const [patientBPIMEI, setPatientBPIMEI] = useState('')
  const [patientTTBPIMEI, setPatientTTBPIMEI] = useState('')
  const [patientADBPIMEI, setPatientADBPIMEI] = useState('')
  const [patientPulseIMEI, setPatientPulseIMEI] = useState('')
  const [patientGlucoseIMEI, setPatientGlucoseIMEI] = useState('')
  const [deviceNotificationsEnabled, setDeviceNotificationsEnabled] =
    useState(false)

  useEffect(() => {
    if (modalShow && selectedPatientId && selectedPatient) {
      if (selectedPatient.name) {
        setPatientFirstName(selectedPatient.name.split(' ')[0])
        setPatientLastName(selectedPatient.name.split(' ')[1])
      } else {
        setPatientFirstName(selectedPatient.firstName)
        setPatientLastName(selectedPatient.lastName)
      }
      setPatientMRN(selectedPatient.mrn || selectedPatient.MRN)
      setPatientEmail(selectedPatient.email)
      setPatientPhoneNumber(selectedPatient.phoneNumber)
      setPatientCellNumber(selectedPatient.cellNumber)
      setPatientAddress(selectedPatient.address)
      setPatientCity(selectedPatient.city)
      setPatientState(selectedPatient.state)
      setPatientZip(selectedPatient.zip)
      setPatientTimeZone(selectedPatient.timeZone2 ?? selectedPatient.timeZone)
      setPatientClinic(selectedPatient.clinic)
      setPatientWeightDevice(selectedPatient.selectedWeightDevice ?? CARDIOWELL)
      setPatientWeightIMEI(selectedPatient.weightIMEI)
      setPatientTTWeightIMEI(selectedPatient.ttWeightIMEI)
      setPatientBpDevice(selectedPatient.selectedBpDevice ?? CARDIOWELL)
      setPatientBPIMEI(selectedPatient.bpIMEI)
      setPatientTTBPIMEI(selectedPatient.ttBpIMEI)
      setPatientADBPIMEI(selectedPatient.adBpIMEI)
      setPatientPulseIMEI(selectedPatient.pulseIMEI)
      setPatientGlucoseIMEI(selectedPatient.glucoseIMEI)
      setDeviceNotificationsEnabled(
        !!selectedPatient.deviceNotificationsEnabled
      )
    }
  }, [selectedPatientId, modalShow])

  const displayedBPDeviceIMEI = () => {
    switch (patientBPDevice) {
      case CARDIOWELL:
        return patientBPIMEI ?? ''
      case TRANSTEK:
        return patientTTBPIMEI ?? ''
      case AD:
        return patientADBPIMEI ?? ''
      case WITHINGS:
        return selectedPatient.withingsBpDevices ?? ''
      default:
        return ''
    }
  }

  const displayedWeightDeviceIMEI = () => {
    switch (patientWeightDevice) {
      case CARDIOWELL:
        return selectedWeightIMEI ?? ''
      case TRANSTEK:
        return patientTTWeightIMEI ?? ''
      default:
        return ''
    }
  }

  const setDisplayedBpIMEI = (imei) => {
    switch (patientBPDevice) {
      case CARDIOWELL:
        setPatientBPIMEI(imei)
        return
      case TRANSTEK:
        setPatientTTBPIMEI(imei)
        return
      case AD:
        setPatientADBPIMEI(imei)
        return
      default:
        return
    }
  }

  const setDisplayedWeightIMEI = (imei) => {
    switch (patientWeightDevice) {
      case CARDIOWELL:
        setPatientWeightIMEI(imei)
        return
      case TRANSTEK:
        setPatientTTWeightIMEI(imei)
        return
      default:
        return
    }
  }

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      style={{ marginTop: '50px' }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Patient Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          id="name"
          label="First Name"
          name="name"
          autoFocus
          value={patientFirstName}
          onChange={(event) => setPatientFirstName(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          name="name"
          label="Last Name"
          id="name"
          value={patientLastName}
          onChange={(event) => setPatientLastName(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          id="number"
          label="Medical Record #"
          name="number"
          autoFocus
          value={patientMRN}
          onChange={(event) => setPatientMRN(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          name="number"
          label="Phone Number"
          id="number"
          value={patientPhoneNumber}
          onChange={(event) => setPatientPhoneNumber(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          name="number"
          label="Cell Phone Number"
          id="number"
          value={patientCellNumber}
          onChange={(event) => setPatientCellNumber(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          name="email"
          label="Email Address"
          value={patientEmail}
          onChange={(event) => setPatientEmail(event.target.value)}
        />
        <FormControl
          className={classes.formControl}
          required
          style={{ marginLeft: '15px', marginRight: '0px', width: '40%' }}
        >
          <br></br>
          <InputLabel id="bp-device-select-label">
            Blood Pressure Device
          </InputLabel>
          <Select
            labelId="bp-device-select-label-id"
            id="bp-device-select-id"
            value={patientBPDevice}
            onChange={(event) => setPatientBpDevice(event.target.value)}
          >
            <MenuItem value={CARDIOWELL}>Cardiowell</MenuItem>
            <MenuItem value={TRANSTEK}>TT Tele RPM</MenuItem>
            <MenuItem value={AD}>{'A&D'}</MenuItem>
            <MenuItem value={WITHINGS}>Withings</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          name="number"
          label={
            patientBPDevice === WITHINGS
              ? 'Withings Device'
              : 'Blood Pressure IMEI'
          }
          disabled={patientBPDevice === WITHINGS}
          value={displayedBPDeviceIMEI()}
          onChange={(event) => setDisplayedBpIMEI(event.target.value)}
        />
        <FormControl
          className={classes.formControl}
          required
          style={{ marginLeft: '15px', marginRight: '0px', width: '40%' }}
        >
          <br></br>
          <InputLabel id="ws-device-select-label">Weight Device</InputLabel>
          <Select
            labelId="ws-device-select-label-id"
            id="ws-device-select-id"
            value={patientWeightDevice}
            onChange={(event) => setPatientWeightDevice(event.target.value)}
          >
            <MenuItem value={CARDIOWELL}>Cardiowell</MenuItem>
            <MenuItem value={TRANSTEK}>TT Tele RPM</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          name="number"
          label="Weight IMEI"
          value={displayedWeightDeviceIMEI()}
          onChange={(event) => setDisplayedWeightIMEI(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          name="number"
          label="Pulse Oximeter IMEI"
          value={patientPulseIMEI}
          onChange={(event) => setPatientPulseIMEI(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          name="number"
          label="Glucose IMEI"
          value={patientGlucoseIMEI}
          onChange={(event) => setPatientGlucoseIMEI(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          name="address"
          label="Address"
          value={patientAddress}
          onChange={(event) => setPatientAddress(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          name="city"
          label="City"
          value={patientCity}
          onChange={(event) => setPatientCity(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          name="state"
          label="State"
          value={patientState}
          onChange={(event) => setPatientState(event.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          style={{ marginLeft: '15px', width: '40%' }}
          name="zip"
          label="Zip"
          value={patientZip}
          onChange={(event) => setPatientZip(event.target.value)}
        />
        <FormControl
          className={classes.formControl}
          style={{ marginLeft: '15px', marginRight: '0px', width: '40%' }}
        >
          <br></br>
          <InputLabel id="demo-simple-select-label">Time Zone</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={patientTimeZone}
            onChange={(event) => {
              setPatientTimeZone(event.target.value)
            }}
          >
            <MenuItem disabled={timezoneRequired ? true : false} value="local">
              Use Local Time
            </MenuItem>
            {timeZoneOptions.map(({ value, label }, i) => (
              <MenuItem key={i} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {canEditClinic && (
          <ClinicSelect
            clinic={patientClinic}
            setClinic={setPatientClinic}
            clinics={clinics}
          />
        )}
        <FormControlLabel
          className={classes.formControl}
          style={{ marginLeft: '15px', marginTop: '30px' }}
          control={
            <Switch
              checked={deviceNotificationsEnabled}
              onChange={(event) =>
                setDeviceNotificationsEnabled(event.target.checked)
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={'SMS Consent'}
        />
        <p>
          Warning: Please be careful when changing information such as IMEI
          numbers.
        </p>
        <p>{serverMessage}</p>
        <div>
          <Button onClick={() => setModalShow(false)}>Close</Button>
          {canDelete && <Button onClick={() => deletePatient()}>Delete</Button>}
          <Button
            onClick={() =>
              savePatientChanges({
                id: selectedPatientId,
                firstName: patientFirstName,
                lastName: patientLastName,
                phoneNumber: patientPhoneNumber,
                cellNumber: patientCellNumber,
                MRN: patientMRN,
                email: patientEmail,
                address: patientAddress,
                city: patientCity,
                state: patientState,
                zip: patientZip,
                timeZone: patientTimeZone,
                bpIMEI: patientBPIMEI,
                ttBpIMEI: patientTTBPIMEI,
                weightIMEI: selectedWeightIMEI,
                ttWeightIMEI: patientTTWeightIMEI,
                pulseIMEI: patientPulseIMEI,
                glucoseIMEI: patientGlucoseIMEI,
                selectedBpDevice: patientBPDevice,
                selectedWeightDevice: patientWeightDevice,
                patientClinic: patientClinic,
                deviceNotificationsEnabled,
              })
            }
          >
            Save Changes
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
