import { useId } from 'react'
import MuiDialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CloseIcon from '@mui/icons-material/Close'

export const Dialog = ({
  open,
  children,
  title,
  actions,
  handleClose,
  id = 'dialog',
  fullWidth = false,
  maxWidth = 'sm',
}) => {
  const titleId = useId()
  return (
    <MuiDialog
      PaperProps={{
        sx: { width: '100%' },
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby={titleId}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle sx={{ m: 0 }} id={titleId}>
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </MuiDialog>
  )
}
