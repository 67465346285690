import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import cardiowell1 from '../images/cardiowell1.png'
import Modal from 'react-bootstrap/Modal'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://material-ui.com/">
        cardiowell
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function TermsConditions(props) {
  const classes = useStyles()
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img style={{ width: '50%' }} src={cardiowell1}></img>
        {/* <Typography component="h1" variant="h5">
          Sign in
        </Typography> */}
        <h2>WELLADAPT INC.’S TERMS OF USE AGREEMENT</h2>
        <p>Last Updated: August 12, 2020</p>
        <p> </p>
        <p>
          <strong>
            PLEASE READ CAREFULLY BEFORE USING WELLADAPT INC. PRODUCTS AND
            SERVICES.&nbsp; YOU ARE ENTERING INTO A LEGALLY BINDING
            AGREEMENT.&nbsp;&nbsp;
          </strong>
          BY REGISTERING FOR A USER ACCOUNT DURING ACCOUNT REGISTRATION AND
          USING THE SERVICES PROVIDED THROUGH THE APPS, PROUCTS AND WEBSITES,
          YOU ACCEPT ALL THE TERMS OF THIS AGREEMENT.
        </p>
        <p>
          This WellAdapt Inc. Terms <strong>(“Terms”</strong>) of Use Agreement
          (the “<strong>Agreement</strong>”) is a legal agreement by and between
          WellAdapt Inc., (“<strong>us</strong>”, “<strong>we</strong>”, or “
          <strong>our</strong>”) provider of the Cardiowell App (the “
          <strong>Application</strong>”, “<strong>App</strong>”) and other{' '}
          <strong>Products and Services</strong>&nbsp;(Apps, Products, Websites
          and Services), and ‘You’ the End User.
        </p>
        <p>
          The Agreement governs your use of all Apps, Websites, Products, and
          Services that you may download, install, or use on your computer,
          mobile device, network or subscribe to.&nbsp; These Terms apply to all
          visitors, users, and others who wish to access or use the App, Product
          or Service.&nbsp; By accessing or using any of the Apps, Websites,
          Products and Services you agree to be bound by this Agreement.&nbsp;
        </p>
        <h2 style={{ textAlign: 'justify' }}>
          <span style={{ fontSize: '12pt' }}>
            1. RELATIONSHIP BETWEEN YOU AND WELLADAPT INC.
          </span>
        </h2>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          WellAdapt Inc. is the maker and provider of the Cardiowell App that
          allows a user to monitor their wellness and biometric data.&nbsp;
          WellAdapt Inc. also provides services to help users better understand
          their data and provide advice in the form of coaching,
          recommendations, and consultation.&nbsp; By installing and using the
          Cardiowell App, you expressly agree to the following WellAdapt Inc.
          Terms of Use. The Cardiowell App is the property of WellAdapt Inc.,
          having its Business office located in Santa Barbara, CA United States
          of America (USA).&nbsp; &nbsp;
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          The Application (<strong>Cardiowell App</strong>) is composed of a
          graphic user interface, that can be used on iOS devices allowing users
          to interact with features made available by the graphical user
          interface that allows you to view, save, store, share and access your
          data.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          A Hardware Device (hereafter “<strong>Product</strong>”) is a
          biometric measurement device such as a weight scale or blood pressure
          device provided by WellAdapt Inc. or third party, designed to measure
          and transmit biomedical results to a cloud server for storage and
          display within the Cardiowell App.&nbsp;
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          A Service (hereafter <strong>“Service”</strong>) may include health
          promotion coaching advice and recommendations offered and provided by
          WellAdapt Inc. at the users discretion.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          As a user, you acknowledge and guarantee:
        </p>
        <p style={{ marginLeft: '0.25in', marginRight: '0in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          That you have obtained, fully read and understood the WellAdapt Inc.
          Terms of Use;
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To be in possession of the Cardiowell App Terms of Use on a durable
          media especially, but not limited to, by printing them out;
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To be of age and aware of legal regulations or to have the signing
          authority;
        </p>
        <p style={{ marginLeft: '0.25in', marginRight: '0in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          That you have the right to access and use the Application.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Any objection or disagreement with the WellAdapt Inc. Terms of Use is
          interpreted as a refusal to agree to the Terms. IF YOU WERE TO
          DISAGREE WITH THESE TERMS OF USE, YOU ARE REQUESTED NOT TO USE THE
          APPLICATION, AND WHENEVER NEEDED UNINSTALL THE APPLICATION.
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>
            2. MODIFICATION OF THE TERMS AND CONDITIONS OF USE AGREEMENT
          </span>
        </h2>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          It is understood between the parties that WellAdapt Inc. keeps the
          right, in any case, to modify all or part of the Agreement, to reflect
          changes to the law or any applicable regulations, modifications of our
          Products and Services or any event which is deemed sufficient by
          WellAdapt Inc. to command such revisions. Any new version of the
          WellAdapt Inc. Terms of Use will not apply retroactively but replaces
          and supersedes the previous Agreement.
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>3. PURPOSE</span>
        </h2>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          The WellAdapt Inc. Terms of Use Agreement may apply when you download,
          install and/or use the Cardiowell App, or use any WellAdapt Inc.
          Products, Services or Websites.&nbsp;
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>
            4. PREREQUISITE FOR APPLICATIONS USAGE, DOWNLOADING AND UPDATE
          </span>
        </h2>
        <p>
          <strong>a. Cardiowell App</strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          You must download the Application on your personal iOS device before
          using it. Cardiowell App download, exchange of data between the
          Cardiowell App and WellAdapt Inc. managed servers require an Internet
          connection.&nbsp; The quality of the Internet connection and the
          integrity of the iOS device are essential elements for using the
          Cardiowell App. For more information please contact our customer
          service department.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Downloading the Cardiowell App should not be considered as a
          presumption that your iOS device is compatible with Cardiowell App
          and/or any WellAdapt Products. Before any use, you are responsible to
          check if your iOS device and the Cardiowell App and any Cardiowell
          Products are compatible.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          You must comply with the terms and conditions attached to the Apple
          App store used to download the Cardiowell App.
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>
            5. YOUR USE OF THE CARDIOWELL APP
          </span>
        </h2>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>License</strong>
        </p>
        <p>
          Subject to your compliance with the Terms of this Agreement, We grant
          you a limited, non-exclusive and non-transferable license to use the
          Application on an iOS device, and to use any supported Products and
          Services for your personal use.&nbsp;&nbsp; Your use of the App may
          also require you to use certain services provided by us, all of which
          is governed by this agreement.&nbsp; Software may include desktop
          applications, firmware, mobile applications and web
          applications.&nbsp;&nbsp;We recommend against installing the
          Applications on non-private mobile devices as your private information
          may be compromised.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          You may download, install and use the Cardiowell App in accordance
          with this Agreement.&nbsp; When you complete the installation of the
          Cardiowell App, you will need to create a Cardiowell user
          account.&nbsp; Your account may also be used as part of any Services,
          subject to these Terms and our Privacy Policy.&nbsp; To create an
          account, you must provide us with an email address and select a
          password.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Data retrieved and presented to you through the Cardiowell App may not
          be accurate due to an inappropriate use of the Cardiowell App. As a
          consequence, you may not use such data as a specific basis to making
          healthcare decisions.&nbsp;&nbsp;
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          By using the Cardiowell App, you are restricted from:
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Distributing the App, including by electronic transfer from one
          computer to another, over a network or otherwise.
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Decompiling, reverse engineering, or attempting to reconstruct,
          identify or discover any source code, underlying user interface
          techniques or algorithms, disassemble, or otherwise reduce the
          software to a human perceivable form.
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Modifying, adapting, translating, renting, leasing, loaning,
          reselling, distributing, or creating derivative works based upon the
          App.
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Granting any sublicense, leases or other rights in the App to any
          third party.
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Taking any action that would cause the App to be placed in the public
          domain.
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Making any use of the App in contradiction with any law or
          regulations, third party rights such as Intellectual Property rights,
          Privacy etc.;
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Acting in any manner that shall create any prejudice to WellAdapt, its
          affiliate, partners or any user of our App, Products and Services;
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Fraudulently introducing any data on the Application or through the
          Application;
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Fraudulently introducing any data on the Website or on WellAdapt’s
          data server;
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Interfering, obstructing, distorting the proper working of the Site or
          to use the Site in any manner whatsoever to interfere with any Rights
          of third parties or WellAdapt;
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Undertaking any action and/or use any method allowing data to be
          retrieved, including Data scraping, data harvesting, Web crawling from
          the Website or our database enabling any direct or indirect migration
          and/or duplication of a significant portion of the data and services
          accessible from the Website;
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Probing, scanning, and technically analyzing the App;
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Testing the vulnerability, the performance and functionality of the
          Application for reasons other than the ones strictly necessary to use
          the Application;
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Breaching any security measures implemented on the Application by
          WellAdapt;
        </p>
        <p style={{ marginLeft: '0.25in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Using any illegitimate means to breach any authentication method,
          implemented by WellAdapt Inc. in order to enable connection by the
          user to the Application and/or to the purchasing module;
        </p>
        <p style={{ marginLeft: '0.25in', marginRight: '0in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Accessing any section of the App that is not specifically accessible
          to normal use of the App.&nbsp;
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>
            6. CONNECTION AND IDENTIFICATION
          </span>
        </h2>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Your identification through an account is requested before any
          connection, access and use of the Cardiowell App.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          By connecting your Cardiowell user account and any associated
          products, you identify yourself as the user of the Product. This
          connection allows you to:
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Link data collected by supported Product(s) to your account;
        </p>
        <p style={{ marginLeft: '0.5in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Have access to your data through the Cardiowell App;
        </p>
        <p style={{ marginLeft: '0.5in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Save your personal data in our data centers in accordance with our
          Privacy Policy.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          All users are required to have an account, and have the obligation to
          prevent any third parties from using your account. You must therefore
          verify that your password is strong and secure, and log off the App
          when you are no longer using it. Access to your account can provide
          access to your personal data.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Any Connection to your account from a third party that you have given
          consent is your responsibility. WellAdapt Inc. is not responsible for
          any personal data communicated to third parties through your implicit
          or explicit permission.&nbsp; Leaving the App unattended without
          logging out is considered an implicit authorization.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of your
          account and password, including but not limited to the restriction of
          access to your computer and/or account. You agree to accept
          responsibility for any and all activities or actions that occur under
          your account and/or password, whether your password is with our
          Service or a third-party service. You must notify us immediately upon
          becoming aware of any breach of security or unauthorized use of your
          account.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          We reserve the right to refuse any user from opening or maintaining an
          account for any reason.&nbsp; You may not provide false information
          during the registration process.&nbsp; If your account is terminated,
          you many not join Cardiowell again without express permission.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          To facilitate access through the electronic system, we remind you that
          your identification can be automated through the use of Cookies.&nbsp;
          &nbsp;
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>Age Restriction.</strong>&nbsp;When you create an account with
          us, you guarantee that you are above the age of 13, and that the
          information you provide us is accurate, complete, and current at all
          times. Inaccurate, incomplete, or obsolete information may result in
          the immediate termination of your account on the Service.
        </p>
        <p>
          You may not use as a username the name of another person or entity or
          that is not lawfully available for use, a name or trademark that is
          subject to any rights of another person or entity other than you,
          without appropriate authorization. You may not use as a username any
          name that is offensive, vulgar or obscene.
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>7. INTELLECTUAL PROPERTY</span>
        </h2>
        <p>
          The Service and its original content, features and functionality are
          and will remain the exclusive property of WellAdapt Inc. and its
          licensors. The Service is protected by copyright, trademark, and other
          laws of both the United States and foreign countries. Our trademarks
          and trade dress may not be used in connection with any product or
          service without the prior written consent of WellAdapt Inc.
          &nbsp;&nbsp;
        </p>
        <p>
          <strong>a. Cardiowell App</strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          We and our licensors and/or suppliers, as applicable retain all right,
          title, and interest to the Apps, including patents, copyrights,
          trademarks, and trade secrets contained therein.&nbsp; When you
          download, install and/or use the Cardiowell App, WellAdapt Inc. grants
          you a personal, non-transferable, non-assignable, non-sub licensable
          license, non-exclusive, strictly limited to the purpose of this
          agreement and to the country from which you connect and use the App or
          any of its updates, to all Intellectual Property rights attached to
          the connection and use of the functionality offered by WellAdapt Inc.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          This license does not grant you the right to undertake any operation
          of reverse engineering, extraction, de-compiling, improvements,
          modification, marketing, renting, licensing, translation of the
          Cardiowell App.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Unless otherwise specified, the right hereby granted may apply to any
          updates and evolution of the Cardiowell App.
        </p>
        <p>
          <strong>b. Application’s Visual Elements</strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          The Application is composed of elements such as text, interfaces,
          photographs, charts, pictures, browsing means, trademarks, logo,
          graphics, design, music, artworks, computer code, software, fonts or
          any other element containing any Intellectual property rights held,
          owned, controlled by WellAdapt Inc. or licensed to WellAdapt Inc. to
          be incorporated into the Application. (Hereinafter “
          <strong>Application Element</strong>”).
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          This license does not grant you the right to undertake any operation
          of reverse engineering, extraction, de-compiling, improvements,
          modification, marketing, renting, licensing, translation of the
          Website or all or part of any Application Element.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Any use of all or part of any Application Element that is not
          expressly allowed by these WellAdapt Inc. Terms of Use Agreement
          requires our express and prior agreement.
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>8. THIRD-PARTY FUNCTIONALITY</span>
        </h2>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          We may introduce functionality or a feature developed and produced by
          third Parties into the Application for the purpose to provide you
          additional functionality and features. You may agree to the Third
          Party terms and conditions when using this functionality or feature.
          As a third party to this contract, we are not liable for any use and
          direct or indirect consequence attached to the use of such
          functionality or feature.
        </p>
        <p>
          WellAdapt Inc. web sites are hosted by Squarespace.&nbsp; Squarespace
          may automatically collect information in the aggregate.&nbsp; View
          Squarespace Terms of Services and Privacy Policy.
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>
            THE CARDIOWELL APP, WELLADAPT SERVICES, AND PRODUCTS MAY STORE DATA
            AT Heroku Inc, and Data Labs Corporation CONTRACTED, THIRD PARTY
            CLOUD DATABASED AND SERVICE COMPANIES.
          </span>
        </h2>
        <h2>
          <span style={{ fontSize: '12pt' }}>9. DATA PROTECTION</span>
        </h2>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          At WellAdapt Inc., protecting the privacy of our users is of great
          importance. You can read our Privacy Policy for more details.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          We may collect and record some personal data, such as name, email, and
          biometric data (weight, blood pressure, etc.) when you use WellAdapt
          Inc. products, including the Cardiowell App, or supporting
          Websites.&nbsp; Personal data is any information relating to directly
          or indirectly identifying you as a user. WellAdapt Inc. provides you
          with access to those data as part of the Cardiowell App and associated
          Products and Services.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Any collection and processing of personal data must be carried out
          with your consent. You can also object, at any time on compelling
          legitimate grounds relating to your particular situation, to the
          processing of data related to you.
        </p>
        <p>
          The Cardiowell app is using the Apple HealthKit framework, and will
          NOT store any users’ health information in iCloud.
          <br />
          The Cardiowell app will NOT use personal data gathered from the
          HealthKit API for advertising or other use-based data mining purposes
          other than improving health and wellness, or for the purpose of
          medical research.
        </p>
        <p>
          This app will NOT share user data acquired via the HealthKit API with
          third parties without user consent.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          We grant you the right of access to and the right to rectify any data
          concerning You by contacting our{' '}
          <a href="http://www.welladapt.com">customer service department</a>:
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>By Mail</strong>: WellAdapt Inc. 315 Meigs Rd. Suite A385,
          Santa Barbara, CA 93109
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>By email</strong>:&nbsp;privacy@welladapt.com
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>10. PURCHASES</span>
        </h2>
        <p>
          If you wish to purchase any Product or Service made available by
          WellAdapt Inc. ("Purchase"), you may be asked to supply certain
          information relevant to your Purchase including, without limitation,
          your credit card number, the expiration date of your credit card, your
          billing address, and your shipping information.
        </p>
        <p>
          You represent and warrant that: (i) you have the legal right to use
          any credit card(s) or other payment method(s) in connection with any
          Purchase; and that (ii) the information you supply to us is true,
          correct and complete.
        </p>
        <p>
          The Service may employ the use of third party services for the purpose
          of facilitating payment and the completion of Purchases. By submitting
          your information, you grant us the right to provide the information to
          these third parties subject to our Privacy Policy.
        </p>
        <p>
          We reserve the right to refuse or cancel your order at any time for
          reasons including but not limited to: product or service availability,
          errors in the description or price of the product or service, error in
          your order or other reasons.
        </p>
        <p>
          We reserve the right to refuse or cancel your order if fraud or an
          unauthorized or illegal transaction is suspected.
        </p>
        <p>
          <strong>a.) Subscriptions</strong>
        </p>
        <p>
          Some parts of the Service are billed on a subscription basis
          ("Subscription(s)"). You will be billed in advance on a recurring and
          periodic basis ("Billing Cycle"). Billing cycles are set either on a
          monthly or annual basis, depending on the type of subscription plan
          you select when purchasing a Subscription.&nbsp;
        </p>
        <p>
          At the end of each Billing Cycle, your Subscription will automatically
          renew under the exact same conditions unless you cancel it or
          WellAdapt Inc. cancels it. You may cancel your Subscription renewal by
          contacting WellAdapt Inc. customer support team.
        </p>
        <p>
          A valid payment method, including credit card or PayPal, is required
          to process the payment for your Subscription. You shall provide
          WellAdapt Inc. with accurate and complete billing information
          including full name, address, state, zip code, telephone number, and a
          valid payment method information. By submitting such payment
          information, you automatically authorize WellAdapt Inc. to charge all
          Subscription fees incurred through your account to any such payment
          instruments.&nbsp;&nbsp;&nbsp;
        </p>
        <p>
          Should automatic billing fail to occur for any reason, WellAdapt Inc.
          will issue an electronic invoice indicating that you must proceed
          manually, within a certain deadline date, with the full payment
          corresponding to the billing period as indicated on the invoice.
        </p>
        <p>
          <strong>b.) Free Trial</strong>
        </p>
        <p>
          WellAdapt Inc. may, at its sole discretion, offer a Subscription with
          a free trial for a limited period of time ("Free Trial").
        </p>
        <p>
          You may be required to enter your billing information in order to sign
          up for the Free Trial.
        </p>
        <p>
          If you do enter your billing information when signing up for the Free
          Trial, you will not be charged by WellAdapt Inc. until the Free Trial
          has expired. On the last day of the Free Trial period, unless you
          cancelled your Subscription, you will be automatically charged the
          applicable Subscription fees for the type of Subscription you have
          selected.
        </p>
        <p>
          At any time and without notice, WellAdapt Inc. reserves the right to
          (i) modify the terms and conditions of the Free Trial offer, or (ii)
          cancel such Free Trial offer.
        </p>
        <p>
          <strong>c.) Fee Changes</strong>
          <br />
          WellAdapt Inc., in its sole discretion and at any time, may modify the
          Subscription fees for the Subscriptions. Any Subscription fee change
          will become effective at the end of the then-current Billing Cycle.
        </p>
        <p>
          WellAdapt Inc. will provide you with a reasonable prior notice of any
          change in Subscription fees to give you an opportunity to terminate
          your Subscription before such change becomes effective.
        </p>
        <p>
          Your continued use of the Service after the Subscription fee change
          comes into effect constitutes your agreement to pay the modified
          Subscription fee amount.
        </p>
        <p>
          <strong>d.) Refunds</strong>
        </p>
        <p>
          Certain refund requests for Subscriptions may be considered by
          WellAdapt Inc. on a case-by-case basis and granted in sole discretion
          of WellAdapt Inc.&nbsp;
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>11. DATA HOSTING</span>
        </h2>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          By using WellAdapt Inc. products and services including the Cardiowell
          App, you may allow WellAdapt Inc., or any third party designated for
          this purpose, to store data collected. You may not be able to access
          your data at all times, as we cannot guarantee you that your data will
          be available forever.&nbsp; Data is being hosted at Object Labs
          Corporation and Heroku.&nbsp; See Privacy Policy for more details.
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>
            12. AVAILABILITY, ERRORS AND INACCURACIES
          </span>
        </h2>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          WellAdapt Inc. uses reasonable commercial efforts to maintain the
          availability of its Products and Services, but does not guarantee any
          specific availability of services.&nbsp; WellAdapt Inc. cannot be held
          liable for temporary service interruptions.
        </p>
        <p>
          We are constantly updating Product and Service offerings. We may
          experience delays in updating information on the Service and in our
          advertising on other web sites. The information found on the Service
          may contain errors or inaccuracies and may not be complete or current.
          Products or Services may be mispriced, described inaccurately, or
          unavailable on the Service and we cannot guarantee the accuracy or
          completeness of any information found on the Service.
        </p>
        <p>
          We therefore reserve the right to change or update information and to
          correct errors, inaccuracies, or omissions at any time without prior
          notice.
        </p>
        <p>
          Because End Users will access the Service over the public Internet,
          temporary disruptions of network connectivity will occur from time to
          time. Internet traffic is usually routed through many different
          Internet backbone providers on the way to its destination. WellAdapt
          Inc. will not be liable for interruption or delays in transmission or
          errors or defects in transmission or failure to transmit when caused
          by any Internet backbone provider.
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>13. WARRANTIES</span>
        </h2>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          The following applies to:
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          The Website
        </p>
        <p style={{ marginLeft: '0.5in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          The Cardiowell App
        </p>
        <p style={{ marginLeft: '0.5in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Hosting services
        </p>
        <p style={{ marginLeft: '0.5in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Services
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          The Hardware Products
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          <span style={{ fontSize: '10pt' }}>
            ▪
            <span style={{ fontSize: '7pt' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Any improvements of the above.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Unless required by any mandatory legal provision, the Website, the
          Applications, Hosting services, the Services and improvements of the
          above (hereinafter the “<strong>Elements</strong>”) are provided on an
          “AS IS” and “AS AVAILABLE” basis. It is used AT YOUR OWN RISK WITHOUT
          EXPRESS OR IMPLIED WARRANTY OR CONDITION OF ANY KIND.&nbsp; WE
          DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS, WELLNES, OR
          HEALTH FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. WELLADAPT INC.
          MAKES, AND USER RECEIVES, IN CONNECTION WITH THE ELEMENTS NO OTHER
          WARRANTY, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, THE
          IMPLIED WARRANTIES OF MERCHANTABILITY, OF FITNESS, WELLNESS OR HEALTH
          FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WE DO NOT PROMISE THAT
          THE PRODUCTS, SERVICES, OR ANY CONTENT OR FEATURE, INCLUDING ANY
          INFORMATION AND DOCUMENTS ARE FREE OF ERROR OR DEFECTS, OR THAT THE
          AFOREMENTIONED WILL BE CORRECTED.&nbsp; WELLADAPT INC. DISCLAIMS ANY
          WARRANTIES, EXPRESS OR IMPLIED REGARDING SECURITY, ACCURACY,
          RELIABILITY, TIMELINESS AND PERFORMANCE.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          WELLADAPT DISCLAIMS ANY AND ALL LIABILITY FOR ANY DAMAGE DIRECTLY OR
          INDIRECTLY SUFFERED BY YOU, IN CONNECTION WITH OR RELATED TO YOUR USE
          OF ANY APPS, PRODUCTS, OR SERVICES. YOU MUST ASSUME TOTAL
          RESPONSIBILITY FOR YOUR USE. YOUR SOLE REMEDY AGAINST WELLADAPT INC.
          FOR ANY DISSATISFACTION OR DECEPTION WITH ALL OR PART OF THE APP,
          PRODUCT AND/OR SERVICES OR ANY CONTENT DIRECTLY OR INDIRECTLY LINKED
          TO IT IS TO STOP USE.&nbsp; THIS LIMITATION OF RELIEF IS AN ESSENTIAL
          PART OF THE BARGAIN BETWEEN THE PARTIES WHITH OUT WHICH THE PARTIES
          WOULD NOT HAVE ENTERED INTO AN AGREEMENT.
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>14. CLASSIFICATION</span>
        </h2>
        <p>
          The Cardiowell App, stores, transmits and displays weight, blood
          pressure and other body metric data allowing for self-analysis and
          remote viewing.
        </p>
        <p>
          Cardiowell is not intended to replace medical supervision, medication
          or a Doctor’s order.&nbsp; Users are solely responsible for the
          interpretation of the data and to consult professional medical help
          with any healthcare related issues.&nbsp; Cardiowell is not liable for
          injury or fault when using the Cardiowell App.&nbsp; Users do so at
          their own risk.&nbsp; It is used solely as an investigation device for
          personal use.
        </p>
        <p>
          Not Medical Advice.&nbsp;&nbsp;WellAdapt is not providing medical
          advice via the Wellness Service, Website or
          Applications.&nbsp;&nbsp;The Cardiowell App is not intended for use in
          the diagnosis, cure, treatment or prevention of disease.&nbsp;
          WELLADAPT INC. IS NOT RESPONSIBLE FOR ANY INCORRECT OR INACCURATE
          ADVICE OR CONSULTATION PROVIDED TO YOU BY USING THE SERVICE.
        </p>
        <p>
          WellAdapt Inc. is not a licensed health care provider and has no
          expertise in predicting or treating medical conditions or in
          determining the effect of any specific exercise on a medical
          condition.
        </p>
        <p>
          WellAdapt Inc. does not recommend or endorse any specific drugs,
          tests, physicians, products, procedures, and opinions.
        </p>
        <p>
          The Cardiowell App promotes healthy breathing patterns and stress
          management, which as part of a healthy lifestyle may reduce the risks
          associated with hypertension.&nbsp;
        </p>
        <p>It is considered a low risk device.&nbsp;</p>
        <h2>
          <span style={{ fontSize: '12pt' }}>15. TERMINATION</span>
        </h2>
        <p>
          We may terminate or suspend your account and bar access to the Apps,
          Websites, Products and Services immediately, without prior notice or
          liability, under our sole discretion, for any reason whatsoever and
          without limitation, including but not limited to a breach of the
          Terms.
        </p>
        <p>
          If you wish to terminate your account, you may simply discontinue
          using the Service.
        </p>
        <p>
          All provisions of the Terms, which by their nature should survive
          termination, shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>16. LIMITATIONS OF LIABILITY</span>
        </h2>
        <p>
          WellAdapt Inc. assumes no responsibility for any error, omission,
          interruption, deletion, defect, delay in operation or transmission,
          communications line failure, theft, or destruction or unauthorized
          access to, or alteration of, any content on the service.
        </p>
        <p>
          Under no circumstance shall WellAdapt Inc. be responsible for any loss
          or damage, including personal injury or death, resulting from use of
          the Service, from any content posted on or through the Service, or
          from the conduct of any users of the Apps, Products, Websites, or
          Services (Product and Services).
        </p>
        <p>
          To the fullest extent permitted by law, In no event shall WellAdapt
          Inc., nor its directors, employees, partners, agents, suppliers, or
          affiliates, be liable for any indirect, incidental, special,
          consequential or punitive damages, including without limitation, loss
          of profits, data, use, goodwill, or other intangible losses, resulting
          from (i) your access to or use of or inability to access or use the
          Product and Services; (ii) any conduct or content of any third party
          on the Service; (iii) any content obtained from the Service; and (iv)
          unauthorized access, use or alteration of your transmissions or
          content, whether based on warranty, contract, tort (including
          negligence) or any other legal theory, whether or not we have been
          informed of the possibility of such damage, and even if a remedy set
          forth herein is found to have failed of its essential
          purpose.&nbsp;Some states do not allow the types of limitations in
          this paragraph, so they may not apply to you.
        </p>
        <p>
          THE CONTENT WE PROVIDE THROUGH THE WEBSITE, INCLUDING ALL TEXT,
          PHOTOGRAPHS, IMAGES, ILLUSTRATIONS, GRAPHICS, AUDIO, VIDEO AND
          AUDIO-VIDEO CLIPS, AND OTHER MATERIALS, WHETHER PROVIDED BY US OR BY
          OTHER ACCOUNT HOLDERS OR THIRD PARTIES IS NOT INTENDED TO BE AND
          SHOULD NOT BE USED IN PLACE OF (A) THE ADVICE OF YOUR PHYSICIAN OR
          OTHER MEDICAL PROFESSIONALS, (B) A VISIT, CALL OR CONSULTATION WITH
          YOUR PHYSICIAN OR OTHER MEDICAL PROFESSIONALS, OR (C) INFORMATION
          CONTAINED ON OR IN ANY PRODUCT PACKAGING OR LABEL. OUR CONTENT DOES
          NOT CONSTITUTE MEDICAL ADVICE. SHOULD YOU HAVE ANY HEALTHCARE RELATED
          QUESTIONS, PLEASE CALL OR SEE YOUR PHYSICIAN OR OTHER HEALTHCARE
          PROVIDER PROMPTLY. SHOULD YOU HAVE AN EMERGENCY, CALL YOUR PHYSICIAN
          OR 911 IMMEDIATELY. YOU SHOULD NEVER DISREGARD MEDICAL ADVICE OR DELAY
          IN SEEKING MEDICAL ADVICE BECAUSE OF ANY CONTENT PRESENTED ON THIS
          SITE, AND YOU SHOULD NOT USE OUR CONTENT FOR DIAGNOSING OR TREATING A
          HEALTH PROBLEM. THE TRANSMISSION AND RECEIPT OF OUR CONTENT, IN WHOLE
          OR IN PART, OR COMMUNICATION VIA THE INTERNET, E-MAIL OR OTHER MEANS
          DOES NOT CONSTITUTE OR CREATE A DOCTOR-PATIENT, THERAPIST-PATIENT OR
          OTHER HEALTHCARE PROFESSIONAL RELATIONSHIP BETWEEN YOU AND US.
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>17. INDEMNIFICATION</span>
        </h2>
        <p>
          You agree to defend, indemnify and hold harmless WellAdapt Inc. and
          its licensee and licensors, and their employees, contractors, agents,
          officers and directors, from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney's fees), resulting from or
          arising out of a) your use and access of the Service, by you or any
          person using your account and password, or b) a breach of these Terms.
          You agree that your use of the Products and Services shall be in
          compliance with all applicable laws, regulations and guidelines.
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>18. GENERAL PROVISIONS</span>
        </h2>
        <p>
          <strong>a. Scope of the Agreement</strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          This version of the WellAdapt Inc. Terms of Use Agreement constitutes
          the entire agreement between us and cancels, excludes and replaces any
          previous conditions in its subject matter.
        </p>
        <p>
          <strong>b. Severability</strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          If any provision of the Conditions is held invalid or unenforceable by
          a court of competent jurisdiction, the invalid or unenforceable part
          or provision will be deemed as unwritten.
        </p>
        <p>
          <strong>c. Waiver</strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Any failure to exercise or delay in exercising any right, power or
          privilege under this Agreement shall not operate as a waiver; nor
          shall any single or partial exercise of any right, power or privilege
          preclude any other or further exercise thereof.
        </p>
        <p>
          <strong>d. Evidence – Electronic communication</strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Any notification and communication between WellAdapt Inc. and you can
          be realized by any electronic means of communication such as emails.
          You hereby grant us your express consent for sending you by electronic
          means of communication any notification through your Cardiowell
          Account.
        </p>
        <p>
          <strong>e. Governing law – Dispute resolution</strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          In the event of any controversy or dispute between WellAdapt Inc. and
          You arising out of or in connection with your use of any Products and
          Services (such as our Website, Product, or Applications) provided by
          WellAdapt Inc., the parties shall attempt, promptly, in good faith and
          before any judicial action, to resolve the dispute through alternative
          dispute resolution.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          This Agreement is entered into and shall be governed by the internal
          laws of the United States and the state of California, without regard
          to its choice of law rules.&nbsp;&nbsp; The exclusive venue for any
          dispute relating to these terms and conditions is Santa Barbara County
          California.&nbsp;&nbsp;Both parties consent to the personal
          jurisdiction of these courts.&nbsp;&nbsp;Nothing in this agreement
          limits either party’s ability to seek equitable relief.&nbsp;&nbsp;The
          Parties may agree to resolve their dispute in accordance with
          California law.&nbsp;
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>19. CHANGES</span>
        </h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will provide at
          least 30 days notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p>
          By continuing to access or use our Product and Services after any
          revisions become effective, you agree to be bound by the revised
          terms. If you do not agree to the new terms, you are no longer
          authorized to use the Service.
        </p>
        <h2>
          <span style={{ fontSize: '12pt' }}>20. ACCEPTANCE</span>
        </h2>
        <p>
          The parties acknowledge that they have read the terms of this
          Agreement and hereby agree to be bound by the terms thereof. This
          Agreement will become effective upon End User’s acceptance by
          electronic acknowledgment on the Cardiowell App, and understanding
          that the Terms of Use Agreement is also found on the Apple AppStore
          and WellAdapt Inc. websites. Each time End User logs into the
          Cardiowell App, Website, or uses any Products and Services, End User
          is reaffirming continued acceptance of this Agreement.
        </p>
        <p> </p>
        <p>
          <strong>Contact Us</strong>
        </p>
        <p>If you have any questions about these Terms, please contact us.</p>
        <p>privacy@welladapt.com</p>
        <p>Updated on August 12, 2020</p>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
