import { useEffect, useState } from 'react'
import { getCustomers } from '../customer/getCustomers'

const CUSTOMERS_LIMIT = 1000

const getClinicsByCustomerId = (customers, id) => {
  const customer = customers.find(({ _id }) => _id === id)
  return customer?.clinics || []
}

const getEndpointsByCustomerId = (customers, id) => {
  const customer = customers.find(({ _id }) => _id === id)
  return customer?.endpoints || []
}

export const useCustomers = ({ customerId, disabled }) => {
  const [isCustomersLoaded, setCustomersLoaded] = useState(false)
  const [customers, setCustomers] = useState([])
  const [clinics, setClinics] = useState([])
  const [endpoints, setEndpoints] = useState([])

  useEffect(() => {
    if (!disabled) {
      getCustomers({ pageSize: CUSTOMERS_LIMIT }).then(({ customers }) => {
        setCustomers(customers)
        setCustomersLoaded(true)
      })
    }
  }, [disabled])

  useEffect(() => {
    setClinics(getClinicsByCustomerId(customers, customerId))
    setEndpoints(getEndpointsByCustomerId(customers, customerId))
  }, [customerId])

  return {
    isCustomersLoaded,
    customers,
    clinics,
    endpoints,
  }
}
