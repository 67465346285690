import { Typography, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import moment from 'moment-timezone'

const PaperOutline = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'left',
}))

export const ClinicalNote = ({ createdAt, providerName, note }) => {
  const momentObj = moment(createdAt)
  const name = providerName?.firstName + ' ' + providerName?.lastName
  return (
    <PaperOutline variant="elevation" sx={{ mt: 2 }}>
      <Typography variant="subtitle2">
        {momentObj.format('MMM D, YYYY; h:mma')}
      </Typography>
      <Typography variant="subtitle2">{name}</Typography>
      <Typography>{`Note: ${note}`}</Typography>
    </PaperOutline>
  )
}
