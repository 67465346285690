import moment from 'moment'
import { parceBerryMedOximeterTime } from '../../../helpers/parceBerryMedOximeterTime'

export const parsePulseMeasures = ({ pulse = [], timeframe = 0, timeZone }) => {
  const arrayPulse = []
  const pulseChartArray = []
  const pulseTableArray = []
  let averageSpo2 = '-'

  for (let z = 0; z < pulse.length; z++) {
    const spo2DateUnix = moment(parceBerryMedOximeterTime(pulse[z].time)).unix()
    const utcSeconds = Math.floor(timeframe.getTime() / 1000)

    if (spo2DateUnix >= utcSeconds) {
      arrayPulse.push(pulse[z].spo2)
      pulseChartArray.push(pulse[z])
    }
  }

  if (arrayPulse.length) {
    const sumSpo2 = arrayPulse.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    )
    averageSpo2 = Math.round(sumSpo2 / arrayPulse.length)
  }

  for (let t = 0; t < pulseChartArray.length; t++) {
    const dateTime = parceBerryMedOximeterTime(pulseChartArray[t].time)

    const pulseObj = {
      date: moment(dateTime).tz(timeZone).toDate(),
      time: moment(dateTime).tz(timeZone).format('h:mm A'),
      spo2: pulseChartArray[t].spo2,
      pr: pulseChartArray[t].pr,
    }
    pulseTableArray.push(pulseObj)
  }

  return { pulseChartArray, pulseTableArray, averageSpo2 }
}
