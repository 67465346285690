export const providerDeletePatient = async (clinic, patientId) => {
  const inputValue = {
    patientClinic: clinic,
    id: patientId,
    providerID: sessionStorage.getItem('providerID'),
  }

  return fetch('/routes/users/providerDeletePatient', {
    method: 'POST',
    body: JSON.stringify(inputValue),
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      return {
        error: 'error',
        status: response.status,
        statusText: response.statusText,
      }
    })
    .catch((error) => {
      console.error(error)
      return { error }
    })
}
