export const getBpThresholdLevel = (systolic, diastolic, bpThresholds) => {
  if (!bpThresholds || !systolic || !diastolic) {
    return 'default'
  }

  if (
    (bpThresholds?.systolic?.crisis?.enabled &&
      systolic >= bpThresholds?.systolic?.crisis?.value) ||
    (bpThresholds?.diastolic?.crisis?.enabled &&
      diastolic >= bpThresholds?.diastolic?.crisis?.value)
  ) {
    return 'crisis'
  }

  if (
    (bpThresholds?.systolic?.stageTwo?.enabled &&
      systolic >= bpThresholds?.systolic?.stageTwo?.value) ||
    (bpThresholds?.diastolic?.stageTwo?.enabled &&
      diastolic >= bpThresholds?.diastolic?.stageTwo?.value)
  ) {
    return 'stageTwo'
  }

  if (
    (bpThresholds?.systolic?.stageOne?.enabled &&
      systolic >= bpThresholds?.systolic?.stageOne?.value) ||
    (bpThresholds?.diastolic?.stageOne?.enabled &&
      diastolic >= bpThresholds?.diastolic?.stageOne?.value)
  ) {
    return 'stageOne'
  }

  if (
    (bpThresholds?.systolic?.elevated?.enabled &&
      systolic >= bpThresholds?.systolic?.elevated?.value) ||
    (bpThresholds?.diastolic?.elevated?.enabled &&
      diastolic >= bpThresholds?.diastolic?.elevated?.value)
  ) {
    return 'elevated'
  }

  if (
    (bpThresholds?.systolic?.normal?.enabled &&
      systolic < bpThresholds?.systolic?.normal?.value) ||
    (bpThresholds?.diastolic?.normal?.enabled &&
      diastolic < bpThresholds?.diastolic?.normal?.value)
  ) {
    return 'normal'
  }

  return 'default'
}
