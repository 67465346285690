import { PatientDataGrid } from '../PatientDataGrid'
import { dateTimeColumns, commonColumnProps } from '../PatientDataGridColumns'

const columns = [
  ...dateTimeColumns,
  {
    ...commonColumnProps,
    field: 'systolic',
    headerName: 'Systolic (mmHg)',
    type: 'number',
    width: 160,
  },
  {
    ...commonColumnProps,
    field: 'diastolic',
    headerName: 'Diastolic (mmHg)',
    type: 'number',
    width: 160,
  },
  {
    ...commonColumnProps,
    field: 'pulse',
    headerName: 'Pulse (bpm)',
    type: 'number',
    width: 160,
  },
]

export const BloodPressureGrid = ({ gridData = [] }) => {
  return <PatientDataGrid gridData={gridData} columns={columns} />
}
