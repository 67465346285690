import { useState, useId } from 'react'
import {
  Alert,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { ClinicalNote } from './ClinicalNote'
import { saveClinicalNote } from './saveClinicalNote'
import { useClinicalNoteData } from './useClinicalNoteData'

export const ClinicalNoteView = ({ patient, timeframe }) => {
  const formId = useId()
  const date = new Date(timeframe)
  const { isLoading, notes, setNotes } = useClinicalNoteData({
    patientId: patient.id,
    from: date.toISOString(),
  })
  const [openNote, setOpenNote] = useState(false)
  const [serverError, setServerError] = useState('')

  const methods = useForm()
  const {
    formState: { errors, isSubmitting },
    control,
  } = methods

  const onClose = () => {
    methods.reset()
    setOpenNote(false)
    setServerError('')
  }

  const onSubmit = async (data) => {
    const response = await saveClinicalNote({
      patientId: patient.id,
      note: data.note,
    })
    if (response && response.message === 'Success') {
      const { clinicalNote } = response
      notes.unshift(clinicalNote)
      setNotes([...notes])
      onClose()
    } else {
      setServerError('Error saving note')
    }
  }

  const toggleOpenNote = () => {
    if (openNote) {
      onClose()
    } else {
      setOpenNote(true)
    }
  }

  return (
    <>
      <Typography variant="h5">Clincal Notes</Typography>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {'Patient Information'}
      </Typography>
      <div style={{ marginTop: '5px', marginLeft: '10px' }}>
        <Typography variant="subtitle2">{`Name: ${patient.name ?? ''}`}</Typography>
        {/* <Typography variant="subtitle2">{`D.O.B: ${patient.birthDate ?? ''}`}</Typography> */}
        <Typography variant="subtitle2">{`MRN: ${patient.mrn ?? ''}`}</Typography>
        <Typography variant="subtitle2">{`Phone Number: ${patient.phoneNumber ?? ''}`}</Typography>
      </div>
      <Button variant="outlined" onClick={toggleOpenNote} sx={{ mt: 2 }}>
        {'+ Add Note'}
      </Button>
      {openNote && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <FormProvider {...methods}>
            <form
              id={formId}
              noValidate
              onSubmit={methods.handleSubmit(onSubmit)}
              style={{ width: '100%' }}
            >
              <Controller
                control={control}
                name="note"
                rules={{ required: 'Required field' }}
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    name={name}
                    fullWidth
                    label="Note"
                    sx={{ mt: 2 }}
                    multiline
                    rows={3}
                    value={value}
                    onChange={onChange}
                    disabled={isSubmitting}
                    error={errors[name]?.message}
                  />
                )}
              />
              {serverError && (
                <Alert sx={{ mt: 2 }} severity="error">
                  {serverError}
                </Alert>
              )}
            </form>
          </FormProvider>
          <Button
            variant="outlined"
            form={formId}
            type="submit"
            sx={{ marginTop: 2 }}
            disabled={isSubmitting}
          >
            SAVE
          </Button>
        </div>
      )}
      <Typography variant="h6" sx={{ mt: 2 }}>
        {'History:'}
      </Typography>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress sx={{ width: '100%' }} />
        </Box>
      ) : (
        notes.map((note, i) => {
          return (
            <ClinicalNote
              createdAt={note.createdAt}
              providerName={note.providerName}
              note={note.note}
              key={i}
            />
          )
        })
      )}
    </>
  )
}
