const FORWARD_STATUS_SUCCESSFUL = 'successful'
const FORWARD_STATUS_FAILED = 'failed'
const FORWARD_STATUS_NOT_SENT = 'notSent'

const forwardResultLabelMap = new Map([
  [FORWARD_STATUS_SUCCESSFUL, 'Successful'],
  [FORWARD_STATUS_FAILED, 'Failed'],
  [FORWARD_STATUS_NOT_SENT, 'Not Sent'],
])

export const formatForwardResult = ({ status, endpoint }) => {
  if (endpoint) {
    return `${forwardResultLabelMap.get(status)} (${endpoint.name})`
  }

  return forwardResultLabelMap.get(status)
}
