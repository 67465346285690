import { Typography, ToggleButton, Box, Select, MenuItem } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
import { StyledToggleButtonGroup } from './ToggleButtonGroup'
import { useState } from 'react'

const Month = 31
const ThreeMonths = 93
const SixMonths = 186
const Year = 365
const TwoYears = 730
const All = 10000

export const durationMap = {
  [Month]: 'Last 1 Month',
  [ThreeMonths]: 'Last 3 Months',
  [SixMonths]: 'Last 6 Months',
  [Year]: 'Last 1 Year',
  [TwoYears]: 'Last 2 Years',
  [All]: 'All Time',
}

const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.primary.main,
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  marginTop: theme.spacing(1),
}))

export const useDurationDays = () => {
  const [durationText, setDurationText] = useState('Last 1 month')
  const [days, setDays] = useState(Month)
  return {
    durationText,
    days,
    setDurationText,
    setDays,
  }
}

export const ResponsiveDurationButtons = ({
  days,
  setDurationText,
  setDays,
  isMobile,
}) => {
  const theme = useTheme()

  const handleChange = (_e, value) => {
    if (value) {
      const num = parseInt(value)
      setDays(num)
      setDurationText(durationMap[num])
    }
  }

  const handleSelect = (e) => {
    const num = parseInt(e.target.value)
    setDays(num)
    setDurationText(durationMap[num])
  }

  return (
    <Box
      sx={{
        marginTop: { xs: theme.spacing(2), md: theme.spacing(1) },
      }}
    >
      {!isMobile && (
        <Typography variant="h6" sx={{ paddingLeft: theme.spacing(1) }}>
          Duration
        </Typography>
      )}
      {isMobile ? (
        <StyledSelect
          value={days}
          onChange={handleSelect}
          fullWidth
          color="primary"
        >
          <MenuItem value={Month}>1 MONTH</MenuItem>
          <MenuItem value={ThreeMonths}>3 MONTHS</MenuItem>
          <MenuItem value={SixMonths}>6 MONTHS</MenuItem>
          <MenuItem value={Year}>1 YEAR</MenuItem>
          <MenuItem value={TwoYears}>2 YEARS</MenuItem>
          <MenuItem value={All}>ALL</MenuItem>
        </StyledSelect>
      ) : (
        <StyledToggleButtonGroup
          color="primary"
          value={days}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value={Month}>1 Month</ToggleButton>
          <ToggleButton value={ThreeMonths}>3 Months</ToggleButton>
          <ToggleButton value={SixMonths}>6 Months</ToggleButton>
          <ToggleButton value={Year}>1 Year</ToggleButton>
          <ToggleButton value={TwoYears}>2 Years</ToggleButton>
          <ToggleButton value={All}>All</ToggleButton>
        </StyledToggleButtonGroup>
      )}
    </Box>
  )
}
