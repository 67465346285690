import { useState, useEffect } from 'react'
import { defaultColumnVisibilityModel } from './columns'

export const useColumnVisibilityModel = () => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    defaultColumnVisibilityModel
  )
  const providerId = sessionStorage.getItem('providerID')
  const key = `${providerId}-columnVisibilityModel`

  useEffect(() => {
    const localColumnVisibilityModel = localStorage.getItem(key)
    if (localColumnVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(localColumnVisibilityModel))
    }
  }, [])

  const setState = (visibilityModel) => {
    localStorage.setItem(key, JSON.stringify(visibilityModel))
    setColumnVisibilityModel(visibilityModel)
  }

  const isBloodPressureVisible = () => {
    const { bptaken, bpThresholdLevel, recentBp, pulse } = columnVisibilityModel
    return bptaken || bpThresholdLevel || recentBp || pulse
  }

  const isWeightScaleVisible = () => {
    const { weighttaken, weight, recentWs } = columnVisibilityModel
    return weight || weighttaken || recentWs
  }

  const isSpo2Visible = () => {
    const { spo2Date, spo2, pulseRate, recentPulse } = columnVisibilityModel
    return spo2 || spo2Date || recentPulse || pulseRate
  }

  const isGlucoseVisible = () => {
    const { glucose, glucoseDate, recentGlucose } = columnVisibilityModel
    return glucose || glucoseDate || recentGlucose
  }

  return {
    columnVisibilityModel,
    setColumnVisibilityModel: setState,
    isBloodPressureVisible,
    isWeightScaleVisible,
    isSpo2Visible,
    isGlucoseVisible,
  }
}
