import { Button } from '@material-ui/core'
import { useState } from 'react'

export const useDurationDays = () => {
  const [durationText, setDurationText] = useState('Last 1 month')
  const [days, setDays] = useState(31)
  return {
    durationText,
    days,
    setDurationText,
    setDays,
  }
}

export const DurationButtons = ({ days, setDurationText, setDays }) => {
  return (
    <div style={{ float: 'right', marginTop: '5px' }}>
      <h6>
        <b>Duration</b>
      </h6>{' '}
      {days === 31 ? (
        <Button
          style={{ backgroundColor: '#F0F0F0' }}
          onClick={() => setDays(31)}
        >
          1 month
        </Button>
      ) : (
        <Button
          onClick={() => {
            setDays(31)
            setDurationText('Last 1 month')
          }}
        >
          1 month
        </Button>
      )}
      {days === 93 ? (
        <Button
          style={{ backgroundColor: '#F0F0F0' }}
          onClick={() => setDays(93)}
        >
          3 months
        </Button>
      ) : (
        <Button
          onClick={() => {
            setDays(93)
            setDurationText('Last 3 months')
          }}
        >
          3 months
        </Button>
      )}
      {days === 186 ? (
        <Button
          style={{ backgroundColor: '#F0F0F0' }}
          onClick={() => setDays(186)}
        >
          6 months
        </Button>
      ) : (
        <Button
          onClick={() => {
            setDays(186)
            setDurationText('Last 6 months')
          }}
        >
          6 months
        </Button>
      )}
      {days === 365 ? (
        <Button
          style={{ backgroundColor: '#F0F0F0' }}
          onClick={() => setDays(365)}
        >
          1 year
        </Button>
      ) : (
        <Button
          onClick={() => {
            setDays(365)
            setDurationText('Last 1 year')
          }}
        >
          1 year
        </Button>
      )}
      {days === 730 ? (
        <Button
          style={{ backgroundColor: '#F0F0F0' }}
          onClick={() => setDays(730)}
        >
          2 years
        </Button>
      ) : (
        <Button
          onClick={() => {
            setDays(730)
            setDurationText('Last 2 years')
          }}
        >
          2 years
        </Button>
      )}
      {days === 10000 ? (
        <Button
          style={{ backgroundColor: '#F0F0F0' }}
          onClick={() => setDays(10000)}
        >
          All
        </Button>
      ) : (
        <Button
          onClick={() => {
            setDays(10000)
            setDurationText('All time')
          }}
        >
          All
        </Button>
      )}
    </div>
  )
}
