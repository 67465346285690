import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment-timezone'
import { durationMap } from '../components/PatientData/ResponsiveDurationButtons'

export const generatePdf = ({
  firstName,
  lastName,
  imei,
  manufacturer,
  days,
  bpmData,
  avgSys,
  avgDia,
}) => {
  const today = moment().format('MMM Do YYYY')
  const dateLast = moment().subtract(days, 'days').format('MMM Do YYYY')
  // Current manufacturers: Transtek, Bodytrace
  const manufacturerLink =
    manufacturer === 'Transtek'
      ? 'https://www.validatebp.org/device/pro-cellular-blood-pressure-monitor/'
      : 'https://www.validatebp.org/device/bodytrace-cellular-blood-pressure-monitor/'

  const pdf = new jsPDF()

  // Set document properties
  pdf.setProperties({
    title: `Patient Rpm Report`,
  })

  // Title
  pdf.setFontSize(10)
  pdf.setFont('Helvetica', 'bold')
  pdf.text(
    `PATIENT RPM REPORT - ${durationMap[days]} (${dateLast} - ${today})`,
    13,
    12
  )

  // Line width in units (you can adjust this)
  pdf.setLineWidth(0.1)

  // Line color (RGB)
  pdf.setDrawColor(200, 200, 200)
  pdf.line(10, 18, 200, 18)

  // Patient Information
  pdf.setFont('Helvetica', 'bold')
  pdf.text('Patient Information', 13, 23)
  pdf.text('Name: ', 13, 28)
  pdf.setFont('Helvetica', 'normal')
  pdf.text(`${firstName} ${lastName}`, 25, 28)
  pdf.line(10, 34, 200, 34)

  // Generate Blood Pressure Device information
  const averageBp = avgSys && avgDia ? `${avgSys}/${avgDia}` : ''
  pdf.setFont('Helvetica', 'bold')
  pdf.text('Blood Pressure Device Information', 13, 39)
  pdf.setFont('Helvetica', 'bold')
  pdf.text(`Unique Device IMEI: `, 13, 44)
  pdf.text('Average Reading: ', 13, 52)
  pdf.text('AMA Validated Device:', 13, 48)
  pdf.setFont('Helvetica', 'normal')
  pdf.text(`${imei ?? ''}`, 52, 44)
  pdf.textWithLink(manufacturerLink, 52, 48, {
    url: manufacturerLink,
  })
  pdf.text(`${averageBp}`, 52, 52)

  // Generate AutoTable for item details
  const bloodPressureRows = bpmData?.map((item) => [
    moment(item.date).format('MM/DD/YY'),
    item.time.toString(),
    item.systolic?.toString(),
    item.diastolic?.toString(),
    item.pulse?.toLocaleString(),
  ])
  const bloodPressureHeaders = [
    'Date',
    'Time',
    'Systolic',
    'Diastolic',
    'Pulse',
  ]
  const columnWidths = [40, 40, 40, 40, 23] // Adjust column widths as needed
  // Define table styles
  const headerStyles = {
    fillColor: [240, 240, 240],
    textColor: [0],
    fontFamily: 'Helvetica',
    fontStyle: 'bold',
  }

  pdf.setFont('Helvetica')
  const itemDetailsYStart = 60
  autoTable(pdf, {
    head: [bloodPressureHeaders],
    body: bloodPressureRows,
    startY: itemDetailsYStart, // Adjust the Y position as needed
    headStyles: {
      fillColor: headerStyles.fillColor,
      textColor: headerStyles.textColor,
      fontStyle: headerStyles.fontStyle,
      fontSize: 10, // Adjust the font size as needed
      font: 'Helvetica', // Set the font family
      halign: 'left',
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] }, // Adjust column widths as needed
      1: { cellWidth: columnWidths[1] },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
      4: { cellWidth: columnWidths[4] },
    },
    alternateRowStyles: { fillColor: [255, 255, 255] },
    bodyStyles: {
      fontSize: 10, // Adjust the font size for the body
      font: 'Helvetica', // Set the font family for the body
      cellPadding: { top: 1, right: 5, bottom: 1, left: 2 }, // Adjust cell padding
      textColor: [0, 0, 0], // Set text color for the body
      rowPageBreak: 'avoid', // Avoid row page breaks
    },
    margin: { top: 10, left: 13 },
  })

  const totalPages = pdf.internal.getNumberOfPages()
  for (let i = 1; i <= totalPages; i++) {
    pdf.line(10, 283, 200, 283)
    pdf.setPage(i)
    pdf.setFont('Helvetica')
    pdf.text(
      `Page ${i} of ${totalPages}`,
      185,
      pdf.internal.pageSize.getHeight() - 5
    )
  }

  // Save the PDF
  const firstInitial = firstName?.[0].toLowerCase()
  pdf.save(
    `${firstInitial + lastName?.toLowerCase()} blood pressure results ${dateLast}-${today}.pdf`
  )
}
