import { useId } from 'react'
import {
  FormHelperText,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { useStyles } from '../components/common/style'

export const ControlledSelect = ({
  inputName,
  label,
  control,
  error,
  children,
  rules = {
    required: 'Required field',
  },
}) => {
  const classes = useStyles()
  const labelId = useId()
  return (
    <Controller
      name={inputName}
      control={control}
      rules={rules}
      render={({ field: { name, value, onChange } }) => (
        <FormControl className={classes.formControl} error={!!error}>
          <InputLabel id={labelId}>{label}</InputLabel>
          <Select
            labelId={labelId}
            name={name}
            onChange={onChange}
            value={value}
          >
            {children}
          </Select>
          {error && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}
