import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import cardiowell1 from '../images/cardiowell1.png'
import { useFormStyles } from './common/style'
import { Dialog } from './common/Dialog'
import { Copyright } from './common/Copyright'
import { Typography } from '@material-ui/core'
import { sendPasswordResetLink } from './sendPasswordResetLink'

export default function LoginPage(props) {
  const classes = useFormStyles()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [forgotPassword, setForgotPassword] = useState(false)
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('')
  const [serverMessage, setServerMessage] = useState('')
  const [modalServerMessage, setModalServerMessage] = useState('')
  const [showLinkSentConfirmation, setShowLinkSentConfirmation] =
    useState(false)

  const submitEmail = async () => {
    if (forgotPasswordEmail.length > 0) {
      const data = await sendPasswordResetLink(forgotPasswordEmail)
      if (data?.message === 'Success') {
        setForgotPassword(false)
        setServerMessage('')
        setModalServerMessage('')
        setShowLinkSentConfirmation(true)
      } else if (data?.message === 'User not found') {
        setModalServerMessage('We do not have that email on record.')
        setShowLinkSentConfirmation(false)
      } else if (data?.errors) {
        setModalServerMessage('Please enter a valid email')
        setShowLinkSentConfirmation(false)
      } else {
        setModalServerMessage('Server error')
        setShowLinkSentConfirmation(false)
      }
    } else {
      setModalServerMessage('Please enter an email.')
    }
  }

  function login() {
    setShowLinkSentConfirmation(false)
    if (password.length > 0 && username.length > 0) {
      var inputValue = {
        username: username.toLowerCase(),
        password: password,
      }
      if (inputValue.username === 'admin') {
        fetch('/routes/users/loginAdmin', {
          method: 'POST',
          body: JSON.stringify(inputValue),
          headers: { 'Content-Type': 'application/json' },
        })
          .then((response) => {
            if (response.status !== 201) {
              return setServerMessage(
                'Incorrect username/password. Please try again.'
              )
            } else {
              return response.json()
            }
          })
          .then((data) => {
            if (data.message === 'Success') {
              sessionStorage.setItem('user', 'admin')
              props.history.push('/adminDashboard', { adminID: data.adminID })
            } else {
              setServerMessage('Incorrect username/password. Please try again.')
            }
          })
          .catch((error) => {
            console.error(error)
            setServerMessage('Incorrect username/password. Please try again.')
          })
      } else {
        fetch('/routes/users/login', {
          method: 'POST',
          body: JSON.stringify(inputValue),
          headers: { 'Content-Type': 'application/json' },
        })
          .then((response) => {
            if (response.status !== 201) {
              return setServerMessage(
                'Incorrect username/password. Please try again.'
              )
            } else {
              return response.json()
            }
          })
          .then((data) => {
            if (data.message === 'Success') {
              if (data.accountType === 'provider') {
                sessionStorage.setItem('user', data.user)
                sessionStorage.setItem('clinic', data.clinic)
                sessionStorage.setItem('providerID', data.providerID)
                sessionStorage.setItem('clinicId', data.clinicId)
                props.history.push('/dashboardPage', {
                  clinic: data.clinic,
                  clinicId: data.clinicId,
                })
              } else {
                sessionStorage.setItem('user', data.user)
                sessionStorage.setItem('patientID', data.patientID)
                sessionStorage.setItem('clinic', data.clinic)
                props.history.push('/patientDashboard')
              }
            } else {
              setServerMessage('Incorrect username/password. Please try again.')
            }
          })
          .catch((error) => {
            console.log(error)
            setServerMessage('Incorrect username/password. Please try again.')
          })
      }
    } else {
      setServerMessage('Please fill out username and password fields!')
    }
  }

  const onModalHide = () => {
    setForgotPassword(false)
    setModalServerMessage('')
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img style={{ width: '50%' }} src={cardiowell1}></img>
        <form
          className={classes.form}
          noValidate
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Username"
            name="name"
            autoFocus
            onChange={(event) => setUsername(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submit}
            onClick={login}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                href="#"
                variant="body2"
                onClick={() => setForgotPassword(true)}
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>
          <Typography variant="body2" style={{ whiteSpace: 'break-spaces' }}>
            {serverMessage}
          </Typography>
          {showLinkSentConfirmation && (
            <Typography variant="body2" style={{ whiteSpace: 'break-spaces' }}>
              {
                'The email has been sent!\nIf you don’t see it in your inbox, please check your spam or junk folder. To ensure you receive our emails in the future, add '
              }
              <a href="mailto: reminders@cardiowell.com">
                reminders@cardiowell.com
              </a>
              {' to your email contacts or safe sender list.'}
            </Typography>
          )}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <Dialog
        open={forgotPassword}
        title="Forgot Password"
        handleClose={onModalHide}
        actions={
          <>
            <Button variant="secondary" onClick={onModalHide}>
              Close
            </Button>
            <Button variant="primary" onClick={submitEmail}>
              Submit
            </Button>
          </>
        }
      >
        <form className={classes.form} noValidate>
          <p>
            Please enter your email address and we'll send you a link to reset
            your password
          </p>
          <TextField
            variant="outlined"
            margin="normal"
            required
            onChange={(event) => setForgotPasswordEmail(event.target.value)}
            id="email"
            label="Email Address"
            name="email"
            autoFocus
          />
          <p>{modalServerMessage}</p>
        </form>
      </Dialog>
    </Container>
  )
}
