export const DELETE_ACTION = 'delete'
export const RENEW_ACTION = 'renew'
export const CHANGE_ACTION = 'change'

export const DELETED_STATUS = 'deleted'
export const CHANGED_STATUS = 'changed'
export const UNMODIFIED_STATUS = 'unmodified'
export const ERROR_STATUS = 'error'
export const SAVED_STATUS = 'saved'
export const SKIPPED_STATUS = 'skipped'
