import timeme from 'timeme.js'

export const sendTimerData = async (patientId) => {
  const time = timeme.getTimeOnPageInSeconds(patientId)
  const providerId = sessionStorage.getItem('providerID')
  if (!time || !providerId) {
    return { error: 'No timer data' }
  }
  const data = {
    providerId,
    patientId,
    time,
    ts: new Date().getTime(),
  }

  timeme.resetRecordedPageTime(`${patientId}`)
  return await fetch('/routes/profiletime/patient', {
    withCredentials: true,
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw new Error(response.status)
    })
    .catch((error) => {
      return { error }
    })
}
