import { PatientDataGrid } from '../PatientDataGrid'
import { commonColumnProps, dateTimeColumns } from '../PatientDataGridColumns'

const columns = [
  ...dateTimeColumns,
  {
    ...commonColumnProps,
    field: 'spo2',
    headerName: 'SPO2',
    type: 'number',
  },
  {
    ...commonColumnProps,
    field: 'pr',
    headerName: 'BPM',
    type: 'number',
  },
]
export const PulseOximeterGrid = ({ gridData = [] }) => {
  return <PatientDataGrid gridData={gridData} columns={columns} />
}
