import { useMemo } from 'react'
import moment from 'moment'
import { MultiLineChart } from '../../common/MultiLineChart.jsx'
import { ThresholdDot } from '../../common/ThresholdDot.jsx'
import { green } from '../../../common/colors.js'

export const GlucoseChart = ({ threshold, chartData }) => {
  const data = useMemo(
    () =>
      chartData
        .map((data) => {
          let taken = ''
          taken = moment.unix(data.ts).format('MM/DD/YY')
          return {
            date: taken,
            Glucose: data.data,
          }
        })
        .reverse(),
    [chartData]
  )
  return (
    <MultiLineChart
      title={'Blood Glucose Trend'}
      label={'mg/dl'}
      lines={[
        {
          key: 'Glucose',
          color: green,
          dot: () => <ThresholdDot threshold={threshold} />,
        },
      ]}
      data={data}
      domain={[40, 300]}
    />
  )
}
