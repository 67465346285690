import React from 'react'
import openSocket from 'socket.io-client'
import clsx from 'clsx'
import CssBaseline from '@material-ui/core/CssBaseline'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import cardiowell2 from '../images/cardiowell2.png'
import Button from '@material-ui/core/Button'
import SettingsIcon from '@material-ui/icons/Settings'
import TextField from '@material-ui/core/TextField'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@mui/material/IconButton'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import 'moment/locale/fr'
import WEBSOCKET_URL from '../common/common'
import { PatientDataDashboard } from './PatientData/Dashboard'
import { AppBar, Drawer, DrawerHeader } from './common/MiniDrawer'
import { useStyles } from './common/style'
import { EditPatient } from './common/EditPatient'
import { DisplaySettings } from './common/DisplaySetings'
import { password_check } from '../common/regex'

const $ = require('jquery')
$.DataTable = require('datatables.net')

const socket = openSocket(WEBSOCKET_URL)

export default function PatientDashboard(props) {
  if (sessionStorage.getItem('user') === null) {
    props.history.push('/')
  }

  const [settings, setSettings] = React.useState(false)
  const [patientDashboard, setPatientDashboard] = React.useState(true)
  const [password1, setPassword1] = React.useState('')
  const [password2, setPassword2] = React.useState('')
  const [patientData, setPatientData] = React.useState(null)

  const [passwordFormMessage, setPasswordFormMessage] = React.useState('')
  const [editModalMessage, setEditModalMessage] = React.useState('')
  const [showEditModal, setShowEditModal] = React.useState(false)
  const [imageData, setImageData] = React.useState('')
  const [el, setEl] = React.useState('')
  const el4 = React.useRef()

  function resetPassword() {
    if (password_check(password1)) {
      if (password1 === password2) {
        var inputValue = {
          username: sessionStorage.getItem('user'),
          password: password1,
          id: sessionStorage.patientID,
        }
        fetch('/routes/users/patientResetPassword', {
          method: 'POST',
          body: JSON.stringify(inputValue),
          headers: { 'Content-Type': 'application/json' },
        })
          .then((response) => {
            if (response.status !== 201) {
              return setPasswordFormMessage(
                'There has been an error. Please try again.'
              )
            } else {
              return response.json()
            }
          })
          .then((data) => {
            try {
              if (data.message === 'Success') {
                setPasswordFormMessage('Your password has been reset!')
                setPassword1('')
                setPassword2('')
              }
            } catch (e) {
              return setPasswordFormMessage(
                'There has been an error. Please try again.'
              )
            }
          })
          .catch((error) => {
            console.error(error)
            setPasswordFormMessage('There has been an error. Please try again.')
          })
      } else {
        setPasswordFormMessage('Passwords do not match!')
      }
    } else {
      setPasswordFormMessage(
        '"Password must contain 8 characters, an uppercase, a lowercase, a number, and a special character"'
      )
    }
  }

  function savePatientChanges(patient) {
    setEditModalMessage('Saving your changes. Please wait...')
    fetch('/routes/users/patientSavePatientChanges', {
      method: 'POST',
      body: JSON.stringify(patient),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (response.status !== 201) {
          return setEditModalMessage(
            'There has been an error. Please try again.'
          )
        } else {
          return response.json()
        }
      })
      .then((data) => {
        if (data.message === 'Success') {
          setEditModalMessage('')
          setShowEditModal(false)
          setPatientData(data.data)
        } else {
          setEditModalMessage('There has been an error. Please try again.')
        }
      })
      .catch((error) => {
        console.error(error)
        setEditModalMessage('There has been an error. Please try again.')
      })
  }

  function exportPDF() {
    html2canvas(el4.current).then((canvas) => {
      document.body.appendChild(canvas) // if you want see your screenshot in body.
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF()
      const width = pdf.internal.pageSize.getWidth()
      const height = pdf.internal.pageSize.getHeight()
      pdf.addImage(imgData, 'PNG', 10, 10, 180, 200)
      pdf.save('report.pdf')
      document.querySelector('canvas').remove()
    })
  }

  React.useEffect(() => {
    // retieve patient data
    fetch('/routes/users/getPatientData', {
      method: 'POST',
      body: JSON.stringify({ id: sessionStorage.patientID }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'Success') {
          setPatientData(data.data)
        }
      })
      .catch((error) => {
        console.error(error)
      })

    socket.on('patientDashboardData', function (data) {
      if (data === 'Error: Not Authenticated') {
        sessionStorage.clear()
        props.history.push('/')
      } else {
        setPatientData(data)
      }
    })

    socket.on('withingsDataUpdate', (data) => {
      if (data.patientId && data.patientId === sessionStorage.patientID) {
        socket.emit('patientDashboardData', { id: sessionStorage.patientID })
      }
    })

    fetch(`/routes/users/clinic-images/${sessionStorage.clinic}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.image) {
          const imageBase64 = data.image
          const imageUrl = `data:image/jpeg;base64,${imageBase64}`
          setImageData(imageUrl)
        }
      })
      .catch((error) => {
        console.error('Error fetching clinic image:', error)
      })
  }, [])

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)
  $(el).DataTable({
    retrieve: true,
    lengthMenu: [
      [10, 100, -1],
      [10, 100, 'All'],
    ],
    pageLength: 10,
  })
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar open={open} handleDrawerOpen={handleDrawerOpen}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          Patient Care Dashboard
        </Typography>
        {imageData ? (
          <img
            style={{ width: '125px', height: '30px' }}
            src={imageData}
            alt="Clinic"
          />
        ) : (
          <img
            style={{ width: '125px', height: '25px' }}
            src={cardiowell2}
            alt="Default"
          />
        )}
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem
            button
            onClick={() => {
              setSettings(false)
              setPatientDashboard(true)
            }}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setPatientDashboard(false)
              setSettings(true)
            }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              var inputValue = {}
              fetch('/routes/users/logout', {
                method: 'POST',
                body: JSON.stringify(inputValue),
                headers: { 'Content-Type': 'application/json' },
              })
                .then((response) => {
                  if (response.status === 201) {
                    return response.json()
                  }
                })
                .then((data) => {
                  sessionStorage.clear()
                  props.history.push('/')
                })
                .catch((error) => {
                  console.error(error)
                })
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>

      {patientDashboard && patientData && (
        <PatientDataDashboard
          patient={patientData}
          measurements={patientData}
          setModalShow2={setShowEditModal}
          exportPDF={exportPDF}
          removePatientFromList={() => {}}
          updatePatientInList={(data) => {
            setPatientData(data)
          }}
          updateTime={() => {}}
        />
      )}
      {settings && (
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <Typography className={classes.heading}>
                  <h4>Change Password</h4>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Grid container spacing={3}>
                    <form
                      className={classes.form}
                      noValidate
                      onSubmit={(e) => {
                        e.preventDefault()
                      }}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Password"
                        type="Password"
                        name="email"
                        autoFocus
                        onChange={(event) => setPassword1(event.target.value)}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Confirm Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(event) => setPassword2(event.target.value)}
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.submit}
                        onClick={resetPassword}
                      >
                        Reset Password
                      </Button>
                      <p>{passwordFormMessage}</p>
                    </form>
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Container>
          <div style={{ marginTop: '30px' }}></div>
          <DisplaySettings onUpdate={() => {}} />
        </main>
      )}
      <EditPatient
        canDelete={false}
        canEditClinic={false}
        modalShow={showEditModal}
        serverMessage={editModalMessage}
        selectedPatientId={sessionStorage.getItem('patientID')}
        selectedPatient={patientData}
        setModalShow={setShowEditModal}
        savePatientChanges={savePatientChanges}
        deletePatient={() => {}}
        timezoneRequired={true}
      />
    </div>
  )
}
