import { useState, useMemo, useEffect, Fragment } from 'react'
import {
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core'
import { Box, Typography, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import SmsIcon from '@mui/icons-material/Sms'
import EmailIcon from '@mui/icons-material/Email'
import moment from 'moment-timezone'
import timeme from 'timeme.js'
import { useStyles } from '../common/style'
import clsx from 'clsx'
import { useWeightUnit } from '../common/DisplaySetings'
import { parseBpMeasures } from './BloodPressure/bloodPressure'
import {
  BloodPressureChart,
  HeartRateChart,
} from './BloodPressure/BloodPressureCharts'
import { BloodPressureGrid } from './BloodPressure/BloodPressureGrid'
import { parsePulseMeasures } from './Pulse/pulseOximeter'
import {
  BloodOxygenChart,
  HeartRateOximeterChart,
} from './Pulse/PulseOximeterCharts'
import { PulseOximeterGrid } from './Pulse/PulseOximeterGrid'
import { parseGlucoseMeasures } from './Glucose/glucose'
import { GlucoseChart } from './Glucose/GlucoseCharts'
import { GlucoseGrid } from './Glucose/GlucoseGrid'
import { parseWeightScaleMeasures } from './WeightScale/weightScale'
import { WeightScaleChart } from './WeightScale/WeightScaleCharts'
import { WeightScaleGrid } from './WeightScale/WeightScaleGrid'
import { DurationButtons } from './DurationButtons'
import { CARDIOWELL } from '../../common/manufacters'
import { EditPatientModal } from '../../patient/EditPatientModal'
import { providerDeletePatient } from '../../patient/providerDeletePatient'
import { providerSavePatientChanges } from '../../patient/providerSavePatientChanges'
import { sendTimerData } from './sendTimerData'
import { ClinicalNoteView } from './ClinicalNote/ClinicalNoteView'
import { useColumnVisibilityModel } from '../../provider/PatientListGrid/useColumnVisibilityModel'
import { secondsToHMS } from '../../common/conversions'
import { useNotificationModal } from '../../provider/useNotificationModal'
import { NotificationModal } from '../../provider/NotificationModal'

const CHART = 'Chart'
const READINGS = 'Readings'
const NOTE = 'clinicalNotes'

export const PatientDataDashboard = ({
  patient,
  clinic,
  measurements,
  exportPDF,
  removePatientFromList,
  updatePatientInList,
  updateTime,
}) => {
  const theme = useTheme()
  const { weightUnit } = useWeightUnit()
  const classes = useStyles()
  const [days, setDays] = useState(31)
  const [durationText, setDurationText] = useState('Last 1 month')
  const [tableView, setTableView] = useState('Chart')
  const [showEditPatientModal, setShowEditPatientModal] = useState(false)
  const [liveTime, setLiveTime] = useState(0)
  const {
    isBloodPressureVisible,
    isWeightScaleVisible,
    isSpo2Visible,
    isGlucoseVisible,
  } = useColumnVisibilityModel()
  const {
    showNotificationModal,
    notificationModalMessage,
    sendSMS,
    sendEmail,
    onClose,
  } = useNotificationModal()

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  const bpDevice = patient.selectedBpDevice || CARDIOWELL
  const weightDevice = patient.selectedWeightDevice || CARDIOWELL
  const threshold = patient.threshold || {}

  const timeZone =
    measurements?.timeZone && measurements.timeZone !== 'local'
      ? measurements.timeZone
      : moment.tz.guess()
  const d = useMemo(() => moment().subtract(days, 'days').toDate(), [days])

  const deletePatient = async () => {
    const response = await providerDeletePatient(clinic, patient.id)
    if (response.message === 'Success') {
      timeme.stopTimer(`${patient.id}`)
      timeme.resetRecordedPageTime(`${patient.id}`)
      removePatientFromList(patient.id)
    }
    return response
  }

  const savePatientChanges = async (patient) => {
    const response = await providerSavePatientChanges(patient)
    if (response.message === 'Success') {
      updatePatientInList(response.data)
    }
    return response
  }

  const {
    arrayBP,
    bpmTableArray,
    highSys,
    highDia,
    lowSys,
    lowDia,
    avgSys,
    avgDia,
  } = useMemo(
    () =>
      parseBpMeasures({
        bpm: measurements?.bpm,
        btMessagesBpm: measurements?.btMessagesBpm,
        ttBpm: measurements?.ttBpm,
        adBpm: measurements?.adBpm,
        withingsBpm: measurements?.withingsBpm,
        bpDevice,
        timeframe: d,
        timeZone,
      }),
    [measurements, bpDevice, timeZone, d]
  )

  const { arrayWS, wsTableArray, lowWeight, highWeight } = useMemo(
    () =>
      parseWeightScaleMeasures({
        ws: measurements?.ws,
        btMessagesWs: measurements?.btMessagesWs,
        ttWs: measurements?.ttWs,
        weightDevice,
        weightUnit,
        timeframe: d,
        timeZone,
      }),
    [measurements, weightDevice, weightUnit, timeZone, d]
  )

  const { pulseChartArray, pulseTableArray, averageSpo2 } = useMemo(
    () =>
      parsePulseMeasures({
        pulse: measurements?.pulse,
        timeframe: d,
        timeZone,
      }),
    [measurements, timeZone, d]
  )

  const { glucoseChartArray, glucoseTableArray, averageGlucose } = useMemo(
    () =>
      parseGlucoseMeasures({ glucose: measurements?.glucose, timeframe: d }),
    [measurements, d]
  )

  const InfoGrid = ({ info, title }) => (
    <Grid item xs={6} md={3} lg={3}>
      <Paper className={fixedHeightPaper}>
        <Container>
          <h4 style={{ textAlign: 'center', marginTop: '40px' }}>{info}</h4>
          <h5 style={{ textAlign: 'center' }}>{title}</h5>
          <p style={{ textAlign: 'center', marginTop: '60px' }}>
            {durationText}
          </p>
        </Container>
      </Paper>
    </Grid>
  )

  useEffect(() => {
    timeme.startTimer(`${patient.id}`)
    const interval = setInterval(
      () => setLiveTime(timeme.getTimeOnPageInSeconds(patient.id) || 0),
      1000
    )

    return async () => {
      timeme.stopTimer(`${patient.id}`)
      clearInterval(interval)
      const timerData = await sendTimerData(patient.id)
      updateTime?.(timerData)
    }
  }, [patient.id])

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <div>
        <Container maxWidth="lg" className={classes.container}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            margin={theme.spacing(1)}
          >
            <Typography variant="h3">{patient?.name}</Typography>
            <Select
              style={{ marginBottom: '10px' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
            >
              <MenuItem style={{ backgroundColor: 'white' }}>
                BP IMEI: {patient?.bpIMEI}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                Transtek BP IMEI: {patient?.ttBpIMEI}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                Weight IMEI: {patient?.weightIMEI}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                Pulse Oximeter IMEI: {patient?.pulseIMEI}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                Glucose IMEI: {patient?.glucoseIMEI}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                MRN: {patient?.mrn}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                Email: {patient?.email}
              </MenuItem>
              <MenuItem style={{ backgroundColor: 'white' }}>
                Cell #: {patient?.cellNumber}
              </MenuItem>
            </Select>
            <Button onClick={() => setShowEditPatientModal(true)}>Edit</Button>
            <Button onClick={() => exportPDF()}>Print Report</Button>
            <IconButton
              onClick={() => sendSMS(patient.cellNumber, patient.name)}
              sx={{ color: 'black' }}
            >
              <SmsIcon />
            </IconButton>
            <IconButton
              onClick={() => sendEmail(patient.email, patient.name)}
              sx={{ color: 'black' }}
            >
              <EmailIcon />
            </IconButton>
            {patient.rt != null && patient.rt != undefined && (
              <Typography
                sx={{ marginLeft: theme.spacing(1) }}
                variant="subtitle1"
              >{`RT (30 Days): ${secondsToHMS(patient.rt + liveTime)}`}</Typography>
            )}
          </Box>
          <Grid container spacing={3}>
            {isBloodPressureVisible() && (
              <Fragment>
                <InfoGrid info={`${avgSys}/${avgDia}`} title={'Avg BP'} />
                <InfoGrid info={`${highSys}/${highDia}`} title={'High BP'} />
                <InfoGrid info={`${lowSys}/${lowDia}`} title={'Low BP'} />
                <InfoGrid info={`${arrayBP.length}`} title={'BP Readings'} />
              </Fragment>
            )}
            {isSpo2Visible() && (
              <InfoGrid info={averageSpo2} title={'Avg SP02'} />
            )}
            {isGlucoseVisible() && (
              <InfoGrid info={averageGlucose} title={'Avg Glucose'} />
            )}
          </Grid>
          <DurationButtons
            days={days}
            setDays={setDays}
            setDurationText={setDurationText}
          />
          <div style={{ float: 'left', marginTop: '5px' }}>
            <Button
              onClick={() => setTableView(CHART)}
              style={{
                ...(tableView === CHART && { backgroundColor: '#F0F0F0' }),
              }}
            >
              Chart
            </Button>
            <Button
              onClick={() => setTableView(READINGS)}
              style={{
                ...(tableView === READINGS && { backgroundColor: '#F0F0F0' }),
              }}
            >
              Readings
            </Button>
            <Button
              onClick={() => setTableView(NOTE)}
              style={{
                ...(tableView === NOTE && { backgroundColor: '#F0F0F0' }),
              }}
            >
              Clinical Note
            </Button>
          </div>
        </Container>
        {tableView === CHART && (
          <div style={{ marginTop: '25px' }}>
            {isBloodPressureVisible() && (
              <Container maxWidth="lg" className={classes.container}>
                <Grid item xs={12} lg={12}>
                  <Paper className={fixedHeightPaper}>
                    <BloodPressureChart
                      timeZone={timeZone}
                      chartData={arrayBP}
                      threshold={threshold?.bloodPressure}
                    />
                  </Paper>
                </Grid>
              </Container>
            )}
            {isBloodPressureVisible() && (
              <Container maxWidth="lg" className={classes.container}>
                <Grid item xs={12} lg={12}>
                  <Paper className={fixedHeightPaper}>
                    <HeartRateChart
                      timeZone={timeZone}
                      chartData={arrayBP}
                      threshold={threshold?.bloodPressure?.pulse}
                    />
                  </Paper>
                </Grid>
              </Container>
            )}
            {isWeightScaleVisible() && (
              <Container maxWidth="lg" className={classes.container}>
                <Grid item xs={12} lg={12}>
                  <Paper className={fixedHeightPaper}>
                    <WeightScaleChart
                      chartData={arrayWS}
                      weightUnit={weightUnit}
                      lowWeight={lowWeight}
                      highWeight={highWeight}
                      timeZone={timeZone}
                      threshold={threshold?.weight?.weight}
                    />
                  </Paper>
                </Grid>
              </Container>
            )}
            {isSpo2Visible() && (
              <Container maxWidth="lg" className={classes.container}>
                <Grid item xs={12} lg={12}>
                  <Paper className={fixedHeightPaper}>
                    <BloodOxygenChart
                      chartData={pulseChartArray}
                      threshold={threshold?.pulseOximeter?.spo2}
                    />
                  </Paper>
                </Grid>
              </Container>
            )}
            {isSpo2Visible() && (
              <Container maxWidth="lg" className={classes.container}>
                <Grid item xs={12} lg={12}>
                  <Paper className={fixedHeightPaper}>
                    <HeartRateOximeterChart
                      chartData={pulseChartArray}
                      threshold={threshold?.pulseOximeter?.pulse}
                    />
                  </Paper>
                </Grid>
              </Container>
            )}
            {isGlucoseVisible() && (
              <Container maxWidth="lg" className={classes.container}>
                <Grid item xs={12} lg={12}>
                  <Paper className={fixedHeightPaper}>
                    <GlucoseChart
                      chartData={glucoseChartArray}
                      threshold={threshold?.bloodGlucose?.glucose}
                    />
                  </Paper>
                </Grid>
              </Container>
            )}
          </div>
        )}
        {tableView === READINGS && (
          <div style={{ marginTop: '25px' }}>
            {isBloodPressureVisible() && (
              <Container maxWidth="lg" className={classes.container}>
                <Grid item xs={12} lg={12}>
                  <h5>BP</h5>
                  <Paper>
                    <BloodPressureGrid gridData={bpmTableArray} />
                  </Paper>
                </Grid>
              </Container>
            )}
            {isWeightScaleVisible() && (
              <Container maxWidth="lg" className={classes.container}>
                <Grid item xs={12} lg={12}>
                  <h5>Weight</h5>
                  <Paper>
                    <WeightScaleGrid
                      gridData={wsTableArray}
                      weightUnit={weightUnit}
                    />
                  </Paper>
                </Grid>
              </Container>
            )}
            {isSpo2Visible() && (
              <Container maxWidth="lg" className={classes.container}>
                <Grid item xs={12} lg={12}>
                  <h5>Pulse</h5>
                  <Paper>
                    <PulseOximeterGrid gridData={pulseTableArray} />
                  </Paper>
                </Grid>
              </Container>
            )}
            {isGlucoseVisible() && (
              <Container maxWidth="lg" className={classes.container}>
                <Grid item xs={12} lg={12}>
                  <h5>Glucose</h5>
                  <Paper>
                    <GlucoseGrid gridData={glucoseTableArray} />
                  </Paper>
                </Grid>
              </Container>
            )}
          </div>
        )}
        {tableView === NOTE && (
          <div style={{ marginTop: '25px' }}>
            <Container maxWidth="lg" className={classes.container}>
              <ClinicalNoteView patient={patient} timeframe={d} />
            </Container>
          </div>
        )}
      </div>
      <EditPatientModal
        canDelete={true}
        open={showEditPatientModal}
        patientId={patient.id}
        patient={patient}
        canEditClinic={false}
        timezoneRequired={true}
        handleClose={() => setShowEditPatientModal(false)}
        savePatientChanges={savePatientChanges}
        deletePatient={deletePatient}
      />
      <NotificationModal
        message={notificationModalMessage}
        open={showNotificationModal}
        onClose={() => onClose()}
      />
    </main>
  )
}
