import { Dot } from 'recharts'
import { red, orange } from '../../common/colors'

export const ThresholdDot = ({
  cx,
  cy,
  stroke,
  value,
  r,
  strokeWidth,
  fill,
  threshold,
}) => {
  if (
    (threshold?.upperHigh?.enabled && value >= threshold?.upperHigh?.value) ||
    (threshold?.lowerHigh?.enabled && value <= threshold?.lowerHigh?.value)
  ) {
    return (
      <Dot
        cx={cx}
        cy={cy}
        r={r}
        stroke={red}
        fill={red}
        strokeWidth={strokeWidth}
      />
    )
  }
  if (
    (threshold?.upperMedium?.enabled &&
      value > threshold?.upperMedium?.value) ||
    (threshold?.lowerMedium?.enabled && value <= threshold?.lowerMedium?.value)
  ) {
    return (
      <Dot
        cx={cx}
        cy={cy}
        r={r}
        stroke={orange}
        fill={orange}
        strokeWidth={strokeWidth}
      />
    )
  }

  return (
    <Dot
      cx={cx}
      cy={cy}
      r={r}
      stroke={stroke}
      fill={stroke}
      strokeWidth={strokeWidth}
    />
  )
}
