import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

export const ChartPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'auto',
  flexWrap: 'nowrap',
}))
