import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core'
import { useStyles } from './style'

export const ClinicSelect = ({ clinic, setClinic, clinics }) => {
  const classes = useStyles()
  return (
    <FormControl
      className={classes.formControl}
      style={{ marginLeft: '15px', marginRight: '0px', width: '40%' }}
    >
      <br></br>
      <InputLabel id="clinic-input-label">Clinic</InputLabel>
      <Select
        labelId="clinic-select-label"
        id="clinic-select"
        onChange={(event) => setClinic(event.target.value)}
        value={clinic}
      >
        {clinics.map((clinic) => (
          <MenuItem value={clinic.name} key={clinic.name}>
            {clinic.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
