import { useEffect, useState } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import { ListItemButton } from '@mui/material'
import { getPrograms } from '../thresholds/program/getPrograms'
import { EditProgramModal } from '../thresholds/program/ProgramThresholdsModal'

export const ProgramsList = ({ clinicId }) => {
  const [programs, setPrograms] = useState([])
  const [selectedProgram, setSelectedProgram] = useState('')

  useEffect(() => {
    getPrograms(clinicId).then((data) => {
      if (data) {
        setPrograms(data)
      }
    })
  }, [clinicId])

  return (
    <>
      <Paper>
        <List component="nav" aria-label="progams list">
          {programs.map((program) => (
            <ListItem key={program._id}>
              <ListItemAvatar>
                <Avatar>
                  <HealthAndSafetyIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemButton onClick={() => setSelectedProgram(program._id)}>
                <ListItemText primary={program.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Paper>
      <EditProgramModal
        open={!!selectedProgram}
        programId={selectedProgram}
        onClose={() => setSelectedProgram('')}
      />
    </>
  )
}
