import { useState, useEffect } from 'react'
import { getClinicalNotes } from './getClinicalNotes'

export const useClinicalNoteData = ({ patientId, from }) => {
  const [notes, setNotes] = useState([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const response = await getClinicalNotes({
        patientId,
        from,
      })
      if (response && response.message === 'Success') {
        setNotes([...response.clinicalNotes])
      }
      setLoading(false)
    }
    fetchData()
  }, [patientId, from])

  return {
    isLoading,
    notes,
    setNotes,
  }
}
