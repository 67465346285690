import { PatientDataGrid } from '../PatientDataGrid'
import { commonColumnProps, dateTimeColumns } from '../PatientDataGridColumns'

const columns = [
  ...dateTimeColumns,
  {
    ...commonColumnProps,
    field: 'data',
    headerName: 'Blood Glucose (mg/dl)',
    type: 'number',
    width: 200,
  },
]

export const GlucoseGrid = ({ gridData = [] }) => {
  return <PatientDataGrid gridData={gridData} columns={columns} />
}
