import { useState, useEffect } from 'react'
import { defaultSortModel } from './columns'

export const useColumnSortModel = () => {
  const [sortModel, setSortModel] = useState(defaultSortModel)
  const providerId = sessionStorage.getItem('providerID')
  const key = `${providerId}-sortModel`

  useEffect(() => {
    const localSortModel = localStorage.getItem(key)
    if (localSortModel) {
      setSortModel(JSON.parse(localSortModel))
    }
  }, [])

  const setState = (model) => {
    localStorage.setItem(key, JSON.stringify(model))
    setSortModel(model)
  }

  return {
    sortModel,
    setSortModel: setState,
  }
}
