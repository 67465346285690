export const saveClinicalNote = async ({ patientId, note }) => {
  const now = new Date()
  const data = {
    patientId,
    createdAt: now.toISOString(),
    providerId: sessionStorage.getItem('providerID'),
    note,
  }
  return await fetch('/routes/note', {
    withCredentials: true,
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw new Error(response.status)
    })
    .catch((error) => {
      return { error }
    })
}
