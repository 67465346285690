import { useState, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { DevicesGrid } from './DevicesGrid/DevicesGrid'
import { v4 as uuidv4 } from 'uuid'
import { useElementById } from './useElementById'
import {
  DELETE_ACTION,
  CHANGE_ACTION,
  UNMODIFIED_STATUS,
  CHANGED_STATUS,
  RENEW_ACTION,
  SAVED_STATUS,
  ERROR_STATUS,
} from './constants'
import { editData } from './actions/editData'
import { deleteData } from './actions/deleteData'
import { renewData } from './actions/renewData'
import { saveData } from './actions/saveData'
import Alert from '@mui/material/Alert'
import { bulkCreateDevices } from '../bulkCreateDevices'

const isItemReadyToSave = ({ status }) =>
  status === UNMODIFIED_STATUS || status === CHANGED_STATUS

const hasItemsToSave = (data) => data.some(isItemReadyToSave)

const prepareRequest = (data) => data.filter(isItemReadyToSave)

const getSaveStatus = (data) => ({
  saved: data.filter((item) => item.status === SAVED_STATUS)?.length || 0,
  error: data.filter((item) => item.status === ERROR_STATUS)?.length || 0,
})

const prepareData = (devices) =>
  devices.map((device) => ({
    id: uuidv4(),
    status: UNMODIFIED_STATUS,
    device,
  }))

export const FinalStage = ({
  devices = [],
  customers,
  endpoints,
  actionId,
  onBack,
  onSave,
  onClose,
}) => {
  const [data, setData] = useState(prepareData(devices))
  const actionElement = useElementById(actionId)
  const [serverError, setServerError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const { saved: savedLength, error: errorLength } = useMemo(
    () => getSaveStatus(data),
    [data]
  )

  const changeHandler = ({ id, type, device }) => {
    if (type === CHANGE_ACTION) {
      setData(editData({ id, device }))
    }
    if (type === DELETE_ACTION) {
      setData(deleteData({ id }))
    }
    if (type === RENEW_ACTION) {
      setData(renewData({ id }))
    }
  }

  const saveHandler = async () => {
    try {
      setLoading(true)

      await bulkCreateDevices(prepareRequest(data)).then((result) => {
        setData(saveData(result))
        onSave?.()
        setLoading(false)
      })
    } catch (e) {
      setServerError(e.toString())
      setLoading(false)
    }
  }

  return (
    <div>
      <DevicesGrid
        data={data}
        customers={customers}
        endpoints={endpoints}
        onChange={changeHandler}
      />
      {actionElement &&
        createPortal(
          <>
            {hasItemsToSave(data) ? (
              <>
                <Button variant="secondary" onClick={onBack}>
                  Back
                </Button>
                <Button
                  disabled={isLoading}
                  variant="primary"
                  onClick={saveHandler}
                >
                  Save
                </Button>
              </>
            ) : (
              <AcionWrapper>
                <div>
                  Saved: {savedLength}, Errors: {errorLength}
                </div>
                <Button variant="primary" onClick={onClose}>
                  Close
                </Button>
              </AcionWrapper>
            )}
          </>,
          actionElement
        )}
      {serverError && (
        <Alert sx={{ mt: 2 }} severity="error">
          {serverError}
        </Alert>
      )}
    </div>
  )
}

const AcionWrapper = styled('div')({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
})
