import { Component } from 'react'
import Alert from '@mui/material/Alert'

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    console.error(error, info.componentStack)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert sx={{ mt: 2 }} severity="error">
          {this.props.fallback || 'Something went wrong'}
        </Alert>
      )
    }

    return this.props.children
  }
}
