import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import { Controller, useFormContext } from 'react-hook-form'
import Skeleton from '@mui/material/Skeleton'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Divider from '@mui/material/Divider'
import { EndpointsForm } from './EndpointsForm'
import Alert from '@mui/material/Alert'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export const CustomerForm = ({
  isLoading,
  serverError,
  isClinicsLoaded,
  clinics,
  onTestEndpoint,
}) => {
  const {
    formState: { errors, isSubmitting },
    control,
  } = useFormContext()

  return (
    <Stack>
      {isLoading ? (
        <>
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton
            variant="rounded"
            width="100%"
            height={102}
            sx={{ mt: 2 }}
          />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
        </>
      ) : (
        <>
          <Controller
            control={control}
            name="name"
            rules={{
              required: 'Required field',
            }}
            render={({ field: { name, value, onChange } }) => (
              <TextField
                name={name}
                disabled={isSubmitting}
                variant="outlined"
                required
                label="Name"
                value={value}
                margin="normal"
                onChange={onChange}
                autoFocus
                error={errors[name]}
                helperText={errors[name]?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="contact"
            rules={{
              required: 'Required field',
            }}
            render={({ field: { name, value, onChange } }) => (
              <TextField
                name={name}
                disabled={isSubmitting}
                variant="outlined"
                required
                label="Contact"
                value={value}
                margin="normal"
                onChange={onChange}
                error={errors[name]}
                helperText={errors[name]?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="contactNumber"
            rules={{
              required: 'Required field',
            }}
            render={({ field: { name, value, onChange } }) => (
              <TextField
                name={name}
                disabled={isSubmitting}
                variant="outlined"
                required
                label="Primary Contact Number"
                value={value}
                margin="normal"
                onChange={onChange}
                error={errors[name]}
                helperText={errors[name]?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            rules={{
              required: 'Required field',
            }}
            render={({ field: { name, value, onChange } }) => (
              <TextField
                name={name}
                disabled={isSubmitting}
                variant="outlined"
                required
                label="Email"
                value={value}
                margin="normal"
                onChange={onChange}
                error={errors[name]}
                helperText={errors[name]?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="address"
            rules={{
              required: 'Required field',
            }}
            render={({ field: { name, value, onChange } }) => (
              <TextField
                name={name}
                disabled={isSubmitting}
                variant="outlined"
                required
                label="Address"
                value={value}
                margin="normal"
                onChange={onChange}
                multiline
                rows={3}
                error={errors[name]}
                helperText={errors[name]?.message}
              />
            )}
          />

          {isClinicsLoaded ? (
            <Controller
              control={control}
              name="clinics"
              rules={{
                validate: (value) =>
                  value.length === 0 ? 'Required field' : undefined,
              }}
              render={({ field: { name, value, onChange } }) => (
                <FormControl fullWidth margin="normal" error={errors[name]}>
                  <Autocomplete
                    multiple
                    value={value}
                    onChange={(event, newValue) => onChange(newValue)}
                    options={clinics}
                    getOptionLabel={(option) => option?.name}
                    getOptionKey={({ _id }) => _id}
                    isOptionEqualToValue={(option, value) =>
                      option?._id === value?._id
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Clinics"
                        error={errors[name]}
                      />
                    )}
                  />
                  {errors[name] && (
                    <FormHelperText>{errors[name]?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          ) : (
            <Skeleton
              variant="rounded"
              width="100%"
              height={56}
              sx={{ mt: 2 }}
            />
          )}

          <Divider sx={{ mt: 3, mb: 3 }} />

          <EndpointsForm onTestEndpoint={onTestEndpoint} />

          {serverError && (
            <Alert sx={{ mt: 2 }} severity="error">
              {serverError}
            </Alert>
          )}
        </>
      )}
    </Stack>
  )
}
