const timeZones = {
  AST: 'America/Puerto_Rico',
  EST: 'America/New_York',
  CST: 'America/Phoenix',
  MST: 'America/Denver',
  PST: 'America/Los_Angeles',
  AKST: 'America/Juneau',
  SAST: 'Asia/Riyadh',
  PKT: 'Asia/Karachi',
  GST: 'Asia/Dubai',
  HST: 'HST',
  UTC: 'UTC',
}

export const getTimeZone = (inputTimeZone) =>
  timeZones[inputTimeZone] || inputTimeZone
