export const updateDevice = (id, createDeviceRequest) =>
  fetch(`/routes/devices/${id}`, {
    withCredentials: true,
    method: 'PUT',
    body: JSON.stringify(createDeviceRequest),
    headers: { 'Content-Type': 'application/json' },
  }).then((response) => {
    if (response.ok) {
      return response.json()
    } else {
      throw Error('server error')
    }
  })
