export const getFieldNames = (vital, parameter) => {
  const vitalParam = `${vital}.${parameter}`
  const upperHighEnabledField = `${vitalParam}.upperHigh.enabled`
  const upperMediumEnabledField = `${vitalParam}.upperMedium.enabled`
  const lowerHighEnabledField = `${vitalParam}.lowerHigh.enabled`
  const lowerMediumEnabledField = `${vitalParam}.lowerMedium.enabled`
  const upperHighValue = `${vitalParam}.upperHigh.value`
  const upperMediumValue = `${vitalParam}.upperMedium.value`
  const lowerHighValue = `${vitalParam}.lowerHigh.value`
  const lowerMediumValue = `${vitalParam}.lowerMedium.value`
  return {
    upperHighEnabledField,
    upperMediumEnabledField,
    lowerHighEnabledField,
    lowerMediumEnabledField,
    upperHighValue,
    upperMediumValue,
    lowerHighValue,
    lowerMediumValue,
  }
}
