import { TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'

export const ControlledTextField = ({
  inputName,
  label,
  control,
  error,
  required = false,
  rules = {},
  style = { marginLeft: '15px', width: '40%' },
}) => {
  return (
    <Controller
      name={inputName}
      control={control}
      rules={rules}
      render={({ field: { name, value, onChange } }) => (
        <TextField
          required={required}
          variant="outlined"
          margin="normal"
          style={style}
          label={label}
          value={value}
          name={name}
          onChange={onChange}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  )
}
