import { useState } from 'react'
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { WithingsSignupForm } from './WithingsSignUpForm'
import { parseForm } from './parseForm'
import { registerDevice } from './registerDevice'

export const RegisterWithingsDevices = ({ patients, adminId, providerId }) => {
  const [serverMessage, setServerMessage] = useState('')

  const onSubmit = async (formData) => {
    const parsedData = parseForm(formData)
    const input = {
      ...parsedData,
      providerId,
      adminId,
    }
    const response = await registerDevice(input)
    if (response.error) {
      setServerMessage(response.error)
    } else if (response.errors) {
      setServerMessage('There has been an error, please try again')
    } else {
      setServerMessage(response.message)
    }
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="withings-header"
      >
        <h4>Withings</h4>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <WithingsSignupForm onSubmit={onSubmit} patients={patients} />
          <Typography style={{ marginTop: '25px' }}>{serverMessage}</Typography>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
