import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const Confirmation = ({ imei }) => {
  const theme = useTheme()
  return (
    <Box>
      <Typography variant="h5" align="center" marginBottom={theme.spacing(2)}>
        Device Registered
      </Typography>
      <Typography variant="body1" component="p" marginBottom={theme.spacing(2)}>
        You have successfully registered your Cardiowell device IMEI# {imei}.
      </Typography>
      <Typography variant="body1" component="p" marginBottom={theme.spacing(2)}>
        We just sent an SMS to the phone number provided to confirm your
        registration. The SMS also contains a secure magic link to view your
        results anytime.
      </Typography>
      <Typography variant="body1" component="p" marginBottom={theme.spacing(2)}>
        We congratulate you for taking a significant step to improving your
        health.
      </Typography>
      <Typography variant="body1" component="p" marginBottom={theme.spacing(2)}>
        We support you in every step of your journey towards better health. You
        are not alone in this.
      </Typography>
      <Typography variant="body1" component="p" marginBottom={theme.spacing(2)}>
        If you have yet to receive the SMS, contact us at{' '}
        <a href="mailto:help@cardiowell.com">help@cardiowell.com</a> for
        assistance.
      </Typography>
      <Typography variant="body1" marginBottom={theme.spacing(2)}>
        We suggest taking a screenshot of this message for future reference.
      </Typography>
    </Box>
  )
}
