import { dateTransform } from '../../utils/dateTransform'
import { manufacturers, manufacturersMap } from '../../utils/manufacturers'
import { deviceTypesMap, deviceTypes } from '../../utils/deviceTypes'
import { getLanguage } from '../../../locale/getLocale'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreIcon from '@mui/icons-material/Restore'
import EditIcon from '@mui/icons-material/Edit'
import ErrorIcon from '@mui/icons-material/Error'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Box from '@mui/material/Box'
import {
  DELETED_STATUS,
  CHANGED_STATUS,
  UNMODIFIED_STATUS,
  ERROR_STATUS,
  SAVED_STATUS,
  SKIPPED_STATUS,
} from '../constants'

const commonColumnProps = {
  flex: 1,
  aggregable: false,
  groupable: false,
  resizable: false,
  minWidth: 120,
}

const IdWithActionsAndStatus = ({
  id,
  status,
  children,
  onDelete,
  onEdit,
  onRenew,
  onShowError,
  onShowDetails,
}) => (
  <Box>
    {status === ERROR_STATUS ? (
      <IconButton onClick={() => onShowError?.(id)}>
        <ErrorIcon />
      </IconButton>
    ) : null}
    {status === SAVED_STATUS ? (
      <IconButton onClick={() => onShowDetails?.(id)}>
        <CheckBoxIcon />
      </IconButton>
    ) : null}

    {status === DELETED_STATUS ? (
      <IconButton onClick={() => onRenew?.(id)}>
        <RestoreIcon />
      </IconButton>
    ) : null}

    {status === CHANGED_STATUS || status === UNMODIFIED_STATUS ? (
      <>
        <IconButton onClick={() => onDelete?.(id)}>
          <DeleteIcon />
        </IconButton>
        <IconButton onClick={() => onEdit?.(id)}>
          <EditIcon />
        </IconButton>
      </>
    ) : null}

    {children}
  </Box>
)

const createGetClinic = (customers) => {
  const clinics = customers.map((value) => value.clinics).flat()
  return (id) => clinics.find(({ _id }) => _id === id)
}

const createGetEndponits = (customers) => {
  const endpoints = customers.map((value) => value.endpoints).flat()
  return (id) => endpoints.find(({ _id }) => _id === id)
}

export const createColumns = ({
  onDelete,
  onEdit,
  onRenew,
  customers,
} = {}) => [
  {
    ...commonColumnProps,
    field: 'identifier',
    minWidth: 240,
    headerName: 'IMEI / Device Id',
    type: 'string',
    hideable: false,
    sortable: false,
    valueGetter: (params) => params.row.device.identifier,
    renderCell: ({ row, id }) => {
      return (
        <div
          component="button"
          variant="body2"
          style={
            row.status === DELETED_STATUS || row.status === SKIPPED_STATUS
              ? { textDecoration: 'line-through' }
              : {}
          }
        >
          <IdWithActionsAndStatus
            status={row.status}
            onDelete={onDelete}
            onRenew={onRenew}
            onEdit={onEdit}
            id={id}
          >
            {row.device.identifier}
          </IdWithActionsAndStatus>
        </div>
      )
    },
  },
  {
    ...commonColumnProps,
    field: 'device.type',
    headerName: 'Type',
    minWidth: 100,
    type: 'singleSelect',
    valueGetter: (params) => params.row.device.type,
    valueFormatter: (params) => {
      const value = params.api.getRow(params.id)
      return deviceTypesMap.get(value.device.type) || value.device.type
    },
    valueOptions: deviceTypes,
  },
  {
    ...commonColumnProps,
    field: 'device.manufacturer',
    headerName: 'Manufacturer',
    minWidth: 100,
    type: 'singleSelect',
    valueGetter: (params) => params.row.device.manufacturer,
    valueFormatter: (params) => {
      const value = params.api.getRow(params.id)
      return manufacturersMap.get(value.device.manufacturer)
    },
    valueOptions: manufacturers,
  },
  {
    ...commonColumnProps,
    field: 'device.customer',
    headerName: 'Customer',
    type: 'string',
    valueGetter: (params) => params.row.device.customer,
    valueFormatter: (params) => {
      const value = params.api.getRow(params.id)
      const customer = customers.find(
        ({ _id }) => _id === value.device.customer
      )
      return customer?.name
    },
  },
  {
    ...commonColumnProps,
    field: 'device.clinic',
    headerName: 'Clinic',
    type: 'string',
    valueGetter: (params) => params.row.device.clinic,
    valueFormatter: (params) => {
      const value = params.api.getRow(params.id)
      return createGetClinic(customers)(value.device?.clinic)?.name
    },
  },
  {
    ...commonColumnProps,
    field: 'device.endpoint',
    headerName: 'Endpoint',
    type: 'string',
    valueGetter: (params) => params.row.device.endpoint,
    valueFormatter: ({ id, api }) => {
      const value = api.getRow(id)
      return createGetEndponits(customers)(value.device.endpoint)?.name
    },
  },
  {
    ...commonColumnProps,
    field: 'device.billingStart',
    headerName: 'Billing Start',
    type: 'date',
    valueGetter: (params) => params.row.device.billingStart,
    valueFormatter: (params) => {
      const value = params.api.getRow(params.id)
      return value.device.billingStart
        ? new Intl.DateTimeFormat(getLanguage()).format(
            dateTransform.input(value.device.billingStart)
          )
        : ''
    },
  },
  {
    ...commonColumnProps,
    field: 'device.billingEnd',
    headerName: 'Billing End',
    type: 'date',
    valueGetter: (params) => params.row.device.billingEnd,
    valueFormatter: (params) => {
      const value = params.api.getRow(params.id)
      return value.device.billingEnd
        ? new Intl.DateTimeFormat(getLanguage()).format(
            dateTransform.input(value.device.billingEnd)
          )
        : ''
    },
  },
]
