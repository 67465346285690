import { useState } from 'react'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useResponseState } from '../../common/useResponsiveState'

export const InfoDropdown = ({
  items = [],
  fitView,
  setFitView,
  generateReport,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { isMobile } = useResponseState()
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const toggleFitView = () => {
    setFitView((prev) => !prev)
    handleClose()
  }

  return (
    <div>
      <IconButton
        aria-label="info-dropdown"
        size="large"
        onClick={handleClick}
        color="black"
      >
        <ExpandMoreIcon fontSize="inherit" />
      </IconButton>
      <Menu
        id="info-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items.map((item, index) => (
          <MenuItem style={{ backgroundColor: 'white' }} key={index}>
            {item}
          </MenuItem>
        ))}
        {isMobile && (
          <MenuItem
            style={{ backgroundColor: 'white' }}
            onClick={() => toggleFitView()}
          >
            <Typography variant="button">
              {fitView ? 'Show recent data' : 'Show all data'}
            </Typography>
          </MenuItem>
        )}
        <MenuItem style={{ backgroundColor: 'white' }}>
          <Typography variant="button" onClick={generateReport}>
            Share Report
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}
