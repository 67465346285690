import EChartsReact from 'echarts-for-react'
import { blue } from '../../common/colors'
import { useResponseState } from '../../common/useResponsiveState'

export const EchartsLine = ({
  title,
  legend,
  xAxis,
  yAxis,
  lines,
  fitView,
}) => {
  const { isMobile } = useResponseState()
  const series = lines.map((line) => {
    return {
      type: 'line',
      symbol: 'circle',
      smooth: true,
      lineStyle: {
        width: 1.3,
        color: blue,
      },
      label: {
        show: true,
        position: 'top',
        textStyle: {
          fontSize: 10,
        },
      },
      labelLayout: {
        hideOverlap: true,
      },
      ...line,
    }
  })

  const endValue = Math.max(0, xAxis?.data?.length - 1)
  const startValue = Math.max(0, endValue - 4)

  const mobileLegend = {
    orient: 'vertical',
    itemGap: 40,
    icon: 'none',
    right: 0,
    top: 110,
  }

  const dataZoom =
    !fitView && isMobile
      ? [
          {
            type: 'inside',
            disabled: false,
            throttle: 50,
            startValue,
            endValue,
            zoomOnMouseWheel: false,
            zoomOnMouseMove: false,
          },
        ]
      : [
          {
            type: 'inside',
            disabled: true,
            throttle: 50,
            startValue: 0,
            endValue,
            zoomOnMouseWheel: false,
            zoomOnMouseMove: false,
          },
        ]

  const option = {
    title: {
      text: title,
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      ...(isMobile && mobileLegend),
      ...legend,
    },
    xAxis,
    yAxis,
    series,
    dataZoom,
  }

  return <EChartsReact option={option} />
}
