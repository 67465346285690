import React, { useState } from 'react'
import openSocket from 'socket.io-client'
import CssBaseline from '@material-ui/core/CssBaseline'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import cardiowell2 from '../images/cardiowell2.png'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import SettingsIcon from '@material-ui/icons/Settings'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
import IconButton from '@mui/material/IconButton'
import timeme from 'timeme.js'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import 'moment/locale/fr'
import WEBSOCKET_URL from '../common/common'
import { useStyles } from './common/style'
import { AppBar, Drawer, DrawerHeader } from './common/MiniDrawer'
import { PatientDataDashboard } from './PatientData/Dashboard'
import { DisplaySettings } from './common/DisplaySetings'
import { AddPatientForm } from './common/AddPatientForm'
import { RegisterWithingsDevices } from '../withings/Withings'
import { DevicesOther } from '@material-ui/icons'
import { ProviderDashboardTable } from '../provider/PatientListGrid/ProviderDashboardTable'
import { ProgramsList } from '../provider/ProgramsList'
import { password_check } from '../common/regex'
import { sendTimerData } from './PatientData/sendTimerData'

let socket = openSocket(WEBSOCKET_URL)

const MAIN_TAB = 'main_tab'
const PATIENT_TAB = 'patients_tab'
const SETTINGS_TAB = 'settings_tab'
const DEVICES_TAB = 'devices_tab'
const PROGRAM_TAB = 'program_tab'

export default function DashboardPage(props) {
  if (sessionStorage.getItem('user') === null) {
    props.history.push('/')
  }

  const [tab, setTab] = useState(MAIN_TAB)
  const [selectedPatientId, setSelectedPatientId] = React.useState('')
  const [selectedPatientData, setSelectedPatientData] = React.useState(null)
  const [password1, setPassword1] = React.useState('')
  const [password2, setPassword2] = React.useState('')
  const [invitedPatient, setInvitedPatient] = React.useState('')
  const [passwordFormMessage, setPasswordFormMessage] = React.useState('')
  const [invitePatientFormMessage, setInvitePatientFormMessage] =
    React.useState('')
  const [addPatientFormMessage, setAddPatientFormMessage] = React.useState('')
  const [patients, setPatients] = React.useState([])
  const [imageData, setImageData] = React.useState('')
  const clinic = props.location.state.clinic
  const clinicId = props.location.state.clinicId
  const providerId = sessionStorage.getItem('providerID')

  function invitePatient() {
    const inputValue = {
      id: invitedPatient,
      providerID: providerId,
    }
    setInvitePatientFormMessage('Sending invitation...')
    fetch('/routes/users/invitePatient', {
      method: 'POST',
      body: JSON.stringify(inputValue),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (response.status !== 201) {
          return
        } else {
          return response.json()
        }
      })
      .then((data) => {
        try {
          if (data.message === 'Success') {
            setInvitePatientFormMessage('Invitation sent!')
          }
        } catch (e) {
          console.error(e)
          setInvitePatientFormMessage('Error. Please try again.')
        }
      })
      .catch((error) => {
        console.error(error)
        setInvitePatientFormMessage('Error. Please try again.')
      })
  }

  function resetPassword() {
    if (password_check(password1)) {
      if (password1 === password2) {
        const inputValue = {
          username: sessionStorage.getItem('user'),
          password: password1,
          providerID: providerId,
        }
        fetch('/routes/users/resetPassword2', {
          method: 'POST',
          body: JSON.stringify(inputValue),
          headers: { 'Content-Type': 'application/json' },
        })
          .then((response) => {
            if (response.status !== 201) {
              return setPasswordFormMessage(
                'There has been an error. Please try again.'
              )
            } else {
              return response.json()
            }
          })
          .then((data) => {
            try {
              if (data.message === 'Success') {
                setPasswordFormMessage('Your password has been reset!')
                setPassword1('')
                setPassword2('')
              }
            } catch (e) {
              return setPasswordFormMessage(
                'There has been an error. Please try again.'
              )
            }
          })
          .catch((error) => {
            console.error(error)
            setPasswordFormMessage('There has been an error. Please try again.')
          })
      } else {
        setPasswordFormMessage('Passwords do not match!')
      }
    } else {
      setPasswordFormMessage(
        '"Password must contain 8 characters, an uppercase, a lowercase, a number, and a special character"'
      )
    }
  }

  const addPatient = (body) => {
    if (body.patientTimeZone.length <= 0) {
      setAddPatientFormMessage("Please set the patient's time zone.")
      return
    }
    setAddPatientFormMessage('Adding patient. Please wait...')
    return fetch('/routes/users/providerAddPatient', {
      method: 'POST',
      body: JSON.stringify({
        ...body,
        providerId,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (response.status !== 201) {
          return setAddPatientFormMessage(
            'There has been an error. Please try again.'
          )
        } else {
          return response.json()
        }
      })
      .then((data) => {
        if (data.message === 'Success') {
          setAddPatientFormMessage('')
          const updatedPatients = [...patients, data.data]
          setPatients(updatedPatients)
          setTab(MAIN_TAB)
        }
      })
      .catch((error) => {
        console.error(error)
        setAddPatientFormMessage('There has been an error. Please try again.')
      })
  }

  const removePatientFromList = (patientId) => {
    setSelectedPatientId('')
    const updatedPatients = patients.filter(
      (patient) => patient.id !== patientId
    )
    setPatients(updatedPatients)
    setTab(MAIN_TAB)
  }

  const updatePatientInList = (patient) => {
    const index = patients.findIndex((elem) => elem.id === patient.id)
    patients[index] = patient
    setPatients([...patients])
  }

  const updatePatientTime = (timerData) => {
    if (timerData.message === 'Success') {
      const { patientId, time } = timerData.saveTime
      const index = patients.findIndex((elem) => elem.id === patientId)
      patients[index].rt += time
      setPatients([...patients])
    }
  }

  function exportPDF() {
    setTimeout(function () {
      html2canvas(document.body).then((canvas) => {
        //used to be el4, this needs to be fixed.
        document.body.appendChild(canvas) // if you want see your screenshot in body.
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF()
        const width = pdf.internal.pageSize.getWidth()
        const height = pdf.internal.pageSize.getHeight()
        pdf.addImage(imgData, 'PNG', 0, 0, width, height - 100, '', 'MEDIUM')
        pdf.save('report.pdf')
        document.querySelector('canvas').remove()
      })
    }, 2000)
  }

  const navigatePatient = (patientId) => {
    const patient = patients.find((patient) => patient.id === patientId) ?? ''
    if (patient) {
      fetch('/routes/users/getPatientData', {
        method: 'POST',
        body: JSON.stringify({ id: patient.id }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === 'Success') {
            setSelectedPatientData(data.data)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    setSelectedPatientId(patient.id)
    setTab(PATIENT_TAB)
  }

  React.useEffect(() => {
    fetch('/routes/users/getClinicPatientOverviews', {
      method: 'POST',
      body: JSON.stringify({ clinic, providerId }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'Success') {
          setPatients(data.data)
        }
      })
      .catch((error) => {
        console.error(error)
      })

    // start regular patient data update
    socket = openSocket(WEBSOCKET_URL)
    const emittedData = {
      clinic: clinic,
      providerID: sessionStorage.getItem('providerID'),
    }
    socket.emit('patientData', emittedData)
    socket.on('patientData', (data) => {
      if (data === 'Error: Not Authenticated') {
        sessionStorage.clear()
        props.history.push('/')
      } else if (data.constructor === Array) {
        setPatients(data)
        // update selected patient data
        setSelectedPatientId((currId) => {
          if (currId) {
            socket.emit('patientDashboardData', { id: currId })
          }
          return currId
        })
      }
    })

    socket.on('patientDataUpdate', (data) => {
      if (data === 'Error: Not Authenticated') {
        sessionStorage.clear()
        props.history.push('/')
      } else if (data.constructor === Array) {
        setPatients(data)
        // update selected patient data
        setSelectedPatientId((currId) => {
          if (currId) {
            socket.emit('patientDashboardData', { id: currId })
          }
          return currId
        })
      }
    })

    socket.on('withingsDataUpdate', (data) => {
      if (data.clinic && data.clinic === clinic && data.patientId) {
        socket.emit('patientDataUpdate', emittedData)
      }
    })

    socket.on('patientDashboardData', (data) => {
      if (data & (data !== 'Error: Not Authenticated')) {
        setSelectedPatientData(data)
      }
    })

    fetch(`/routes/users/clinic-images/${clinic}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.image) {
          const imageBase64 = data.image
          const imageUrl = `data:image/jpeg;base64,${imageBase64}`
          setImageData(imageUrl)
        }
      })
      .catch((error) => {
        console.error('Error fetching clinic image:', error)
      })

    // initialize timer
    timeme.initialize({
      currentPageName: `${providerId}_dashboard`,
      idleTimeoutMs: 300000,
      trackWhenUserLeavesPage: false,
    })
  }, [])

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar open={open} handleDrawerOpen={handleDrawerOpen}>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          Patient Care Dashboard
        </Typography>
        {imageData ? (
          <img
            style={{ width: '125px', height: '30px' }}
            src={imageData}
            alt="Clinic"
          />
        ) : (
          <img
            style={{ width: '125px', height: '25px' }}
            src={cardiowell2}
            alt="Default"
          />
        )}
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem
            button
            onClick={() => {
              setTab(MAIN_TAB)
            }}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setTab(DEVICES_TAB)
            }}
          >
            <ListItemIcon>
              <DevicesOther />
            </ListItemIcon>
            <ListItemText primary="Devices" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setTab(PROGRAM_TAB)
            }}
          >
            <ListItemIcon>
              <MonitorHeartIcon />
            </ListItemIcon>
            <ListItemText primary="Programs" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              setTab(SETTINGS_TAB)
            }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem
            button
            onClick={async () => {
              await fetch('/routes/users/logout', {
                method: 'POST',
                body: JSON.stringify({}),
                headers: { 'Content-Type': 'application/json' },
              })
                .then(async () => {
                  await sendTimerData(selectedPatientId)
                  await socket.disconnect()
                  sessionStorage.clear()
                  props.history.push('/')
                })
                .catch((error) => {
                  console.error(error)
                })
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      {tab === MAIN_TAB && (
        <main className={classes.content} show={tab === MAIN_TAB}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            <Paper>
              <ProviderDashboardTable
                patients={patients}
                clinicId={clinicId}
                navigatePatient={navigatePatient}
                savePatientData={(patient) => {
                  const index = patients.findIndex(
                    (elem) => elem.id === patient.id
                  )
                  patients[index] = patient
                  setPatients([...patients])
                }}
              />
            </Paper>
          </Container>
        </main>
      )}
      {tab === PATIENT_TAB && (
        <PatientDataDashboard
          patient={patients.find((patient) => selectedPatientId === patient.id)}
          clinic={clinic}
          measurements={selectedPatientData}
          exportPDF={exportPDF}
          removePatientFromList={removePatientFromList}
          updatePatientInList={updatePatientInList}
          updateTime={updatePatientTime}
        />
      )}
      {tab === SETTINGS_TAB && (
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <Typography className={classes.heading}>
                  <h4>Change Password</h4>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Grid container spacing={3}>
                    <form
                      className={classes.form}
                      noValidate
                      onSubmit={(e) => {
                        e.preventDefault()
                      }}
                    >
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Password"
                        type="Password"
                        name="email"
                        autoFocus
                        onChange={(event) => setPassword1(event.target.value)}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Confirm Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(event) => setPassword2(event.target.value)}
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.submit}
                        onClick={resetPassword}
                      >
                        Reset Password
                      </Button>
                      <p>{passwordFormMessage}</p>
                    </form>
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Container>
          <Container
            maxWidth="lg"
            className={classes.container}
            style={{ marginTop: '-25px' }}
          >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <Typography className={classes.heading}>
                  <h4>Add Patient</h4>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AddPatientForm
                  clinic={clinic}
                  providerID={providerId}
                  serverMessage={addPatientFormMessage}
                  addPatient={addPatient}
                />
              </AccordionDetails>
            </Accordion>
          </Container>
          <Container>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <Typography className={classes.heading}>
                  <h4>Invite Patient to Careportal</h4>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Grid>
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(event) =>
                          setInvitedPatient(event.target.value)
                        }
                      >
                        {patients.map((individualPatient, index) => (
                          <MenuItem key={index} value={individualPatient.id}>
                            {individualPatient.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br></br>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ marginTop: '15px' }}
                      onClick={invitePatient}
                    >
                      Send Invite
                    </Button>
                    <br></br>
                    <br></br>
                    <p>{invitePatientFormMessage}</p>
                    <div style={{ marginTop: '25px' }}></div>
                    <div></div>
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Container>
          <div style={{ marginTop: '60px' }}></div>
          <DisplaySettings onUpdate={() => {}} />
        </main>
      )}
      {tab === DEVICES_TAB && (
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <RegisterWithingsDevices
              patients={patients}
              providerId={providerId}
            />
          </Container>
        </main>
      )}
      {tab === PROGRAM_TAB && (
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <ProgramsList clinicId={clinicId} />
          </Container>
        </main>
      )}
    </div>
  )
}
