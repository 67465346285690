import { useMemo } from 'react'
import moment from 'moment-timezone'
import { MultiLineChart } from '../../common/MultiLineChart.jsx'
import { ThresholdDot } from '../../common/ThresholdDot.jsx'
import { green } from '../../../common/colors.js'

export const WeightScaleChart = ({
  threshold,
  chartData = [],
  weightUnit,
  lowWeight,
  highWeight,
  timeZone,
}) => {
  const data = useMemo(
    () =>
      chartData.reverse().map((data) => {
        let taken = ''
        if (moment(data.ts).tz(timeZone)) {
          taken = moment(data.ts).tz(timeZone).format('MM/DD/YY')
        }
        return {
          date: taken,
          Weight:
            weightUnit === 'Lbs'
              ? (
                  ((parseFloat(data.weight) / 100) * 22046) /
                  100 /
                  100 /
                  10
                ).toFixed(2)
              : (parseFloat(data.weight) / 1000).toFixed(2),
        }
      }),
    [chartData, timeZone, weightUnit]
  )
  return (
    <MultiLineChart
      title={'Weight Trend'}
      label={weightUnit}
      lines={[
        {
          key: 'Weight',
          color: green,
          dot: () => <ThresholdDot threshold={threshold} />,
        },
      ]}
      data={data}
      domain={[lowWeight, highWeight]}
    />
  )
}
