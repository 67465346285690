import { Fragment } from 'react'
import { Grid, Paper, Container, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const InfoGrid = ({ info, title, subText, isMobile }) => {
  const theme = useTheme()

  return (
    <Grid item xs={12} md={3}>
      <Paper
        sx={{
          height: {
            xs: 100,
            md: 240,
          },
          padding: theme.spacing(2),
          display: 'flex',
          flexDirection: { xs: 'row', md: 'column' },
          alignItems: 'center',
        }}
      >
        {isMobile ? (
          <Fragment>
            <Container sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6">{title}</Typography>
              <Typography variant="body1">{subText}</Typography>
            </Container>
            <Typography variant="h5">{info}</Typography>
          </Fragment>
        ) : (
          <Fragment>
            <Typography
              variant="h5"
              sx={{ textAlign: 'center', marginTop: '40px' }}
            >
              {info}
            </Typography>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
              {title}
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: 'center', marginTop: '60px' }}
            >
              {subText}
            </Typography>
          </Fragment>
        )}
      </Paper>
    </Grid>
  )
}
