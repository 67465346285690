import parse from 'date-fns/parse'

export const parceBerryMedOximeterTime = (dateString) => {
  if (!dateString) {
    return dateString
  }
  const timeZoneRegex = /([+-]\d{1,2})$/
  const match = dateString.match(timeZoneRegex)

  if (match) {
    const timeZone = match[1]
    const dateStringWithoutTimeZone = dateString.replace(timeZone, '')
    const result = parse(
      `${dateStringWithoutTimeZone}+00`,
      'yy/MM/dd,HH:mm:ssx',
      new Date()
    )

    return result
  }
}
