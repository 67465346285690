import { useId } from 'react'
import Stack from '@mui/material/Stack'
import { Controller, useFormContext } from 'react-hook-form'
import Skeleton from '@mui/material/Skeleton'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import isIMEI from 'validator/es/lib/isIMEI'
import Alert from '@mui/material/Alert'
import { isAfter } from 'date-fns'
import { dateTransform } from './utils/dateTransform'
import { deviceTypes } from './utils/deviceTypes'
import { manufacturers } from './utils/manufacturers'

export const DeviceForm = ({
  isLoading,
  customers,
  clinics,
  endpoints,
  serverError,
  withIdentifier,
}) => {
  const typeLabelId = useId()
  const manufacturerLabelId = useId()
  const customerLabelId = useId()
  const clinicLabelId = useId()
  const endpointLabelId = useId()
  const {
    formState: { errors, isSubmitting },
    control,
    watch,
  } = useFormContext()

  const endpointsDisabled = endpoints.length === 0
  const clinicsDisabled = clinics.length === 0
  const customerId = watch('customer')
  const billingStart = watch('billingStart')

  return (
    <Stack>
      {isLoading ? (
        <>
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
        </>
      ) : (
        <>
          {withIdentifier && (
            <Controller
              control={control}
              name="identifier"
              rules={{
                required: 'Required field',
                // validate: (value) => isIMEI(value) || 'Invalid IMEI',
              }}
              render={({ field: { name, value, onChange } }) => (
                <TextField
                  name={name}
                  disabled={isSubmitting}
                  variant="outlined"
                  required
                  label="IMEI / Device Id"
                  value={value}
                  margin="normal"
                  onChange={onChange}
                  autoFocus
                  error={errors[name]}
                  helperText={errors[name]?.message}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="type"
            rules={{
              required: 'Required field',
            }}
            render={({ field: { name, value, onChange } }) => (
              <FormControl fullWidth margin="normal" error={errors[name]}>
                <InputLabel id={typeLabelId}>Device Type</InputLabel>
                <Select
                  label="Device Type"
                  labelId={typeLabelId}
                  value={value}
                  onChange={onChange}
                >
                  {deviceTypes.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                {errors[name] && (
                  <FormHelperText>{errors[name]?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="manufacturer"
            render={({ field: { name, value, onChange } }) => (
              <FormControl fullWidth margin="normal" error={errors[name]}>
                <InputLabel id={manufacturerLabelId}>Manufacturer</InputLabel>
                <Select
                  label="Manufacturer"
                  labelId={manufacturerLabelId}
                  value={value}
                  onChange={onChange}
                >
                  {manufacturers.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                {errors[name] && (
                  <FormHelperText>{errors[name]?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="customer"
            render={({ field: { name, value, onChange } }) => (
              <FormControl
                fullWidth
                margin="normal"
                error={errors[name]}
                disabled={customers.length === 0}
              >
                <InputLabel id={customerLabelId}>Customer</InputLabel>
                <Select
                  label="Customer"
                  labelId={customerLabelId}
                  value={value}
                  onChange={onChange}
                >
                  {customers.map(({ _id, name }) => (
                    <MenuItem value={_id}>{name}</MenuItem>
                  ))}
                </Select>
                {errors[name] && (
                  <FormHelperText>{errors[name]?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="clinic"
            render={({ field: { name, value, onChange } }) => (
              <FormControl
                fullWidth
                margin="normal"
                error={errors[name]}
                disabled={clinicsDisabled}
              >
                <InputLabel id={clinicLabelId}>Clinic</InputLabel>
                <Select
                  label="Clinic"
                  labelId={clinicLabelId}
                  value={value}
                  onChange={onChange}
                >
                  {clinics.map(({ _id, name }) => (
                    <MenuItem key={_id} value={_id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {errors[name] && (
                  <FormHelperText>{errors[name]?.message}</FormHelperText>
                )}
                {!customerId && (
                  <FormHelperText>Select "Customer" field</FormHelperText>
                )}
                {!!customerId && clinicsDisabled && (
                  <FormHelperText>
                    The selected customer has no clinics
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="endpoint"
            render={({ field: { name, value, onChange } }) => (
              <FormControl
                fullWidth
                margin="normal"
                error={errors[name]}
                disabled={endpointsDisabled}
              >
                <InputLabel id={endpointLabelId}>Endpoint</InputLabel>
                <Select
                  label="Endpoint"
                  labelId={endpointLabelId}
                  value={value}
                  onChange={onChange}
                >
                  {endpoints.map(({ _id, name }) => (
                    <MenuItem key={_id} value={_id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {errors[name] && (
                  <FormHelperText>{errors[name]?.message}</FormHelperText>
                )}
                {!customerId && (
                  <FormHelperText>Select "Customer" field</FormHelperText>
                )}
                {!!customerId && endpointsDisabled && (
                  <FormHelperText>
                    The selected customer has no endpoints
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="billingStart"
            render={({ field: { name, value, onChange } }) => (
              <FormControl fullWidth margin="normal" error={errors[name]}>
                <DatePicker
                  label="Billing Start Date"
                  value={dateTransform.input(value)}
                  onChange={(e) => onChange(dateTransform.output(e))}
                />
                {errors[name] && (
                  <FormHelperText>{errors[name]?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="billingEnd"
            rules={{
              validate: {
                isAfter: (billingEnd) => {
                  const end = dateTransform.input(billingEnd)
                  const start = dateTransform.input(billingStart)
                  if (end && start) {
                    return isAfter(end, start)
                      ? true
                      : 'The end date must be after the start date'
                  }
                  return true
                },
              },
            }}
            render={({ field: { name, value, onChange } }) => (
              <FormControl fullWidth margin="normal" error={errors[name]}>
                <DatePicker
                  slotProps={{
                    textField: {
                      error: Boolean(errors[name]),
                    },
                  }}
                  label="Billing End Date"
                  value={dateTransform.input(value)}
                  onChange={(e) => onChange(dateTransform.output(e))}
                />
                {errors[name] && (
                  <FormHelperText>{errors[name]?.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </>
      )}
      {serverError && (
        <Alert sx={{ mt: 2 }} severity="error">
          {serverError}
        </Alert>
      )}
    </Stack>
  )
}
