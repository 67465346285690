import moment from 'moment-timezone'
import { pascalsToTorr } from '../../common/conversions'
import { CARDIOWELL, TRANSTEK, WITHINGS, AD } from '../../common/manufacters'
import { parceBerryMedOximeterTime } from '../../helpers/parceBerryMedOximeterTime'
import { getBpThresholdLevel } from '../../thresholds/threshold/getBpThresholdLevel'
import { standardizeAdBpm } from '../../measurements/bpm/standardizeAdBpm'

export const parsePatients = (patients) => {
  const tableData = []
  const user = sessionStorage.getItem('user')

  patients.forEach((patient) => {
    const bpDevice = patient.selectedBpDevice ?? CARDIOWELL
    const weightDevice = patient.selectedWeightDevice ?? CARDIOWELL

    let systolic = ''
    let diastolic = ''
    let bpDate = ''
    let pulse = ''
    let recentBp = 0

    let weight = ''
    let wsDate = ''
    let recentWs = 0

    let glucose = ''
    let glucoseDate = ''
    let recentGlucose = 0

    let spo2 = ''
    let spo2Date = ''
    let pulseRate = ''
    let recentPulse = 0

    // BPM data
    if (bpDevice === TRANSTEK && patient.ttBpm.length) {
      // Transtek
      systolic = parseInt(patient.ttBpm[0].systolic)
      diastolic = parseInt(patient.ttBpm[0].diastolic)
      bpDate = patient.ttBpm[0].ts * 1000
      pulse = patient.ttBpm[0].pulse
      recentBp = patient.ttRecentBpms
    } else if (
      bpDevice === CARDIOWELL &&
      (patient.bpm.length || patient.btMessagesBpm.length)
    ) {
      // Bodytrace
      if (patient.bpm.length) {
        systolic = pascalsToTorr(patient.bpm[0].systolic)
        diastolic = pascalsToTorr(patient.bpm[0].diastolic)
        bpDate = moment(patient.bpm[0]._created_at).valueOf()
        pulse = patient.bpm[0].pulse
      }
      // bodytracemessages
      if (patient.btMessagesBpm.length) {
        const btMessagesBpmDate = moment(
          patient.btMessagesBpm[0].createdAt
        ).valueOf()
        if (btMessagesBpmDate > bpDate) {
          const values = patient.btMessagesBpm[0].message.values
          systolic = pascalsToTorr(values.systolic)
          diastolic = pascalsToTorr(values.diastolic)
          bpDate = btMessagesBpmDate
          pulse = values.pulse
        }
      }
      recentBp = patient.btRecentBpms + patient.btMessagesRecentBpm
    } else if (bpDevice === AD && patient.adBpm.length) {
      const measurement = standardizeAdBpm(patient.adBpm[0])
      systolic = measurement.systolic
      diastolic = measurement.diastolic
      pulse = measurement.pulse
      bpDate = measurement.ts
      recentBp = patient.adRecentBpms
    } else if (bpDevice === WITHINGS && patient.withingsBpm.length) {
      // Withings
      const measurement = patient.withingsBpm[0]
      systolic = measurement.sys.value * Math.pow(10, measurement.sys.unit)
      diastolic = measurement.dia.value * Math.pow(10, measurement.dia.unit)
      pulse = measurement.pulse.value * Math.pow(10, measurement.pulse.unit)
      bpDate = measurement.created * 1000
      recentBp = patient.withingsRecentBpm
    }

    const bpThresholdLevel = getBpThresholdLevel(
      systolic,
      diastolic,
      patient.threshold?.bloodPressure
    )

    // Weight Scale Data
    if (weightDevice === TRANSTEK && patient.ttWs.length) {
      // Transtek
      weight = patient.ttWs[0].wt
      wsDate = patient.ttWs[0].ts * 1000
      recentWs = patient.ttRecentWs
    } else if (
      weightDevice === CARDIOWELL &&
      (patient.ws.length || patient.btMessagesWs.length)
    ) {
      // BodyTrace
      if (patient.ws.length) {
        weight = patient.ws[0].weight
        wsDate = moment(patient.ws[0]._created_at).valueOf()
      }
      if (patient.btMessagesWs.length) {
        const btMessagesWsDate = moment(
          patient.btMessagesWs[0].createdAt
        ).valueOf()
        if (btMessagesWsDate > wsDate) {
          const values = patient.btMessagesWs[0].message.values
          weight = values.weight
          wsDate = btMessagesWsDate
        }
      }
      recentWs = patient.btRecentWs + patient.btMessagesRecentWs
    }

    // Pulse Data
    if (patient.pulse.length) {
      // Body Trace
      spo2Date = moment(parceBerryMedOximeterTime(patient.pulse[0].time))
        .tz(patient.timeZone)
        .valueOf()
      spo2 = patient.pulse[0].spo2
      pulseRate = patient.pulse[0].pr
      recentPulse = patient.recentPulse
    }

    // Glucose Data
    if (patient.glucose.length) {
      // Transtek
      glucoseDate = patient.glucose[0].ts * 1000
      glucose = patient.glucose[0].data
      recentGlucose = patient.recentGlucose
    }

    const rt = patient.rt || 0

    const tableRow = {
      id: patient.id,
      name: patient.name,
      timezone: patient.timeZone,
      bptaken: bpDate ? new Date(bpDate) : null,
      bp: systolic + '/' + diastolic,
      bpThresholdLevel,
      pulse,
      weighttaken: wsDate ? new Date(wsDate) : null,
      weight,
      spo2Date: spo2Date ? new Date(spo2Date) : null,
      spo2,
      pulseRate,
      glucose,
      glucoseDate: glucoseDate ? new Date(glucoseDate) : null,
      deviceNotificationsEnabled: patient.deviceNotificationsEnabled,
      patient,
      cellNumber: patient.cellNumber,
      email: patient.email,
      username: user,
      recentBp,
      recentWs,
      recentPulse,
      recentGlucose,
      rt,
    }
    tableData.push(tableRow)
  })

  return tableData
}
