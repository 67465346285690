import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { Dialog } from '../../components/common/Dialog'
import { getCustomerDetails } from '../getCustomerDetails'
import range from 'lodash/range'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import { startCustomerTest } from './startCustomerTest'
import { stopCustomerTest } from './stopCustomerTest'
import { CustomerDetails } from './CustomerDetails'
import { TestReportView } from './TestReportView/TestReportView'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { EndpointList } from './EndpointList'
import { TestEndpoint } from '../TestEndpoint'
import { testEndpointStatusMessage } from '../testEndpointStatusMessage'
import { testEndpointTelemetryMessage } from '../testEndpointTelemetryMessage'

const DETAILS_MODE = 'details'
const TEST_MODE = 'test'

const getTitle = ({ mode, testEndpoint }) =>
  mode === DETAILS_MODE
    ? 'Customer Details'
    : `Customer Details | ${testEndpoint?.name}`

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const StartStopTestButton = ({
  onStart,
  onStop,
  isStarted,
  isLoading,
  disabled,
}) => {
  if (isLoading) {
    return <Skeleton width={114} height={37} />
  }
  return isStarted ? (
    <Button variant="secondary" onClick={onStop} disabled={disabled}>
      Stop recording
    </Button>
  ) : (
    <Button variant="secondary" onClick={onStart} disabled={disabled}>
      Start Recording
    </Button>
  )
}

const TabPanel = ({ children, value, index, ...other }) =>
  value === index ? (
    <Box sx={{ p: 3 }}>
      <div
        role="tabpanel"
        id={`details-modal-tabpanel-${index}`}
        aria-labelledby={`details-modal-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    </Box>
  ) : null

export const CustomerDetailsModal = ({
  id,
  opend,
  onClose = () => {},
  onEdit = () => {},
}) => {
  const [customer, setCustomer] = useState()
  const [latestTestReport, setLatestTestReport] = useState()
  const [loading, setLoading] = useState()
  const [isTestButtonLoading, setTestButtonLoading] = useState()
  const [mode, setMode] = useState(DETAILS_MODE)
  const [testEndpoint, setTestEndpoint] = useState(null)
  const [testRequest, setTestRequest] = useState(null)
  const [testResponse, setTestResponse] = useState(null)
  const [serverError, setServerError] = useState('')

  const clearTestData = () => {
    setTestEndpoint(null)
    setTestRequest(null)
    setTestResponse(null)
  }

  const closeHandler = () => {
    if (mode === DETAILS_MODE) {
      onClose?.()
      setLoading(true)
      setServerError('')
    } else {
      setMode(DETAILS_MODE)
      clearTestData(null)
    }
  }

  const handleBack = async () => {
    setTestEndpoint(null)
    setMode(DETAILS_MODE)
  }

  const openTestEndpoint = (endpoint) => {
    setTestEndpoint(endpoint)
    setMode(TEST_MODE)
  }

  const [currentTab, setCurrentTab] = useState(0)

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const update = () => {
    setLoading(true)
    getCustomerDetails(id).then(({ customer, latestTestReport }) => {
      setCustomer(customer)
      setLatestTestReport(latestTestReport)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (opend) {
      update()
    }
  }, [opend])

  const createToggleTestHandler =
    ({ start, stop }) =>
    async () => {
      setTestButtonLoading(true)
      try {
        if (start) {
          await startCustomerTest(id)
        }
        if (stop) {
          await stopCustomerTest(id)
        }
        update()
      } catch (e) {
        console.error(e)
      } finally {
        setTestButtonLoading(false)
      }
    }

  const handleSendStatus = async () => {
    try {
      const { request, response } = await testEndpointStatusMessage({
        endpoint: testEndpoint,
      })
      setTestRequest(request)
      setTestResponse(response)
      setServerError(null)
    } catch (e) {
      setServerError(e.message)
    }
  }

  const handleSendTelemetry = async () => {
    try {
      const { request, response } = await testEndpointTelemetryMessage({
        endpoint: testEndpoint,
      })
      setTestRequest(request)
      setTestResponse(response)
      setServerError(null)
    } catch (e) {
      setServerError(e.message)
    }
  }

  return (
    <Dialog
      open={opend}
      title={getTitle({ mode, testEndpoint })}
      handleClose={closeHandler}
      actions={
        mode === DETAILS_MODE ? (
          <>
            <Button variant="secondary" onClick={closeHandler}>
              Close
            </Button>
            <Button variant="secondary" onClick={onEdit}>
              Edit
            </Button>
            <StartStopTestButton
              onStart={createToggleTestHandler({ start: true })}
              onStop={createToggleTestHandler({ stop: true })}
              isLoading={loading}
              disabled={isTestButtonLoading}
              isStarted={Boolean(customer?.testReport)}
            />
          </>
        ) : (
          <>
            <Button onClick={handleBack} variant="primary">
              Back
            </Button>
            <Button onClick={handleSendStatus} variant="primary">
              Send Status
            </Button>
            <Button onClick={handleSendTelemetry} variant="primary">
              Send Telemetry
            </Button>
          </>
        )
      }
    >
      {mode === DETAILS_MODE ? (
        loading ? (
          <Stack spacing={2}>
            {range(5).map(() => (
              <Skeleton width="100%" height={42} />
            ))}
          </Stack>
        ) : (
          <>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Details" {...a11yProps(0)} />
              <Tab label="Endpoints" {...a11yProps(1)} />
              <Tab label="Record Report" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={currentTab} index={0}>
              {customer && <CustomerDetails customer={customer} />}
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <EndpointList
                endpoints={customer?.endpoints}
                onTest={openTestEndpoint}
              />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <TestReportView
                customerId={id}
                latestTestReport={latestTestReport}
              />
            </TabPanel>
          </>
        )
      ) : (
        <TestEndpoint
          endpoint={testEndpoint}
          request={testRequest}
          response={testResponse}
          serverError={serverError}
        />
      )}
    </Dialog>
  )
}
