import { useEffect, useId, useState } from 'react'
import { createPortal } from 'react-dom'
import { Controller, useForm } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { DeviceForm } from '../DeviceForm'
import { FormProvider } from 'react-hook-form'
import { useCustomers } from '../../customer/useCustomers'
import { generateBulkData } from './generateBulkData'
import { useElementById } from './useElementById'

const readFile = (files) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve(e.target.result)
    }
    const file = files.item(0)
    reader.readAsText(file)
  })
}

const parseFileText = async (files) => {
  const fileText = await readFile(files)
  const lines = fileText.split('\n')
  const headers = lines[0].split(',')
  const data = lines.slice(1)

  return {
    headers,
    data,
  }
}
const DEFAULT_FORM_STATE = {
  identifierName: null,
  type: 'bloodPressure',
  manufacturer: null,
  customer: null,
  endpoint: null,
  clinic: null,
  billingStart: null,
  billingEnd: null,
}

export const FillingStage = ({ files, actionId, onBack, onNext }) => {
  const actionElement = useElementById(actionId)
  const identifierLabelId = useId()
  const [loading, setLoading] = useState()
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])
  const formId = useId()
  const [serverError, setServerError] = useState('')

  const methods = useForm({
    defaultValues: DEFAULT_FORM_STATE,
  })

  const customerId = methods.watch('customer')
  const { isCustomersLoaded, customers, clinics, endpoints } = useCustomers({
    customerId,
  })

  const onSubmit = async (formData) => {
    try {
      const devices = generateBulkData({
        data,
        headers,
        ...formData,
      })
      onNext?.({ devices, customers, endpoints })
    } catch (e) {
      console.error(e)
      setServerError(e.toString())
    }
  }

  useEffect(() => {
    setLoading(true)
    parseFileText(files).then(({ headers, data }) => {
      setData(data)
      setHeaders(headers)
      setLoading(false)
    })
  }, [files])

  useEffect(() => {
    methods.setValue('clinic', null)
    methods.setValue('endpoint', null)
  }, [customerId])

  const noIdentifierName = methods.watch('noIdentifierName')

  return (
    <div>
      <FormProvider {...methods}>
        <form id={formId} noValidate onSubmit={methods.handleSubmit(onSubmit)}>
          {headers.length > 0 && (
            <Controller
              control={methods.control}
              name="identifierName"
              render={({ field: { name, value, onChange } }) => {
                return (
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={methods.formState?.errors[name]}
                  >
                    <InputLabel id={identifierLabelId}>
                      {noIdentifierName
                        ? 'Identifier filed (Disabled)'
                        : 'Identifier filed'}
                    </InputLabel>
                    <Select
                      label={
                        noIdentifierName
                          ? 'Identifier filed (Disabled)'
                          : 'Identifier filed'
                      }
                      labelId={identifierLabelId}
                      value={value}
                      onChange={onChange}
                      disabled={noIdentifierName}
                    >
                      {headers.map((header) => (
                        <MenuItem key={header} value={header}>
                          {header}
                        </MenuItem>
                      ))}
                    </Select>
                    {methods.formState?.errors[name] && (
                      <FormHelperText>
                        {methods.formState?.errors[name]?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )
              }}
            />
          )}

          <Controller
            control={methods.control}
            name="noIdentifierName"
            render={({ field: { name, value, onChange } }) => (
              <FormControlLabel
                name={name}
                control={<Checkbox />}
                label="The entire row is an identifier"
                value={value}
                onChange={onChange}
              />
            )}
          />

          <DeviceForm
            serverError={serverError}
            isLoading={!isCustomersLoaded || loading}
            customers={customers}
            clinics={clinics}
            endpoints={endpoints}
          />
        </form>
      </FormProvider>
      {actionElement &&
        createPortal(
          <>
            <Button variant="secondary" onClick={onBack}>
              Back
            </Button>
            <Button
              type="submit"
              form={formId}
              variant="primary"
              disabled={!files}
            >
              Next
            </Button>
          </>,
          actionElement
        )}
    </div>
  )
}
