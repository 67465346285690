import { Dot } from 'recharts'
import { green, red, orange, yellow, darkRed } from '../../common/colors'

export const StageDot = ({
  cx,
  cy,
  stroke,
  value,
  r,
  strokeWidth,
  fill,
  threshold,
}) => {
  if (threshold?.crisis?.enabled && value >= threshold?.crisis?.value) {
    return (
      <Dot
        cx={cx}
        cy={cy}
        r={r}
        stroke={darkRed}
        fill={darkRed}
        strokeWidth={strokeWidth}
      />
    )
  } else if (
    threshold?.stageTwo?.enabled &&
    value >= threshold?.stageTwo?.value
  ) {
    return (
      <Dot
        cx={cx}
        cy={cy}
        r={r}
        stroke={red}
        fill={red}
        strokeWidth={strokeWidth}
      />
    )
  } else if (
    threshold?.stageOne?.enabled &&
    value >= threshold?.stageOne?.value
  ) {
    return (
      <Dot
        cx={cx}
        cy={cy}
        r={r}
        stroke={orange}
        fill={orange}
        strokeWidth={strokeWidth}
      />
    )
  } else if (
    threshold?.elevated?.enabled &&
    value >= threshold?.elevated?.value
  ) {
    return (
      <Dot
        cx={cx}
        cy={cy}
        r={r}
        stroke={yellow}
        fill={yellow}
        strokeWidth={strokeWidth}
      />
    )
  } else if (threshold?.normal?.enabled && value < threshold?.normal?.value) {
    return (
      <Dot
        cx={cx}
        cy={cy}
        r={r}
        stroke={green}
        fill={green}
        strokeWidth={strokeWidth}
      />
    )
  }

  return (
    <Dot
      cx={cx}
      cy={cy}
      r={r}
      stroke={stroke}
      fill={stroke}
      strokeWidth={strokeWidth}
    />
  )
}
