import { DELETED_STATUS } from '../constants'

export const deleteData =
  ({ id }) =>
  (data) => {
    console.log('deleteData >>')
    const editIndex = data.findIndex((val) => val.id === id)
    if (editIndex > -1) {
      const newData = [
        ...data.slice(0, editIndex),
        {
          ...data[editIndex],
          status: DELETED_STATUS,
        },
        ...data.slice(editIndex + 1),
      ]
      return newData
    } else {
      console.error('')
    }

    return data
  }
