import { useState, useEffect } from 'react'
import { Container, CssBaseline, Box, Typography } from '@mui/material'
import cardiowell1 from '../../images/cardiowell1.png'
import { useFormStyles } from '../common/style'
import { Copyright } from '../common/Copyright'
import { DeviceSignupForm } from './DeviceSignupForm'
import { getDeviceStatus } from './getDeviceStatus'
import { registerDeviceUpdates } from './registerDeviceUpdates'
import { history } from '../../App'
import { Confirmation } from './Confirmation'

const alreadyActivatedMessage =
  'Device has already been activated to receive notifications'

export const DeviceSignUp = (props) => {
  const imei = props.match.params.imei
  const classes = useFormStyles()
  const [showForm, setShowForm] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [serverMessage, setServerMessage] = useState('')

  const onSubmit = (formData) => {
    setServerMessage('')
    registerDeviceUpdates({ ...formData, imei }).then((data) => {
      if (data.error) {
        setServerMessage(data.message)
      } else {
        setShowForm(false)
        setServerMessage('')
        setShowConfirmation(true)
      }
    })
  }

  useEffect(() => {
    getDeviceStatus(imei).then((data) => {
      setShowConfirmation(false)
      if (data.activated) {
        setShowForm(false)
        setServerMessage(alreadyActivatedMessage)
        history.push(`/device-updates/dashboard/${imei}`)
      } else {
        setShowForm(true)
        setServerMessage('')
      }
    })
  }, [imei])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.image} src={cardiowell1} alt="" />
        {showForm && (
          <Box>
            <Typography variant="h5" align="center">
              Device Registration
            </Typography>
            <Typography align="center" variant="body1">
              {`Receive updates from your blood pressure device.`}
            </Typography>
            <Typography align="center" variant="body1">
              {`IMEI#: ${imei}`}
            </Typography>
            <DeviceSignupForm onSubmit={onSubmit} />
          </Box>
        )}
        {showConfirmation && <Confirmation imei={imei} />}
        {!!serverMessage && (
          <Typography className={classes.title} variant="body2">
            {serverMessage}
          </Typography>
        )}
      </div>
      <Box className={classes.footer} mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
