import { Typography, Link } from '@material-ui/core'
export const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit">cardiowell</Link> {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
