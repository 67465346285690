import { useEffect } from 'react'
import { history } from '../App'

export const ShortUrlPage = (props) => {
  const token = props.match.params.token

  useEffect(() => {
    const fetchOriginalUrl = async () => {
      const response = await fetch(`/routes/short-urls/${token}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })

      if (response.status === 200) {
        const { originalUrl } = await response.json()

        const isSameDomain = originalUrl.startsWith(window.location.origin)

        if (isSameDomain) {
          const internalPath = originalUrl.replace(window.location.origin, '')
          history.push(internalPath)
        } else {
          window.location.href = originalUrl
        }
      } else {
        console.error('URL not found')
        history.push('/not-found')
      }
    }

    fetchOriginalUrl()
  }, [token, history])

  return <></>
}
