export const editData =
  ({ id, device }) =>
  (data) => {
    const editIndex = data.findIndex((val) => val.id === id)
    if (editIndex > -1) {
      const newData = [
        ...data.slice(0, editIndex),
        {
          ...data[editIndex],
          device,
        },
        ...data.slice(editIndex + 1),
      ]
      return newData
    } else {
      console.error('')
    }

    return data
  }
