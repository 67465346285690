import TextField from '@mui/material/TextField'
import Accordion from '@mui/material/Accordion'
import AccordionActions from '@mui/material/AccordionActions'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Stack from '@mui/material/Stack'
import {
  Controller,
  useFormContext,
  useFieldArray,
  useWatch,
} from 'react-hook-form'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

const DEFAULT_KEY_NAME = 'Authorization'

const createEmptyEndpoint = () => ({
  name: '',
  url: '',
  keyName: '',
  keyValue: '',
  forward: true,
})

const getArrayErrorMessage = (errors, index, name) =>
  errors.endpoints?.[index]?.[name]?.message
const hasArrayError = (errors, index, name) =>
  Boolean(errors.endpoints?.[index]?.[name])

export const EndpointsForm = ({ onTestEndpoint }) => {
  const {
    formState: { errors, isSubmitting },
    control,
  } = useFormContext()
  const {
    fields: endpointFields,
    append: appendEndpoint,
    remove: removeEndpoint,
  } = useFieldArray({
    control,
    name: 'endpoints',
  })
  const endpoints = useWatch({
    control,
    name: 'endpoints',
  })

  return (
    <Stack spacing={2}>
      <Typography variant="h6" component="span">
        Data Forwarding
      </Typography>
      {endpointFields?.length > 0 ? (
        endpointFields?.map(({ id }, index) => (
          <Accordion key={id} variant="outlined">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`endpoint-${id}-content`}
              id={`endpoint-${id}-header`}
            >
              {(endpoints && endpoints[index]?.name) || 'New endpoint'}
            </AccordionSummary>
            <AccordionDetails>
              <Stack>
                <Controller
                  control={control}
                  name={`endpoints[${index}].name`}
                  rules={{
                    required: 'Required field',
                  }}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      name={name}
                      disabled={isSubmitting}
                      variant="outlined"
                      required
                      label="Name"
                      value={value}
                      margin="normal"
                      onChange={onChange}
                      error={hasArrayError(errors, index, 'name')}
                      helperText={getArrayErrorMessage(errors, index, 'name')}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`endpoints[${index}].url`}
                  rules={{
                    required: 'Required field',
                  }}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      name={name}
                      disabled={isSubmitting}
                      variant="outlined"
                      required
                      label="Url"
                      value={value}
                      margin="normal"
                      onChange={onChange}
                      error={hasArrayError(errors, index, 'url')}
                      helperText={getArrayErrorMessage(errors, index, 'url')}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`endpoints[${index}].keyName`}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      name={name}
                      disabled={isSubmitting}
                      variant="outlined"
                      label="Key Name"
                      value={value}
                      margin="normal"
                      onChange={onChange}
                      error={hasArrayError(errors, index, 'keyName')}
                      helperText={getArrayErrorMessage(
                        errors,
                        index,
                        'keyName'
                      )}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`endpoints[${index}].keyValue`}
                  render={({ field: { name, value, onChange } }) => (
                    <TextField
                      name={name}
                      disabled={isSubmitting}
                      variant="outlined"
                      label="Key Value"
                      value={value}
                      margin="normal"
                      onChange={onChange}
                      error={hasArrayError(errors, index, 'keyValue')}
                      helperText={getArrayErrorMessage(
                        errors,
                        index,
                        'keyValue'
                      )}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`endpoints[${index}].forward`}
                  rules={{
                    required: 'Required field',
                  }}
                  render={({ field: { name, value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={value}
                          onChange={onChange}
                          name={name}
                        />
                      }
                      label="Forward through Cardiowell"
                    />
                  )}
                />
              </Stack>
            </AccordionDetails>
            <AccordionActions>
              <Button
                variant="secondary"
                onClick={() => onTestEndpoint(endpoints[index])}
              >
                Test Endpoint
              </Button>
              <Button variant="secondary" onClick={() => removeEndpoint(index)}>
                Delete
              </Button>
            </AccordionActions>
          </Accordion>
        ))
      ) : (
        <Typography variant="body1" component="span">
          No endpoints have been set up yet.
        </Typography>
      )}

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Button
          variant="secondary"
          onClick={() => appendEndpoint(createEmptyEndpoint())}
        >
          Add Endpoint
        </Button>
      </Stack>
    </Stack>
  )
}
