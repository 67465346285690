import Button from '@material-ui/core/Button'
import { createPortal } from 'react-dom'
import { styled } from '@mui/material/styles'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useElementById } from './useElementById'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const getFilesName = (files) => {
  if (files) {
    return Array.from(files)
      .map((file) => file.name)
      .join()
  }
  return ''
}

export const DataStage = ({ onChange, files, actionId, onClose, onNext }) => {
  const actionElement = useElementById(actionId)

  return (
    <div>
      <div>{getFilesName(files)}</div>
      <Button
        component="label"
        role={undefined}
        tabIndex={-1}
        variant="outlined"
        startIcon={<CloudUploadIcon />}
      >
        Upload .csv
        <VisuallyHiddenInput
          type="file"
          onChange={(event) => onChange(event.target.files)}
        />
      </Button>

      {actionElement &&
        createPortal(
          <>
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!files}
              onClick={onNext}
            >
              Next
            </Button>
          </>,
          actionElement
        )}
    </div>
  )
}
