import { useTheme } from '@material-ui/core/styles'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts'
import Title from '../Title'

const CustomizedLabel = (props) => {
  const { x, y, value } = props
  return (
    <text
      x={x}
      y={y}
      dy={-4}
      fill={'#3F51B5'}
      fontSize={10}
      textAnchor="middle"
    >
      {value}
    </text>
  )
}

export const MultiLineChart = ({ title, label, data, lines, domain }) => {
  const theme = useTheme()
  return (
    <>
      <Title>{title}</Title>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="date" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary} domain={domain}>
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
            >
              {label}
            </Label>
          </YAxis>
          <Tooltip />
          <Legend iconType="rect" />
          {lines.map((line) => (
            <Line
              type="monotone"
              key={line.key}
              dataKey={line.key}
              stroke={line.color}
              dot={
                line.dot?.() ?? {
                  stroke: line.color,
                  strokeWidth: 2,
                  fill: line.color,
                }
              }
              isAnimationActive={false}
              label={<CustomizedLabel />}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}
