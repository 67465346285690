import { useState } from 'react'
import { notifyEmail } from './notifyEmail'
import { notifySMS } from './notfiySMS'

export const useNotificationModal = () => {
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const [notificationModalMessage, setNotificationModalMessage] = useState('')

  const handleNotificationModal = (data) => {
    if (data.message === 'Success') {
      setNotificationModalMessage('Your notification has been sent!')
    } else if (data.errors) {
      setNotificationModalMessage('Missing parameters')
    } else {
      setNotificationModalMessage(data.message)
    }
    setShowNotificationModal(true)
  }

  const sendSMS = async (number, name) => {
    const data = await notifySMS(number, name)
    handleNotificationModal(data)
  }

  const sendEmail = async (email, name) => {
    const data = await notifyEmail(email, name)
    handleNotificationModal(data)
  }

  const onClose = () => {
    setShowNotificationModal(false)
    setNotificationModalMessage('')
  }

  return {
    showNotificationModal,
    notificationModalMessage,
    sendSMS,
    sendEmail,
    onClose,
  }
}
