export const manufacturers = [
  { value: 'transtek', label: 'Transtek' },
  { value: 'bodyTrace', label: 'BodyTrace' },
  { value: 'berryMed', label: 'BerryMed' },
  { value: 'withings', label: 'Withings' },
  { value: 'ad', label: 'A&D' },
  { value: 'other', label: 'Other' },
]

export const manufacturersMap = new Map(
  manufacturers.map(({ value, label }) => [value, label])
)
