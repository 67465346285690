export const setFormThresholds = (methods, threshold) => {
  methods.setValue('bloodPressure', threshold.bloodPressure, {
    shouldValidate: true,
  })
  methods.setValue('bloodGlucose', threshold.bloodGlucose, {
    shouldValidate: true,
  })
  methods.setValue('weight', threshold.weight, {
    shouldValidate: true,
  })
  methods.setValue('pulseOximeter', threshold.pulseOximeter, {
    shouldValidate: true,
  })
}
