import { ERROR_STATUS, SAVED_STATUS, SKIPPED_STATUS } from '../constants'

const mapSaveStatusToDataItem = (id, resultStatusMap) => {
  console.log('resultStatusMap', resultStatusMap)
  const successfulMap = resultStatusMap.get('successful')
  const errorMap = resultStatusMap.get('error')

  if (successfulMap?.has(id)) {
    return SAVED_STATUS
  }

  if (errorMap?.has(id)) {
    return ERROR_STATUS
  }

  return SKIPPED_STATUS
}

export const saveData = (result) => (data) => {
  try {
    const initMap = new Map([
      ['successful', new Set()],
      ['error', new Set()],
    ])
    const resultStatusMap = result.reduce((resultMap, value) => {
      const statusSet = resultMap.get(value.status)
      if (statusSet) {
        statusSet.add(value.id)
      }
      return resultMap
    }, initMap)

    return data.map((item) => ({
      ...item,
      status: mapSaveStatusToDataItem(item.id, resultStatusMap),
    }))
  } catch (err) {
    console.error(err)
    return []
  }
}
