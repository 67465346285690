export const bulkCreateDevices = async (bulkCreateDevicesRequest) => {
  const response = await fetch('/routes/devices/bulk', {
    withCredentials: true,
    method: 'POST',
    body: JSON.stringify(bulkCreateDevicesRequest),
    headers: { 'Content-Type': 'application/json' },
  })

  if (response.ok) {
    return response.json()
  } else {
    const { message } = await response.json()
    throw Error(message || 'Unknown error')
  }
}
