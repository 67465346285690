import { PatientDataGrid } from '../PatientDataGrid'
import { dateTimeColumns, commonColumnProps } from '../PatientDataGridColumns'

const getColumns = (weightUnit) => [
  ...dateTimeColumns,
  {
    ...commonColumnProps,
    field: 'weight',
    headerName: `Weight (${weightUnit === 'Lbs' ? 'lbs' : 'kgs'})`,
    type: 'number',
  },
]

export const WeightScaleGrid = ({ gridData = [], weightUnit }) => {
  return (
    <PatientDataGrid gridData={gridData} columns={getColumns(weightUnit)} />
  )
}
