export const adminAddPatient = async (data, adminID) => {
  const inputValue = {
    patientFirstName: data.firstName,
    patientLastName: data.lastName,
    patientMRN: data.mrn,
    patientEmail: data.email,
    patientPhoneNumber: data.phoneNumber,
    patientCellNumber: data.cellNumber,
    patientCity: data.city,
    patientState: data.state,
    patientAddress: data.address,
    patientZip: data.zip,
    patientTimeZone: data.timezone,
    patientBPIMEI: data.bpImei,
    patientTTBpIMEI: data.transtekBpImei,
    patientAdBpIMEI: data.adBpImei,
    patientWeightIMEI: data.weightImei,
    patientTTWeightIMEI: data.transtekWeightImei,
    patientGlucoseIMEI: data.glucoseImei,
    patientPulseIMEI: data.pulseImei,
    patientClinic: data.clinic,
    selectedBpDevice: data.selectedBpDevice,
    selectedWeightDevice: data.selectedWeightDevice,
    adminID,
  }

  return fetch('/routes/users/addPatient', {
    method: 'POST',
    body: JSON.stringify(inputValue),
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      return {
        error: 'error',
        status: response.status,
        statusText: response.statusText,
      }
    })
    .catch((error) => {
      console.error(error)
      return { error }
    })
}
