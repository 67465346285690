import { useState } from 'react'
import Button from '@material-ui/core/Button'
import Modal from 'react-bootstrap/Modal'
import TextField from '@material-ui/core/TextField'
import { useStyles } from './style'
import { ClinicSelect } from './ClinicSelect'

export const AddProvider = ({
  adminID,
  clinics,
  open,
  handleClose,
  setProviders,
}) => {
  const [providerFirstName, setProviderFirstName] = useState('')
  const [providerLastName, setProviderLastName] = useState('')
  const [providerEmail, setProviderEmail] = useState('')
  const [providerPhoneNumber, setProviderPhoneNumber] = useState('')
  const [providerUsername, setProviderUsername] = useState('')
  const [providerPassword, setProviderPassword] = useState('')
  const [providerClinic, setProviderClinic] = useState('')
  const [serverMessage, setServerMessage] = useState('')

  const classes = useStyles()

  const password_check = (pass) => {
    var regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    if (regex.exec(pass) == null) {
      return false
    } else {
      return true
    }
  }

  const addProvider = () => {
    if (password_check(providerPassword)) {
      var inputValue = {
        providerUsername: providerUsername.toLowerCase(),
        providerFirstName: providerFirstName,
        providerLastName: providerLastName,
        providerPhoneNumber: providerPhoneNumber,
        providerEmail: providerEmail,
        password: providerPassword,
        providerClinic: providerClinic,
        adminID: adminID,
      }
      fetch('/routes/users/addProvider', {
        method: 'POST',
        body: JSON.stringify(inputValue),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => {
          if (response.status !== 201) {
            return setServerMessage(
              'There has been an error. Please try again.'
            )
          } else {
            return response.json()
          }
        })
        .then((data) => {
          if (data.message === 'Success') {
            setProviders((prevProviders) => {
              prevProviders.push(data.provider)
              return [...prevProviders]
            })
            handleClose()
            setServerMessage('')
          }
        })
        .catch((error) => {
          setServerMessage('There has been an error. Please try again.')
          console.log(error)
        })
    } else {
      setServerMessage(
        'Password must contain 8 characters, an uppercase, a lowercase, a number, and a special character'
      )
    }
  }

  return (
    <Modal
      style={{ marginTop: '50px', marginLeft: '75px', width: '75%' }}
      show={open}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Provider</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            id="first-name"
            label="First Name"
            name="first-name"
            autoFocus
            onChange={(event) => setProviderFirstName(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="last-name"
            label="Last Name"
            id="last-name"
            onChange={(event) => setProviderLastName(event.target.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            onChange={(event) => setProviderEmail(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="number"
            label="Phone Number"
            id="number"
            onChange={(event) => setProviderPhoneNumber(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="username"
            label="Username"
            id="username"
            onChange={(event) => setProviderUsername(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="password"
            label="Password"
            type="password"
            onChange={(event) => setProviderPassword(event.target.value)}
          />
          <ClinicSelect
            clinic={providerClinic}
            setClinic={setProviderClinic}
            clinics={clinics}
          />
          <p>{serverMessage}</p>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={addProvider}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
