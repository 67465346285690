import moment from 'moment-timezone'

export const commonColumnProps = {
  aggregable: false,
  groupable: false,
  resizable: false,
  hideable: false,
  align: 'left',
  headerAlign: 'left',
  width: 150,
}

export const dateTimeColumns = [
  {
    ...commonColumnProps,
    field: 'date',
    headerName: 'Date',
    type: 'date',
    valueFormatter: (params) => moment(params.value).format('MM/DD/YY'),
  },
  {
    ...commonColumnProps,
    field: 'time',
    headerName: 'Time',
    type: 'string',
  },
]
