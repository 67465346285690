export const sendPasswordResetLink = async (email) => {
  const inputValue = {
    email,
  }
  return await fetch('/routes/users/sendLink', {
    method: 'POST',
    body: JSON.stringify(inputValue),
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error(error)
      return
    })
}
