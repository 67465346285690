import { DataGrid } from '@mui/x-data-grid'
import { getLanguage } from '../../../locale/getLocale'
import { formatForwardResult } from '../formatForwardResult'
import { dateTransform } from '../../../device/utils/dateTransform'
import Typography from '@material-ui/core/Typography'
import Stack from '@mui/material/Stack'

const PAGE_SIZE = 10
const getRowId = (row) => row._id

const columns = [
  {
    field: 'imei',
    headerName: 'IMEI',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Time',
    flex: 1,
    type: 'datetime',
    valueFormatter: ({ value }) =>
      value
        ? new Intl.DateTimeFormat(getLanguage(), {
            dateStyle: 'short',
            timeStyle: 'medium',
          }).format(dateTransform.input(value))
        : value,
    filterable: false,
    sortable: false,
  },
  {
    field: 'forwardResults',
    headerName: 'Forward Results',
    flex: 1,
    valueFormatter: ({ value }) =>
      value
        ? value.map((result) => formatForwardResult(result)).join('; ')
        : value,
    valueGetter: ({ row }) => row?.forwardResults,
    filterable: false,
    sortable: false,
  },
]

export const TestReport = ({ testReport = {} }) => {
  return (
    <Stack spacing={2}>
      <Typography color="inherit" noWrap>
        Started at:{' '}
        {new Intl.DateTimeFormat(getLanguage(), {
          dateStyle: 'short',
          timeStyle: 'short',
        }).format(dateTransform.input(testReport.createdAt))}
      </Typography>
      <DataGrid
        sx={{
          '--DataGrid-overlayHeight': '300px',
        }} // rewrite boostrap styles.
        rows={testReport?.messages || []}
        columns={columns}
        disableRowSelectionOnClick
        getRowId={getRowId}
        autoHeight
        initialState={{
          pagination: { paginationModel: { pageSize: PAGE_SIZE } },
        }}
        pageSizeOptions={[PAGE_SIZE]}
        disableColumnFilter
        disableColumnResize
        disableColumnSorting
      />
    </Stack>
  )
}
