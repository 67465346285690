export const testEndpointStatusMessage = ({ endpoint }) =>
  fetch(`/routes/customers/testEndpointStatus`, {
    withCredentials: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(endpoint),
  }).then(async (response) => {
    if (response.ok) {
      return response.json()
    } else {
      const { message } = await response.json()
      throw Error(message || 'Server error')
    }
  })
