import { useState, useId } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import Button from '@material-ui/core/Button'
import { Dialog } from '../components/common/Dialog'
import { useStyles } from '../components/common/style'
import { PatientForm } from './PatientForm'
import { defaultFormState } from './defaultFormState'
import { adminAddPatient } from './adminAddPatient'

export const AddPatientModal = ({
  adminID,
  clinics,
  open,
  handleClose,
  setPatients,
}) => {
  const formId = useId()
  const methods = useForm({ defaultValues: defaultFormState })
  const classes = useStyles()
  const [serverMessage, setServerMessage] = useState('')

  const closeHandler = () => {
    setServerMessage('')
    handleClose()
    methods?.reset()
  }

  const onSubmit = async (data) => {
    try {
      const response = await adminAddPatient(data, adminID)
      if (response.error) {
        throw new Error(response.statusText)
      }
      if (response.message === 'Success') {
        setPatients((prevPatients) => {
          prevPatients.push(response.patient)
          return [...prevPatients]
        })
        closeHandler()
      }
    } catch (e) {
      setServerMessage(e.message || 'Service Error')
    }
  }

  return (
    <Dialog
      open={open}
      title={'Add Patient'}
      handleClose={closeHandler}
      actions={
        <>
          <Button variant="secondary" onClick={closeHandler}>
            Close
          </Button>
          <Button
            variant="primary"
            form={formId}
            type="submit"
            disabled={methods.formState.isSubmitting}
          >
            Submit
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <form
          className={classes.form}
          id={formId}
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <PatientForm addClinic={true} clinics={clinics} isLoading={false} />
        </form>
      </FormProvider>
      <p>{serverMessage}</p>
    </Dialog>
  )
}
