import { Grid, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { InputGridItem } from './InputGridItem'
import { getStageFieldNames } from './getStageFieldNames'

export const StageVitalRow = ({
  vital,
  vitalLabel,
  parameter,
  parameterLabel,
  unit,
  rowEnabled,
  transform,
  rowControl = () => {},
}) => {
  const {
    normalEnabledName,
    elevatedEnabledName,
    stageOneEnabledName,
    stageTwoEnabledName,
    crisisEnabledName,
    normalValueName,
    elevatedValueName,
    stageOneValueName,
    stageTwoValueName,
    crisisValueName,
  } = getStageFieldNames(vital, parameter)

  return (
    <Grid
      container
      item
      alignContent="center"
      alignItems="center"
      direction="row"
      spacing={1}
      xs={12}
    >
      <Grid item xs={2}>
        {vitalLabel && (
          <FormControlLabel
            value={rowEnabled}
            checked={rowEnabled}
            onChange={(e) => {
              rowControl(e.target.checked)
            }}
            control={<Checkbox />}
            label={vitalLabel}
          />
        )}
      </Grid>
      <Grid item xs={1}>
        {parameterLabel && <Typography>{parameterLabel}</Typography>}
      </Grid>
      <InputGridItem
        sign="<"
        unit={unit}
        enabledName={normalEnabledName}
        valueName={normalValueName}
        xs={true}
      />
      <InputGridItem
        sign=">="
        unit={unit}
        enabledName={elevatedEnabledName}
        valueName={elevatedValueName}
        xs={true}
      />
      <InputGridItem
        sign=">="
        unit={unit}
        enabledName={stageOneEnabledName}
        valueName={stageOneValueName}
        xs={true}
      />
      <InputGridItem
        sign=">="
        unit={unit}
        enabledName={stageTwoEnabledName}
        valueName={stageTwoValueName}
        xs={true}
      />
      <InputGridItem
        sign=">="
        unit={unit}
        enabledName={crisisEnabledName}
        valueName={crisisValueName}
        xs={true}
      />
    </Grid>
  )
}
