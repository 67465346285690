export const startDeviceTest = (id) =>
  fetch(`/routes/devices/${id}/tests`, {
    withCredentials: true,
    method: 'PUT',
    body: JSON.stringify({ start: true }),
    headers: { 'Content-Type': 'application/json' },
  }).then((response) => {
    if (response.ok) {
      return response.json()
    } else {
      throw Error('server error')
    }
  })
