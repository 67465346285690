export const deviceTypes = [
  { value: 'bloodPressure', label: 'Blood Pressure' },
  { value: 'scale', label: 'Scale' },
  { value: 'glucometer', label: 'Glucometer' },
  { value: 'oximeter', label: 'Oximeter' },
  { value: 'peakFlowMeter', label: 'Peak Flow Meter' },
  { value: 'spirometer', label: 'Spirometer' },
  { value: 'sleepTracker', label: 'Sleep Tracker' },
]

export const deviceTypesMap = new Map(
  deviceTypes.map(({ value, label }) => [value, label])
)
