import { useEffect, useState } from 'react'
import {
  CssBaseline,
  AppBar,
  Toolbar,
  CircularProgress,
} from '@material-ui/core'
import { Box, Typography, Card, CardContent, Alert } from '@mui/material'
import { Copyright } from '../../common/Copyright'
import { useStyles } from '../../common/style'
import cardiowell2 from '../../../images/cardiowell2.png'
import { sendMagicLink } from './magicLinkAPI'

const MagicLinkSentMessage = ({ phone }) => {
  const classes = useStyles()

  return (
    <Box className={classes.centered}>
      <Card className={classes.card} elevation={3}>
        <CardContent>
          <Alert severity="info">
            <Typography variant="h6" gutterBottom>
              Authentication link has been sent to {phone}
            </Typography>
          </Alert>
          <Typography variant="body2" color="textSecondary">
            Follow the link in the message to authenticate your device.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}

const LoadingDisplay = () => {
  const classes = useStyles()
  return (
    <Box className={classes.centered}>
      <CircularProgress />
    </Box>
  )
}

export const MagicLinkAuthPage = (props) => {
  const [phone, setPhone] = useState(undefined)

  const imei = props.match.params.imei
  const classes = useStyles()

  useEffect(() => {
    sendMagicLink(imei).then((response) => {
      setPhone(response.phone)
    })
  }, [imei])

  return (
    <main className={classes.content}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Patient Auth
          </Typography>
          <img
            style={{ width: '125px', height: '25px' }}
            src={cardiowell2}
            alt="Default"
          />
        </Toolbar>
      </AppBar>
      <div className={classes.appBarSpacer} />
      {!phone && <LoadingDisplay />}
      {phone && <MagicLinkSentMessage phone={phone} />}
      <Box mt={8}>
        <Copyright />
      </Box>
    </main>
  )
}
