import { useState, useId, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Button } from '@material-ui/core'
import { Dialog } from '../components/common/Dialog'
import { defaultFormState } from './defaultFormState'
import { useStyles } from '../components/common/style'
import { PatientForm } from './PatientForm'

export const EditPatientModal = ({
  canDelete,
  open,
  patientId,
  patient,
  canEditClinic,
  timezoneRequired,
  clinics = [],
  handleClose = () => {},
  savePatientChanges = async () => {},
  deletePatient = async () => {},
}) => {
  const formId = useId()
  const methods = useForm({ defaultValues: defaultFormState })
  const classes = useStyles()
  const [serverMessage, setServerMessage] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const closeHandler = () => {
    setServerMessage('')
    setIsLoading(true)
    handleClose()
    methods?.reset()
  }

  const deleteHandler = async () => {
    try {
      const response = await deletePatient()
      if (response.message === 'Success') {
        closeHandler()
      } else {
        setServerMessage(
          response.message || response.statusText || 'Service Error'
        )
      }
    } catch (e) {
      setServerMessage(e.message || 'Service Error')
    }
  }

  const onSubmit = async (data) => {
    try {
      setServerMessage('Saving patient changes. Please wait...')
      const response = await savePatientChanges({ id: patientId, ...data })
      if (response.message === 'Success') {
        closeHandler()
      } else {
        setServerMessage(
          response.message || response.statusText || 'Service Error'
        )
      }
    } catch (e) {
      setServerMessage(e.message || 'Service Error')
    }
  }

  useEffect(() => {
    if (open && patientId && patient) {
      if (patient.name) {
        methods.setValue('firstName', patient.name.split(' ')[0])
        methods.setValue('lastName', patient.name.split(' ')[1])
      } else {
        methods.setValue('firstName', patient.firstName)
        methods.setValue('lastName', patient.lastName)
      }
      methods.setValue('mrn', patient.mrn || patient.MRN)
      methods.setValue('email', patient.email)
      methods.setValue('phoneNumber', patient.phoneNumber)
      methods.setValue('cellNumber', patient.cellNumber)
      methods.setValue('address', patient.address)
      methods.setValue('city', patient.city)
      methods.setValue('state', patient.state)
      methods.setValue('zip', patient.zip)
      methods.setValue('timezone', patient.timeZone)
      methods.setValue('bpImei', patient.bpIMEI)
      methods.setValue('transtekBpImei', patient.ttBpIMEI)
      methods.setValue('adBpImei', patient.adBpIMEI)
      methods.setValue('withingsBpDevice', patient.withingsBpDevices?.deviceIds)
      methods.setValue('weightImei', patient.weightIMEI)
      methods.setValue('transtekWeightImei', patient.ttWeightIMEI)
      methods.setValue('glucoseImei', patient.glucoseIMEI)
      methods.setValue('pulseImei', patient.pulseIMEI)
      methods.setValue('clinic', patient.clinic)
      if (patient.selectedBpDevice) {
        methods.setValue('selectedBpDevice', patient.selectedBpDevice)
      }
      if (patient.selectedWeightDevice) {
        methods.setValue('selectedWeightDevice', patient.selectedWeightDevice)
      }
      methods.setValue(
        'deviceNotificationsEnabled',
        !!patient.deviceNotificationsEnabled
      )
    }
    setIsLoading(false)
  }, [open, patientId])

  return (
    <Dialog
      open={open}
      title={'Edit Patient'}
      handleClose={closeHandler}
      actions={
        <>
          <Button variant="secondary" onClick={closeHandler}>
            Close
          </Button>
          {canDelete && (
            <Button variant="secondary" onClick={deleteHandler}>
              Delete
            </Button>
          )}
          <Button
            variant="primary"
            form={formId}
            type="submit"
            disabled={methods.formState.isSubmitting}
          >
            Submit
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <form
          className={classes.form}
          id={formId}
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <PatientForm
            addClinic={canEditClinic}
            clinics={clinics}
            timezoneRequired={timezoneRequired}
            isLoading={isLoading}
            showWithings={true}
          />
        </form>
      </FormProvider>
      <p>{serverMessage}</p>
    </Dialog>
  )
}
