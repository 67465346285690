export const getClinicalNotes = async ({ patientId, from }) => {
  const params = new URLSearchParams({
    from,
  })
  return await fetch(`/routes/note/patient/${patientId}?${params}`, {
    withCredentials: true,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw new Error(response.status)
    })
    .catch((error) => {
      return { error }
    })
}
