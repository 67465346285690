export const getStageFieldNames = (vital, parameter) => {
  const vitalParam = `${vital}.${parameter}`
  const normalEnabledName = `${vitalParam}.normal.enabled`
  const elevatedEnabledName = `${vitalParam}.elevated.enabled`
  const stageOneEnabledName = `${vitalParam}.stageOne.enabled`
  const stageTwoEnabledName = `${vitalParam}.stageTwo.enabled`
  const crisisEnabledName = `${vitalParam}.crisis.enabled`
  const normalValueName = `${vitalParam}.normal.value`
  const elevatedValueName = `${vitalParam}.elevated.value`
  const stageOneValueName = `${vitalParam}.stageOne.value`
  const stageTwoValueName = `${vitalParam}.stageTwo.value`
  const crisisValueName = `${vitalParam}.crisis.value`
  return {
    normalEnabledName,
    elevatedEnabledName,
    stageOneEnabledName,
    stageTwoEnabledName,
    crisisEnabledName,
    normalValueName,
    elevatedValueName,
    stageOneValueName,
    stageTwoValueName,
    crisisValueName,
  }
}
