import { useId, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { Dialog } from '../components/common/Dialog'
import { useForm, FormProvider } from 'react-hook-form'
import { CustomerForm } from './CustomerForm/CustomerForm'
import { getClinics } from './getClinics'
import { createCustomer } from './createCustomer'
import { TestEndpoint } from './TestEndpoint'
import { testEndpointStatusMessage } from './testEndpointStatusMessage'
import { testEndpointTelemetryMessage } from './testEndpointTelemetryMessage'

const ADD_MODE = 'add'
const TEST_MODE = 'test'

const getTitle = ({ mode, testEndpoint }) =>
  mode === ADD_MODE ? 'Add Customer' : `Add Customer | ${testEndpoint?.name}`

const DEFAULT_FORM_STATE = {
  name: '',
  contact: '',
  contactNumber: '',
  email: '',
  address: '',
  clinics: [],
  endpoints: [],
}

export const AddCustomerModal = ({ opend, onClose = () => {}, onSave }) => {
  const formId = useId()
  const [serverError, setServerError] = useState('')
  const [isClinicsLoaded, setClinicsLoaded] = useState(false)
  const [clinics, setClinics] = useState([])

  const [mode, setMode] = useState(ADD_MODE)
  const [testEndpoint, setTestEndpoint] = useState(null)
  const [testRequest, setTestRequest] = useState(null)
  const [testResponse, setTestResponse] = useState(null)

  const clearTestData = () => {
    setTestEndpoint(null)
    setTestRequest(null)
    setTestResponse(null)
  }

  useEffect(() => {
    if (opend) {
      getClinics().then((clinics) => {
        setClinics(clinics)
        setClinicsLoaded(true)
        setServerError('')
      })
    }
  }, [opend])

  const methods = useForm({
    defaultValues: DEFAULT_FORM_STATE,
  })

  const closeHandler = () => {
    if (mode === ADD_MODE) {
      onClose?.()
      setClinicsLoaded(false)
      setServerError('')
      methods.reset()
      methods.setValue('endpoints', [])
    } else {
      setMode(ADD_MODE)
      clearTestData(null)
    }
  }

  const onSubmit = async (data) => {
    try {
      await createCustomer(data)
      onSave?.()
      closeHandler()
    } catch (e) {
      setServerError(e.message)
    }
  }

  const handleBack = async () => {
    setTestEndpoint(null)
    setMode(ADD_MODE)
  }

  const openTestEndpoint = (endpoint) => {
    setTestEndpoint(endpoint)
    setMode(TEST_MODE)
  }

  const handleSendStatus = async () => {
    try {
      const { request, response } = await testEndpointStatusMessage({
        endpoint: testEndpoint,
      })
      setTestRequest(request)
      setTestResponse(response)
      setServerError(null)
    } catch (e) {
      setServerError(e.message)
    }
  }

  const handleSendTelemetry = async () => {
    try {
      const { request, response } = await testEndpointTelemetryMessage({
        endpoint: testEndpoint,
      })
      setTestRequest(request)
      setTestResponse(response)
      setServerError(null)
    } catch (e) {
      setServerError(e.message)
    }
  }

  return (
    <Dialog
      open={opend}
      title={getTitle({ mode, testEndpoint })}
      handleClose={closeHandler}
      actions={
        mode === ADD_MODE ? (
          <>
            <Button variant="secondary" onClick={closeHandler}>
              Close
            </Button>
            <Button
              disabled={!isClinicsLoaded || methods.formState.isSubmitting}
              form={formId}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleBack} variant="primary">
              Back
            </Button>
            <Button onClick={handleSendStatus} variant="primary">
              Send Status
            </Button>
            <Button onClick={handleSendTelemetry} variant="primary">
              Send Telemetry
            </Button>
          </>
        )
      }
    >
      {mode === ADD_MODE ? (
        <FormProvider {...methods}>
          <form
            id={formId}
            noValidate
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <CustomerForm
              clinics={clinics}
              serverError={serverError}
              isClinicsLoaded={isClinicsLoaded}
              onTestEndpoint={openTestEndpoint}
            />
          </form>
        </FormProvider>
      ) : (
        <TestEndpoint
          endpoint={testEndpoint}
          request={testRequest}
          response={testResponse}
          serverError={serverError}
        />
      )}
    </Dialog>
  )
}
