export const notifyEmail = async (email, name) => {
  const username = sessionStorage.getItem('user')
  const inputValue = {
    email,
    name,
    username,
    providerID: sessionStorage.getItem('providerID'),
  }
  const response = await fetch('/routes/users/notifyEmail', {
    method: 'POST',
    body: JSON.stringify(inputValue),
    headers: { 'Content-Type': 'application/json' },
  })

  if (response.ok) {
    return response.json()
  }

  const { message, errors } = await response.json()
  return { message: message || 'Unknown error', errors }
}
