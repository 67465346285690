export const createCustomer = (createCustomerRequest) =>
  fetch('/routes/customers', {
    withCredentials: true,
    method: 'POST',
    body: JSON.stringify({
      ...createCustomerRequest,
      clinics: createCustomerRequest.clinics.map(({ _id }) => _id),
    }),
    headers: { 'Content-Type': 'application/json' },
  }).then((response) => {
    if (response.ok) {
      return response.json()
    } else {
      throw Error('server error')
    }
  })
