import { Grid, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { InputGridItem } from './InputGridItem'
import { getFieldNames } from './getFieldNames'

export const VitalRow = ({
  vital,
  vitalLabel,
  parameter,
  parameterLabel,
  unit,
  rowEnabled,
  rowControl = () => {},
  transform = {
    input: (value) => value,
    output: (e) => e,
  },
}) => {
  const {
    upperHighEnabledField,
    upperMediumEnabledField,
    lowerHighEnabledField,
    lowerMediumEnabledField,
    upperHighValue,
    upperMediumValue,
    lowerHighValue,
    lowerMediumValue,
  } = getFieldNames(vital, parameter)

  return (
    <Grid
      container
      item
      alignContent="center"
      alignItems="center"
      direction="row"
      spacing={3}
      xs={12}
    >
      <Grid item xs={2}>
        {vitalLabel && (
          <FormControlLabel
            value={rowEnabled}
            checked={rowEnabled}
            onChange={(e) => {
              rowControl(e.target.checked)
            }}
            control={<Checkbox />}
            label={vitalLabel}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        {parameterLabel && <Typography>{parameterLabel}</Typography>}
      </Grid>
      <InputGridItem
        sign="<="
        unit={unit}
        enabledName={lowerMediumEnabledField}
        valueName={lowerMediumValue}
        transform={transform}
      />
      <InputGridItem
        sign=">="
        unit={unit}
        enabledName={upperMediumEnabledField}
        valueName={upperMediumValue}
        transform={transform}
      />
      <InputGridItem
        sign="<="
        unit={unit}
        enabledName={lowerHighEnabledField}
        valueName={lowerHighValue}
        transform={transform}
      />
      <InputGridItem
        sign=">="
        unit={unit}
        enabledName={upperHighEnabledField}
        valueName={upperHighValue}
        transform={transform}
      />
    </Grid>
  )
}
