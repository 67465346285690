import { useId, useEffect, useState, useRef } from 'react'
import Button from '@mui/material/Button'
import { Dialog } from '../../../components/common/Dialog'
import { useForm, FormProvider } from 'react-hook-form'
import { DeviceForm } from '../../DeviceForm'

const useUpdateEffect = (handler, deps) => {
  const isMoutedRef = useRef(null)
  useEffect(() => {
    if (!isMoutedRef.current) {
      isMoutedRef.current = true
    } else {
      return handler()
    }
  }, deps)
}

const getClinicsByCustomerId = (customers, id) => {
  const customer = customers.find(({ _id }) => _id === id)
  return customer?.clinics || []
}

const getEndpointsByCustomerId = (customers, id) => {
  const customer = customers.find(({ _id }) => _id === id)
  return customer?.endpoints || []
}

export const EditDeviceModal = ({
  id,
  device,
  customers,
  onClose = () => {},
  onSave,
}) => {
  const formId = useId()
  const [serverError, setServerError] = useState('')

  const [clinics, setClinics] = useState(
    getClinicsByCustomerId(customers, device.customer)
  )
  const [endpoints, setEndpoints] = useState(
    getEndpointsByCustomerId(customers, device.customer)
  )

  const methods = useForm({
    defaultValues: device,
  })

  const customerId = methods.watch('customer')

  useUpdateEffect(() => {
    setClinics(getClinicsByCustomerId(customers, customerId))
    setEndpoints(getEndpointsByCustomerId(customers, customerId))
  }, [customerId])

  const closeHandler = () => {
    onClose?.()
    methods.reset()
    setServerError('')
  }

  const onSubmit = async (request) => {
    try {
      onSave?.(id, request)
      closeHandler()
    } catch (e) {
      setServerError(e.toString())
    }
  }

  return (
    <Dialog
      open
      title="Edit Device"
      handleClose={closeHandler}
      actions={
        <>
          <Button variant="secondary" onClick={closeHandler}>
            Close
          </Button>
          <Button
            disabled={methods.formState.isSubmitting}
            form={formId}
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <form id={formId} noValidate onSubmit={methods.handleSubmit(onSubmit)}>
          <DeviceForm
            serverError={serverError}
            customers={customers}
            clinics={clinics}
            endpoints={endpoints}
            withIdentifier
          />
        </form>
      </FormProvider>
    </Dialog>
  )
}
