import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import cardiowell1 from '../images/cardiowell1.png'
import Modal from 'react-bootstrap/Modal'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://material-ui.com/">
        cardiowell
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function PrivacyPolicy(props) {
  const classes = useStyles()
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img style={{ width: '50%' }} src={cardiowell1}></img>
        {/* <Typography component="h1" variant="h5">
          Sign in
        </Typography> */}
        <h2>WELLADAPT INC.’S PRIVACY POLICY</h2>
        <p>Last Updated: August 12, 2020</p>
        <p>
          <span style={{ fontSize: '10pt' }}>&nbsp;</span>
        </p>
        <p>
          <strong>Our Commitment to Privacy</strong>
          <br />{' '}
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          WellAdapt Inc., (“us”, “we”, or “our”) operates the{' '}
          <a href="http://www.welladapt.com">www.welladapt.com</a>&nbsp;and
          cardiowell.io websites and the Cardiowell mobile application
          (Cardiowell App), is located in Santa Barbara, CA. (USA). This privacy
          policy applies to all software, products, and services created,
          published and provided by WellAdapt Inc., including the Cardiowell App
          associated websites.
          <br />
          <br />
          WellAdapt Inc. is committed to protecting the security and privacy of
          your personal information and as such conducts business in compliance
          with all applicable laws concerning data security and privacy.&nbsp;
          &nbsp;We will not share your information with anyone except as
          described in this Privacy Policy.
          <br />
          <br />
          WellAdapt Inc.’s products and services, including the Cardiowell App,
          can be used to track and share your own health information. WellAdapt
          Inc. provides an option to store biometric data to the WellAdapt Inc.
          data centers that are manually entered, and/or provided directly from
          supported devices, such as a blood pressure monitor or weight scale.
          The Cardiowell App also allows you to sync with Apple HealthKit so you
          can view certain Apple Health data in the Cardiowell App.&nbsp; Using
          the Cardiowell App, you are able to view the biometric data in the
          form of text and graphs. As of now, HealthKit biometric data is never
          stored on WellAdapt servers. Other data can be stored on WellAdapt
          servers for safe-keeping and remote analysis with your explicit
          authorization.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          WellAdapt Inc. does not provide healthcare services of any type. The
          Cardiowell App only allows you to aggregate biometric data for
          self-analysis and understanding.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Our Privacy policy was created to explain what information we collect,
          why we collect it, and how the information is used. We also explain
          the choices we offer, including how to access and update
          information.&nbsp; Unless otherwise defined in this Privacy Policy,
          terms used in this Privacy Policy have the same meanings as in our
          Terms of Use Agreement.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Our confidentiality policy provides you with total transparency on how
          we process your personal data.
          <br />
          <br />
          BY USING THE WELLADAPT INC WEB SITES, PRODUCTS, SERVICES AND THE
          CARDIOWELL APP, YOU APPROVE AND AGREE TO THE PRACTICES DESCRIBED IN
          OUR POLICY.
          <br />
          <br />
          If you do not find the answer to your questions in this document, feel
          free to contact us at:&nbsp;
          <a href="mailto:privacy@welladapt.com">privacy@welladapt.com</a>
        </p>
        <p> </p>
        <p>1.&nbsp;What types of data are collected?</p>
        <p>2.&nbsp;How can I maintain control of my data?</p>
        <p>3.&nbsp;What data is collected and when?</p>
        <p>4.&nbsp;How can my data be used?</p>
        <p>
          5.&nbsp;In which cases does WellAdapt Inc. share my personal data?
        </p>
        <p>6.&nbsp;For how long do you retain my personal data?</p>
        <p>7.&nbsp;Where is my personal data kept?</p>
        <p>8.&nbsp;How do you protect my data?&nbsp;&nbsp;</p>
        <p>9.&nbsp;How do I delete my Cardiowell App user account?</p>
        <p>10.&nbsp;What third-party tools do you use?</p>
        <p>
          11.&nbsp;What is the minimum age for the use of WellAdapt Inc.’s
          products and services?
        </p>
        <p>
          12.&nbsp;Can the privacy policy change?
          <br />{' '}
        </p>
        <h3>1. WHAT TYPE OF DATA ARE COLLECTED?</h3>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          The End User of WellAdapt Inc. products and services requests data to
          be collected as part of the functionality that the Cardiowell App
          provides. The data identifies you either directly or indirectly.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Data that identifies you directly may include your first name, last
          name, date of birth, and e-mail address. We may also collect data that
          indirectly identifies you such as weight, blood pressure, and zip
          code.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Our privacy policy is designed to protect your data by applying
          high-level security technology and processes.
        </p>
        <h3>
          <br />
          2. HOW CAN I MAINTAIN CONTROL OVER MY DATA?
        </h3>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          We believe you should have access to your data at anytime; to ensure
          your data remains secure and private at WellAdapt Inc, we support you
          and protect your privacy as fully as possible by guaranteeing you the
          following rights:
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <br />
          <strong>a.) Right to access your data</strong>
          <br />
          Your personal data is and shall remain yours. It must be accessible to
          you upon your request. This means that we will provide you a means to
          view your data and to export your data for you in an open format.
          <br />
          <br />
          <strong>b.) Right to object to your data being processed</strong>
          <br />
          You may object to your personal data being processed and aggregated by
          contacting our customer service department. Please know that this
          might limit the scope of WellAdapt Inc.’s. products and services.
          <br />
          <br />
          <strong>c.) Right to protect your data</strong>
          <br />
          We use various methods to safeguard your data.&nbsp;
          <a href="#howdoweprotectyourdata">How do we protect your data?</a>
          <br />
          <br />
          First, you need to have a password-protected account to access your
          data.
          <br />
          Second, all data stored in our cloud database is encrypted.
          <br />
          Third, we de-identify all data used for statistical analysis and
          research.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <br />
          <strong>d.) Right to control your data sharing</strong>
          <br />
          The Cardiowell App provides a sharing option that allows you to
          control with whom you have shared your data. This option allows you to
          begin and end sharing your data with WellAdapt Inc. or Apple HealthKit
          at any time. For your added protection we currently do not allow
          sharing with any third party apps other than Apple Health. WellAdapt
          Inc. does not sell, rent, share, or disclose personal information or
          biometric data with third parties. The system is not designed to
          enable access by physicians and/or third parties to your personal
          information.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>e.) Right to be forgotten: Deletion of your account</strong>
          <br />
          At WellAdapt Inc. we give you the right to remove data that identifies
          you directly by deleting your account for you.&nbsp; Contact us
          anytime to have your account deleted
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>f.) Right to update your information</strong>
          <br />
          At any time you can update your personal information within the App.
          If you have registered or created an account, you may view, update, or
          delete certain personal information through that account. Please note
          that we reserve the right to retain personal information relating to
          you for a period of time as reasonably necessary to document our
          business activities, comply with applicable laws or regulations or as
          needed for required disclosures. If you wish to edit personal
          information but are unable to do so by visiting your account, please
          contact the WellAdapt Inc. customer service department. &nbsp;
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>&nbsp;</strong>
        </p>
        <h3>3. WHAT DATA IS COLLECTED AND WHEN?</h3>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          WellAdapt Inc. products and services, such as the Cardiowell App, do
          not share any data with third-party companies without your explicit
          permission. The Cardiowell App does collect data when used. This is a
          list and explanation of the data collected with your permission.
          &nbsp;
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          We receive data:
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          a.)&nbsp;When you visit and use our websites and services
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          b.)&nbsp;When you create a Cardiowell App account
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          c.) When you use the Cardiowell App.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          d.) When you use a WellAdapt Inc. Product
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          e.)&nbsp;When you share data from Apple HealthKit
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          f.)&nbsp;When you share data with WellAdapt Inc.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          g.)&nbsp;When you contact our customer service department
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>&nbsp;</strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>a.) When you visit and use our websites and services</strong>
          <br />
          When you use our website, take or reply to our questionnaires, we
          collect certain information even if you do not have a Cardiowell App
          account. This happens when you complete surveys, write a comment,
          share on a social network, order a product, etc.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          When ordering Products and Services from our website, we collect
          information about your order such as mailing and billing address.
          However, WellAdapt Inc. will never have access or store your bank
          details as payment transactions are conducted and secured by our
          partners.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          We may also collect information that your browser sends whenever you
          use the Cardiowell App, use our service or visit our websites by or
          through a mobile device or PC. ("Log Data").
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          This Log Data may include information such as your computer's Internet
          Protocol ("IP") address, browser type, browser version, the pages of
          our Service that you visit, the time and date of your visit, the time
          spent on those pages and other statistics.&nbsp;
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          When you access the Service by or through a mobile device, this Log
          Data may include information such as the type of mobile device you
          use, your mobile device unique ID, the IP address of your mobile
          device, your mobile operating system, the type of mobile Internet
          browser you use and other statistics. It can also include crash
          reports and logs related to the function or malfunction of the
          Cardiowell App.
          <br />
          <br />
          WellAdapt Inc. analyses web traffic data and information gathered
          using cookies, web beacons and mobile device data to help better
          understand who is using the website and/or apps and how they are being
          used. This helps to identify patterns and trends in usage to allow
          WellAdapt Inc. to better meet user needs. “Cookies” are small files
          placed on your hard drive when you visit a web page that help to a)
          analyze page flow, b.) customize services, content and advertising, c)
          measure promotional effectiveness d.) promote trust and safety and e.)
          store user preferences and other information.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          You can always disable cookies on your browser.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          We do not support Do Not Track ("DNT"). Do Not Track is a preference
          you can set in your web browser to inform websites that you do not
          want to be tracked. You can enable or disable Do Not Track by visiting
          the Preferences or Settings page of your web browser.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          This is the data that you may communicate to us by visiting and using
          our sites and services:
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          1.
          <span style={{ fontSize: '7pt' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Identity Data
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          2.
          <span style={{ fontSize: '7pt' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Cookies
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          3.
          <span style={{ fontSize: '7pt' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Log Data
          <br />{' '}
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>b.) When you create a Cardiowell App account</strong>
          <br />
          Creating a Cardiowell App End User account allows you to use the App
          and may allow you to use WellAdapt Inc.’s Products and Services. A
          unique user account will be created requiring us to collect certain
          identifiers about you. We will, therefore, collect any information you
          provide to identify and register yourself as a Cardiowell App user.
          This data may include demographic-type data including your name, user
          name and contact information. The Cardiowell App user account allows
          you to access and control your data. Data is collected when you first
          create an account and every time you login and use the Cardiowell App.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          This is the data you may communicate to us when you create a
          Cardiowell user account:
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          1.
          <span style={{ fontSize: '7pt' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Identity Data
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <br />
          <strong>c.) When you use </strong>
          <strong>the Cardiowell App</strong>
          <br />
          Using the Cardiowell App requires a user account. To create and
          maintain a user account certain data is shared with us when you
          download, install and use the Cardiowell App. This is also the case
          when you set a notification, reminder, share information, or respond
          to questionnaires.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          This is the data that you may communicate to us by using our
          applications:
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          1.
          <span style={{ fontSize: '7pt' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Identity Data
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          2.
          <span style={{ fontSize: '7pt' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Body Metrics Data
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          <span style={{ fontSize: '10pt' }}>&nbsp;</span>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>d.) When you Use a WellAdapt Inc. product</strong>
          <br />
          WellAdapt’s products, services and applications such as the Cardiowell
          App collect data, and enable biometric monitoring. When you activate a
          WellAdapt product, you will need to download the Cardiowell App and
          create an account.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}> </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          When used, the device’s serial number and your biometric measurement
          (i.e. weight, pulse, blood pressure) data are automatically sent from
          supported devices to the WellAdapt Inc. data center whenever you use
          the device. No user identification information is sent. Cardiowell App
          uses the information to show measurement history and graphs.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          This is the data that you may communicate to us by using our products:
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          1.
          <span style={{ fontSize: '7pt' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Body Metric Data
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          2.
          <span style={{ fontSize: '7pt' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Device Serial Number
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}> </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>e.) When you share data from Apple HealthKit</strong>
          <br />
          You have the option to share Apple HealthKit data with the Cardiowell
          App. Sharing with Apple HealthKit allows Cardiowell App to display
          certain biometric data in the Cardiowell App. The user must explicitly
          grant permission to read and write data from Apple HealthKit. Users
          can grant or deny permission separately for each type of data. No
          Apple HealthKit data is stored on WellAdapt Inc servers.
        </p>
        <p>Sharing can be turned off by the user at anytime.</p>
        <p>
          The Cardiowell app is using the Apple HealthKit framework, and will
          NOT store any users’ health information in iCloud.
        </p>
        <p>
          This app will NOT use user data gathered from the Apple HealthKit API
          for advertising or other use-based data mining purposes other than
          improving health, medical, and fitness management, or for the purpose
          of medical research.
        </p>
        <p>
          This app will NOT share user data acquired via the HealthKit API with
          third parties without user consent.
          <br />{' '}
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          When you choose to connect and share your Apple HealthKit with the
          Cardiowell App, your Apple HealthKit information will be displayed in
          the Cardiowell App. The information you share from Apple HealthKit is
          then governed by the Apple Terms and Conditions and Privacy Policy.
          The unique information you choose to send from Apple HealthKit is not
          automatically made available to WellAdapt Inc., or any third-party
          affiliates. The data resides only on your iOS device and its backups.
          Apple HealthKit data is never used by WellAdapt Inc. for marketing and
          advertising or transferred by WellAdapt Inc. to third-parties for
          marketing and advertising.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          This is the data that you may share from Apple HealthKit when you use
          the Cardiowell App:
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          <br />- We do not transfer any AppleHealth Kit data to WellAdapt Inc
          servers or any third parties.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}> </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>f.) When you share the data with WellAdapt Inc.</strong>
          <br />
          When you share data with WellAdapt Inc., some of your biometric data
          may be sent automatically and securely to our cloud-based data servers
          so that you can access the data online, sync your data with your other
          devices, and share the data with WellAdapt Inc. health coaches.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          This is the data you may communicate to us when you share your data
          with WellAdapt Inc.
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          1.
          <span style={{ fontSize: '7pt' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Body Metrics Data
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          2.
          <span style={{ fontSize: '7pt' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Date of Birth
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}> </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>g.) When you contact our customer service department</strong>
          <br />
          As part of our customer service, we collect information about you.
          &nbsp;Customer support information remains confidential whenever you
          contact us or visit our Web site.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          To better support you, some of your data is shared with our teams
          until we solve the problem. Our teams ensure your data is secure and
          comply with our confidentiality policy.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          This is the data you may communicate to us when you reach our customer
          service department:
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          1.
          <span style={{ fontSize: '7pt' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Identity Data
        </p>
        <p style={{ marginLeft: '0.5in', marginRight: '0in' }}>
          2.
          <span style={{ fontSize: '7pt' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Body Metrics Data
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <span style={{ fontSize: '10pt' }}>&nbsp;</span>
        </p>
        <h3>4. HOW CAN MY DATA BE USED?</h3>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>a.) To display your data to you</strong>
          <br />
          The data is displayed through the Cardiowell App in the form of graphs
          and historical trends. Data may be in raw format (weight, pulse,
          etc.), or averaged over time.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>
            b.) To improve WellAdapt Inc. product and service offerings
          </strong>
          <br />
          In order to improve our products and services and ensure the
          availability of our platform, in accordance with Internet standards,
          we retain a record of operations conducted, in log form.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          We never work with your data when it identifies you unless you have
          given us your consent; for example, in order to resolve a problem that
          you have pointed out to us when you contact our customer service
          department.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>c.) To communicate information to you</strong>
          <br />
          We may use certain data in order to offer surveys, news, tips,
          promotions and other types of direct marketing and communications,
          such as new product and service offerings, at your discretion.
          Information can be communicated by email, and within the Cardiowell
          App. &nbsp;
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          If you do not wish to receive such information, please opt out by
          emailing{' '}
          <a href="mailto:contact@cardiowell.io">privacy@welladapt.com</a>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>
            d.) To produce statistics and/or aggregated data analyses
          </strong>
          <br />
          We will never sell your personal data without your permission. At
          WellAdapt Inc., we whole-heartedly believe that data can serve the
          common good. We may create statistics and analysis using the data we
          collect. When conducted, all data is first anonymized to assure your
          privacy is protected.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Data anonymized and/or aggregated in this way are used to:
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>
            1. Improve Cardiowell App products and services so that we:
          </strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          - Can better understand how our products and services are used and can
          be improved.
          <br />- Can improve the user experience of visitors on our websites
          and applications.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>2. Demonstrate the effectiveness of our solutions:</strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          - We participate in scientific studies to document benefits.
          <br />- We communicate through blogs, advertising or the press, to
          educate the public about the benefits of the products and solutions.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>3. Educate the public on health issues:</strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          - We publish websites and charts to present statistics and analyses.
          <br />- We may generate studies, analyses and general statistical
          analysis for the public, for companies or universities. We may at
          times produce studies on digital health and new applications (e.g., a
          research paper on effectiveness).
          <br />{' '}
        </p>
        <h3>5. IN WHICH CASES DOES WELLADAPT INC. SHARE MY PERSONAL DATA?</h3>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          WellAdapt Inc. never shares your data without your authorization. Data
          can only be shared between the Cardiowell App and Apple HealthKit and
          WellAdapt Inc. In each case, only with your explicit authorization. It
          is essential for us to gain your prior agreement when data that can
          identify you is shared.
        </p>
        <p>
          <strong>a.) When it is on your own initiative</strong>
          <br />
          You can decide to share your data with Apple HealthKit
          <br />
          You can decide to share your data with WellAdapt Inc.
        </p>
        <p>
          <strong>
            b.) When sharing your data is necessary to provide you with certain
            services
          </strong>
          <br />
          Some services require the involvement of other companies. This is the
          case, for example, for online payments on our www.welladapt.com Web
          site. In order for this service to work, we have to transmit certain
          data to our partners that allows you to be identified in order to
          process a secure payment.
        </p>
        <p>
          <strong>c.) When we are obliged by law</strong>
          <br />
          We may disclose Personal Information in the good faith belief that we
          are required to do so by law. We fully cooperate with any law
          enforcement authority or court order requesting or directing us to
          disclose the identity, behavior or digital content of any user
          suspected to have engaged in illegal or infringing behavior. Certain
          laws, regulations, administrative or court rulings may compel us to
          communicate certain personal data to a third-party. Except when this
          is prohibited by law, we shall inform you as soon as possible if we
          have to transmit some of your data.
        </p>
        <p>
          <strong>d.) Business Transaction</strong>
          <br />
          If WellAdapt Inc is involved in a merger, acquisition or asset sale,
          your Personal Information may be transferred as a business asset. In
          such cases, we will provide notice before your Personal Information is
          transferred and/or becomes subject to a different Privacy Policy.
          <br />{' '}
        </p>
        <h3>
          <br />
          6. FOR HOW LONG DO YOU RETAIN MY PERSONAL DATA?
        </h3>
        <p>We retain all data until the time your user account is deleted.</p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}> </p>
        <h3>7. WHERE IS MY PERSONAL DATA KEPT?</h3>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          WellAdapt Inc. has implemented and maintains reasonable security
          procedures and practices designed to protect against the unauthorized
          access, use, modification, destruction or disclosure of your Personal
          Information.
        </p>
        <p>
          Data is stored locally on your iOS device and upon your authorization
          limited data can be stored on secure database servers hosted by
          Database-as-a-Service partner, Object Labs Corporation maker of
          mLAB.com.&nbsp; Cardiowell also uses Heroku Inc., which is a
          third-party cloud based data storage and management company.
          &nbsp;WellAdapt Inc. may also use other services provided by Object
          Labs and Heroku such as Push Notifications, Debugging Features or
          Analytics.&nbsp; All data is backed up and encrypted to preserve and
          secure your information. The Heroku servers are throughout the country
          and are subject to regulations that guarantee a high level of
          protection.
          <br />
          <br />
        </p>
        <p>
          Heroku Terms of Service: https://www.heroku.com/policy/tos; and
          <br />
          Heroku Privacy Policy: https://www.heroku.com/policy/security
          <br />
          mLAb Terms of Service: https://mlab.com/company/legal/tos/
          <br />
          mLAB Privacy Policy: https://mlab.com/company/legal/privacy/
        </p>
        <p>
          Heroku’s physical infrastructure is hosted and managed within Amazon’s
          secure data centers and utilize the Amazon Web Service (AWS)
          technology. Amazon continually manages risk and undergoes recurring
          assessments to ensure compliance with industry standards.
        </p>
        <p>Amazon’s data center operations have been accredited under:</p>
        <p>
          ISO 27001
          <br />
          SOC 1 and SOC 2/SSAE 16/ISAE 3402 (Previously SAS 70 Type II)
          <br />
          PCI Level 1<br />
          FISMA Moderate
          <br />
          Sarbanes-Oxley (SOX)
        </p>
        <p>
          Heroku provides a high level of data security, including vulnerability
          management, backups, disaster recovery and redundancy.
        </p>
        <p>
          However, as you probably know, third-parties may unlawfully intercept
          or access transmissions or private communications, and other users may
          abuse or misuse your personal information that they collect from the
          site. Therefore, although we work very hard to protect your privacy,
          we do not promise, and you should not expect, that your personal
          information or private communications will always remain private.
        </p>
        <h3>8. HOW DO YOU PROTECT MY DATA?</h3>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          WellAdapt Inc. has put in place security measures in an effort to
          protect personal information from loss, misuse, or alterations while
          it is under our control. Your data is stored on industry-compliant
          cloud servers located within the Amazon Inc. hosting facilities.
          Amazon uses technical, contractual, administrative and physical
          measures in an effort to protect against unauthorized access. These
          include secure servers, SSL and encryption. The hosting facilities are
          equipped with the latest security technologies and advanced security
          technologies and procedures. Data is restricted using security
          controls consisting of security staff, security doors and biometric
          readers. &nbsp;Remote access to the servers is highly restricted and
          controlled.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          To allow you to securely view your account and registration
          information you will be required to submit a username and password
          upon registration and log into the application.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>&nbsp;</strong>
        </p>
        <h3>9. HOW DO I DELETE MY CARDIOWELL APP USER ACCOUNT?</h3>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          To delete your account, please contact our customer service
          department.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Data that identifies you directly will be deleted from our production
          servers. However, data may be retained within the security backups
          that we regularly make. In order to ensure free access to your data,
          we shall give you the option of retaining your data locally before
          deleting your account. This will be done following a secured
          procedure.
        </p>
        <h3>
          <br />
          10. WHAT THIRD-PARTY TOOLS DO YOU USE?
        </h3>
        <p>
          Other than the use of Heroku and Object Labs Corp. for application
          managmenet and database storage we use Square Space for web site
          hosting and Heap for user analytics.&nbsp; Heap allows us to track
          bugs, crashes, and user behavior to better understand issues and
          improve user experience.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>&nbsp;</p>
        <h3>
          11. WHAT IS THE MINIMUM AGE FOR THE USE OF WELLADAPT INC. PRODUCTS AND
          SERVICES?
        </h3>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          All users must be over the age of thirteen (13) to create an account
          and use our products and services.&nbsp; Our Service does not address
          anyone under the age of 13 ("Children").
          <br />
          <br />
          All individuals under the age of 13 must have a parent's or legal
          guardian's consent by contacting our customer service department. We
          do not knowingly collect personally identifiable information from
          children under 13. If you are a parent or guardian and you learn that
          your Children have provided us with Personal Information, please
          contact us. If we become aware that we have collected Personal
          Information from a child under age 13 without verification of parental
          consent, we take steps to remove that information from our servers.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}> </p>
        <h3>12. CAN THE PRIVACY POLICY CHANGE?</h3>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          We may change our privacy policy to adapt to your needs, to the
          evolution of the legal framework or when we develop our products and
          services. We shall inform you of any modification performed by making
          changes indicating at the top of the privacy statement the date it was
          last updated. We will make attempts to update you of any changes in
          the Policy.&nbsp; But know that this Policy may be changed at any time
          without prior notice to any visitors/users/customers. So please check
          this policy periodically for any changes. &nbsp;&nbsp;
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Your continued use of the Service after we post any modifications to
          the Privacy Policy on this page will constitute your acknowledgment of
          the modifications and your consent to abide and be bound by the
          modified Privacy Policy.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}> </p>
        <h3>APPLICABLE LAW</h3>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          By visiting and using WellAdapt Inc. products, applications and
          services, you agree that the laws of the state of California, without
          regard to principles of conflict of laws, will govern any dispute of
          any sort that might arise between you and WellAdapt Inc. If you are
          located outside of the United States and choose to provide your
          information, your information will be transferred to the United States
          and processed there.
        </p>
        <h3>
          <br />
          YOUR CONSENT
        </h3>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          By using WellAdapt Inc. Product, Applications and Services, you are
          consenting to our processing of user-provided and automatically
          collected information as set forth in this Privacy policy now, and as
          amended by us. "Processing," means using cookies on a
          computer/hand-held device or using information in any way, including,
          but not limited to, collecting, storing, deleting, using, aggregating
          and disclosing information, all of which activities will take place in
          the United States. By using our products and providing information to
          us, you consent to such transfer to, and processing in, the US.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}> </p>
        <h3>DISCLAIMER OF WARRANTIES &amp; LIMITATION OF LIABILITY</h3>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          All information, content, materials, products (including applications)
          and services included on or otherwise made available to you through
          the Cardiowell App, Cardiowell Web sites and WellAdapt Inc. products
          and services are provided by WellAdapt Inc. on an “as is” and “as
          available” basis, unless otherwise specified in writing. WellAdapt
          Inc. makes no representations or warranties of any kind, express or
          implied, as to the operation of Web sites, or the information,
          content, materials, products (including Cardiowell App) or services
          included on or otherwise made available to you by WellAdapt Inc.,
          unless otherwise specified in writing. You expressly agree that your
          use of the Cardiowell App and other WellAdapt Inc. products and
          services, including the websites is at your sole risk.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          To the full extent permissible by applicable law, WellAdapt Inc.
          disclaims all warranties, express or implied, including, but not
          limited to, implied warranties of merchantability and health for a
          particular purpose. WellAdapt Inc. does not warrant that the
          Cardiowell App, or Web sites; information, content, materials,
          products (including software) or services included on or otherwise
          made available to you through this site; their servers; or e-mail sent
          from WellAdapt Inc., are free of viruses or other harmful components.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <br />
          WellAdapt Inc. will not be liable for any damages of any kind arising
          from the use of its products or services or from any information,
          content, materials, products (including software) or services included
          on or otherwise made available to you, including, but not limited to,
          direct, indirect, incidental, punitive, and consequential damages,
          unless otherwise specified in writing.&nbsp;
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Read the WellAdapt Terms of Use Agreement for more details.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Certain state laws do not allow limitations on implied warranties or
          the exclusion or limitation of certain damages. If these laws apply to
          you, some or all of the above disclaimers, exclusions, or limitations
          may not apply to you, and you might have additional rights.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          The power to interpret and modify this privacy policy shall rest with
          WellAdapt Inc.
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>&nbsp;</strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          <strong>Contact us</strong>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          If you have any questions regarding privacy while using our products,
          or have questions about our practices, please contact us via email at{' '}
          <a href="mailto:privacy@welladapt.com">privacy@welladapt.com</a>
        </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}> </p>
        <p style={{ marginLeft: '0in', marginRight: '0in' }}>
          Last updated: August 12, 2020
        </p>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
