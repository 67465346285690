export const standardizeAdBpm = (bpm) => {
  const systolic = bpm.payload?.measurements?.find(
    (m) => m.measurementType === 'systolic'
  )
  const diastolic = bpm.payload?.measurements?.find(
    (m) => m.measurementType === 'diastolic'
  )
  const pulse = bpm.payload?.measurements?.find(
    (m) => m.measurementType === 'pulse'
  )
  const date = new Date(bpm.payload?.timestamp)

  return {
    systolic: systolic?.value,
    diastolic: diastolic?.value,
    pulse: pulse?.value,
    ts: date.valueOf(),
  }
}
