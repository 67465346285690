import { useState, useId } from 'react'
import { Dialog } from '../../components/common/Dialog'
import { DataStage } from './DataStage'
import { FillingStage } from './FillingStage'
import { FinalStage } from './FinalStage'

const stages = ['data', 'filling', 'final']

const getNextStage = (stage) => {
  const currentIndex = stages.findIndex((val) => val === stage)
  return stages[currentIndex + 1] || stages[stages.length - 1]
}

const getPreviousStage = (stage) => {
  const currentIndex = stages.findIndex((val) => val === stage)
  return stages[currentIndex - 1] || stages[0]
}

export const BulkUploadDevicesModal = ({ opend, onClose = () => {} }) => {
  const actionId = useId()
  const [files, setFiles] = useState()
  const [devices, setDevices] = useState([])
  const [customers, setCustomers] = useState([])
  const [endpoints, setEndpoints] = useState([])
  const [stage, setStage] = useState(stages[0])

  const nextStageHandler = () => {
    setStage((stage) => getNextStage(stage))
  }
  const backStageHandler = () => {
    setStage((stage) => getPreviousStage(stage))
  }
  const closeHandler = () => {
    setStage('data')
    setFiles(null)
    onClose?.()
  }

  return (
    <Dialog
      open={opend}
      title="Buld Upload"
      handleClose={closeHandler}
      actions={<div id={actionId}></div>}
      maxWidth={stage === 'final' ? 'm' : 'sm'}
    >
      {stage === 'data' && (
        <DataStage
          files={files}
          actionId={actionId}
          onChange={setFiles}
          onClose={closeHandler}
          onNext={nextStageHandler}
        />
      )}
      {stage === 'filling' && (
        <FillingStage
          files={files}
          actionId={actionId}
          onNext={({ devices, customers, endpoints }) => {
            setDevices(devices)
            setCustomers(customers)
            setEndpoints(endpoints)
            nextStageHandler()
          }}
          onBack={backStageHandler}
        />
      )}
      {stage === 'final' && (
        <FinalStage
          devices={devices}
          customers={customers}
          endpoints={endpoints}
          actionId={actionId}
          onBack={backStageHandler}
          onClose={closeHandler}
        />
      )}
    </Dialog>
  )
}
