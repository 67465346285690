import { isNumber } from 'lodash'

export const signalOptions = [
  { value: 'strong', label: 'Strong' },
  { value: 'medium', label: 'Medium' },
  { value: 'weak', label: 'Weak' },
]

export const formatSignal = (signal) => {
  if (isNumber(signal)) {
    if (signal > 20) {
      return 'Strong'
    } else if (signal > 15) {
      return 'Medium'
    } else {
      return 'Weak'
    }
  }
  return signal
}
